<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="cell.value !== undefined; else undefinedValue" [ngSwitch]="cell.type">
  <og-truncated-text
    *ngSwitchCase="'text'"
    class="table-cell--align-left"
    [text]="cell.value"
  ></og-truncated-text>

  <div fxLayout="row" fxLayoutAlign="center center" *ngSwitchCase="'joined_values'">
    <ng-container *ngFor="let cell of cell.value; let first = first">
      <hr *ngIf="!first" class="table-cell__divider" />
      <og-table-cell [cell]="cell" class="table-cell__details"></og-table-cell>
    </ng-container>
  </div>

  <og-number-value-cell
    *ngSwitchCase="'number'"
    [value]="cell.value"
    [decimalPlaces]="options?.decimalPlaces"
  ></og-number-value-cell>

  <og-bounded-number-value-cell
    *ngSwitchCase="'bounded_number'"
    [value]="cell.value"
    [bound]="bound"
    [decimalPipeArgs]="getDecimalPipeArgs(options?.decimalPlaces)"
  ></og-bounded-number-value-cell>

  <og-short-number-value-cell
    *ngSwitchCase="'short_number'"
    [value]="cell.value"
  ></og-short-number-value-cell>

  <og-currency-value-cell
    *ngSwitchCase="'currency_value'"
    [value]="cell.value"
    [alignment]="options?.alignment"
    [bound]="options?.columnBound || bound"
    [decimalPipeArgs]="getDecimalPipeArgs(options?.decimalPlaces)"
  ></og-currency-value-cell>

  <og-change-currency-value
    *ngSwitchCase="'currency_value_change'"
    [currency]="cell.value"
    [bound]="options?.columnBound || bound"
    [decimalPipeArgs]="getDecimalPipeArgs(options?.decimalPlaces)"
  ></og-change-currency-value>

  <og-percentage-value-cell
    *ngSwitchCase="'percentage'"
    [value]="cell.value"
  ></og-percentage-value-cell>

  <og-entry-line-feedback-field
    *ngSwitchCase="'status'"
    [feedbackItem]="cell.value"
  ></og-entry-line-feedback-field>

  <og-icon-cell *ngSwitchCase="'icon'" [icon]="cell.value"></og-icon-cell>

  <!-- If cell type could not be matched, display the undefined value placeholder. -->
  <ng-container *ngSwitchDefault>
    <ng-container *ngTemplateOutlet="undefinedValue"></ng-container>
  </ng-container>
</ng-container>

<ng-template #undefinedValue>
  <div>-</div>
</ng-template>
