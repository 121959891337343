import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { ScreenEngineResolver, ScreenEngineScreenModel } from '@opengamma/ui';
import { Observable } from 'rxjs';
import * as fromUser from 'app/user-auth/store/user-auth.selectors';
import { filterUndefined } from '../rxjs/rxjs.utils';
import { map } from 'rxjs/operators';
import { carmaApiPath } from 'environments/environment.utils';

@Injectable()
export class CarmaScreenEngineResolver extends ScreenEngineResolver {
  constructor(private store: Store) {
    super();
  }

  getScreen(): Observable<ScreenEngineScreenModel> {
    return this.store.pipe(select(fromUser.getScreensByUrl), filterUndefined()).pipe(
      map(screenMap => ({
        id: location.pathname,
        definition: screenMap[location.pathname]?.definition,
        screenName: screenMap[location.pathname]?.screenName
      }))
    );
  }

  getScreenName(): string {
    return location.pathname;
  }

  getScreenUrlPathForDownload(downloadPath: string): string {
    return `${carmaApiPath()}/${this.getScreenName()}/${downloadPath}`;
  }

  observeDatepickerChanges(): boolean {
    return false;
  }
}
