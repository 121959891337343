<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
@if (!hidden) {
<section
  class="config__tile config__tile--{{ size }}"
  [@slideIn]
  [ngClass]="[status | lowercase, theme]"
>
  <h3>
    {{ title }}
    @if (status === 'COMPLETE') {
    <span class="success_wrapper">
      <og-icon name="circle-check-filled" [size]="IconSize.Large"></og-icon>
    </span>
    }
  </h3>
  @if (error) {
  <div class="error-container">
    <og-error [text]="error" [theme]="'light'" (refreshClick)="onErrorRetryClicked()">
      <div class="error__change-selection" (click)="onErrorChangeSelectionClicked()">
        Change Selection
      </div>
    </og-error>
  </div>
  }
  <div class="content__wrapper">
    @if (loading) {
    <div class="loading__container">
      <og-spinner></og-spinner>
    </div>
    } @if (!error) {
    <ng-content></ng-content>
    }
  </div>
  @if (showCancelButton) {
  <div class="cancel-button__wrapper" (click)="onCancelButtonClicked()">
    <span class="cancel__button">CANCEL</span>
  </div>
  }
</section>
}
