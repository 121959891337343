<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div fxLayout="row" fxLayoutAlign="center center">
  <og-button
    icon="cloud-download"
    theme="secondary"
    matTooltip="Export timeseries data"
    [iconOnly]="true"
    (buttonClick)="onExportChartToCSVButtonClicked()"
  ></og-button>
</div>
