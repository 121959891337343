/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { RadioButtonEntry } from './radio-button-group.model';

@Component({
  selector: 'og-legacy-radio-button-group',
  templateUrl: './radio-button-group.component.html',
  styleUrls: ['./radio-button-group.component.scss']
})
export class RadioButtonGroupComponent implements OnChanges {
  /** The labels of all buttons to display */
  @Input() buttons: RadioButtonEntry[];
  /** The label of the show more button */
  @Input() showMoreButtonText: string;
  /** The id of the currently selected button */
  @Input() selectedButton = '';
  /** The custom theme to apply */
  @Input() theme = '';
  /** The custom theme for the buttons */
  @Input() buttonTheme = '';
  /** A static button size to be used */
  @Input() buttonSize: 'small' | 'medium' | 'large';
  /** The name of the control. */
  @Input() name: string;
  /** Emits selected button changes */
  @Output() selectedButtonChange: EventEmitter<RadioButtonEntry> = new EventEmitter<
    RadioButtonEntry
  >();
  /** Emits show more clicks */
  @Output() showMoreClicked: EventEmitter<void> = new EventEmitter<void>();

  buttonSizeClass: string;
  showMoreSelected: boolean;

  constructor() {
    this.showMoreSelected = false;
  }

  onButtonSelected(id: string) {
    const button = this.buttons.find(btn => btn.id === id);
    this.selectedButtonChange.next(button);
  }

  ngOnChanges() {
    if (this.buttonSize) {
      this.buttonSizeClass = `rb--${this.buttonSize}`;
    } else if (this.buttons) {
      if (this.buttons.length <= 4) {
        this.buttonSizeClass = 'rb--large';
      } else if (this.buttons.length <= 6) {
        this.buttonSizeClass = 'rb--medium';
      } else {
        this.buttonSizeClass = 'rb--small';
      }
    }
  }

  onShowMoreClicked() {
    this.showMoreSelected = !this.showMoreSelected;
    this.showMoreClicked.next();
  }
}
