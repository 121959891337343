/*
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  TemplateRef,
  Output,
  EventEmitter
} from '@angular/core';
import { IconStroke } from '@opengamma/ui';
import {
  SortingFunction,
  SortingOrder,
  TreeTableColumn,
  TreeTableColumnGroup,
  TreeTableNode,
  TreeTableRow,
  ColumnSortingEvent
} from 'app/shared/legacy-components/tree-table/models/tree-table.models';

/** A store for the currently sorting settings of the table */
interface SortData {
  /** the column currently being sorted */
  columnIndex: number;
  /** the comparator function used for sorting */
  sortingFunction?: SortingFunction;
  /** the order of the sorting */
  sortOrder: SortingOrder;
}

/**
 * A tree-table that can custom render cells via provided templates
 */
@Component({
  selector: 'og-tree-table',
  templateUrl: './tree-table.component.html',
  styleUrls: ['./tree-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TreeTableComponent {
  /** The rows of the table. */
  @Input() rows: TreeTableRow[];
  /** A total row to be displayed a the top of the table. */
  @Input() totalRow: TreeTableRow;
  /** Bespoke cell templates to be rendered in the table */
  @Input() cellTemplates: { [reference: string]: TemplateRef<any> };
  /** True if multiple rows can be expanded at the same time. */
  @Input() allowMultipleExpandingRows = true;
  /** The number of rows to display before showing show more */
  @Input() rowPaginationCount = 25;
  /**
   * Prevent TreeTableComponent from sorting data. TreeTableComponent will still emit sortingChange
   *    events. Thus, sorting can be handled outside of the component.
   */
  @Input() exportSorting = false;

  /** Emits when a row is expanded. */
  @Output() expansionChange = new EventEmitter<string[]>();
  /** Emits when the user sorts by a different column. */
  @Output() sortingChange = new EventEmitter<ColumnSortingEvent>();

  /** Contains the ids of all expanded rows */
  expandedRows = new Set<string>();
  /** The number of pages each expanded level has loaded into view. */
  pagesDisplayedForPath: { [nodePath: string]: number } = {};
  /** Metadata for the currently sorted column */
  sortData: SortData;
  /** All the table columns groups */
  groups: TreeTableColumnGroup[];
  /** All the table columns, ungrouped */
  columns: TreeTableColumn[];

  /** The column groups of the table */
  @Input() set columnGroups(groups: TreeTableColumnGroup[]) {
    if (groups) {
      this.groups = groups;
      this.columns = groups.reduce((types, columnGroup) => [...types, ...columnGroup.columns], []);
      this.columns.forEach((column, index) => {
        if (column.defaultSorting) {
          this.sortData = {
            columnIndex: index,
            sortingFunction: column.sortingFunction,
            sortOrder: column.defaultSorting
          };
        }
      });
    }
  }

  /** The ids of the rows that should be expanded */
  @Input() set expandedRowsIds(rowIds: string[]) {
    this.expandedRows = new Set(rowIds);
  }

  IconStroke = IconStroke;

  getNodePath(pathSoFar: string[], rowId: string): string[] {
    return [...pathSoFar, rowId];
  }

  getCellTemplate(index: number): TemplateRef<any> {
    return this.cellTemplates && this.cellTemplates[this.columns[index].type];
  }

  getRowsToDisplay(node: TreeTableNode): TreeTableRow[] {
    if (node.rows) {
      const rows = this.exportSorting ? node.rows : this.getSortedRows(node.rows);
      const pagesToDisplay = this.pagesDisplayedForPath[node.path] || 1;
      return rows.slice(0, pagesToDisplay * this.rowPaginationCount);
    }
    return [];
  }

  isRowExpandable(row: TreeTableRow): boolean {
    return row.children && row.children.length !== 0;
  }

  isRowExpanded(path: string[]): boolean {
    return this.expandedRows.has(path.slice(-1)[0]);
  }

  isColumnActive(columnIndex: number): boolean {
    return (
      !this.columns[columnIndex]?.readonly && this.columns[columnIndex]?.readonly !== undefined
    );
  }

  onCellClick(index: number, event: MouseEvent) {
    if (this.isColumnActive(index)) {
      event.stopPropagation();
    }
  }

  onRowToggle(nodePath: string[]) {
    const lastNode = nodePath.slice(-1)[0];
    if (lastNode === undefined) {
      throw new Error('Row has no defined id');
    }
    if (this.expandedRows.has(lastNode)) {
      this.expandedRows.delete(lastNode);
    } else {
      if (this.allowMultipleExpandingRows) {
        this.expandedRows.add(lastNode);
      } else {
        // if only one expanded row is allowed at any time per level,
        // delete all expanded rows so far and expand all nodes down the tree where the currently expanded node is
        this.expandedRows = new Set<string>(nodePath);
      }
    }

    this.expansionChange.emit([...this.expandedRows]);
  }

  onShowMoreRows(path: string) {
    this.pagesDisplayedForPath[path] = (this.pagesDisplayedForPath[path] || 1) + 1;
  }

  onSortChange(column: TreeTableColumn, index: number) {
    if (column.isSortable) {
      if (this.sortData !== undefined && index === this.sortData.columnIndex) {
        this.sortData.sortOrder = this.sortData.sortOrder === 'desc' ? 'asc' : 'desc';
      } else {
        this.sortData = {
          sortingFunction: column.sortingFunction,
          sortOrder: 'desc',
          columnIndex: index
        };
      }
      const columnSortingEvent: ColumnSortingEvent = {
        id: this.columns[this.sortData.columnIndex].id,
        order: this.sortData.sortOrder
      };
      if (this.exportSorting) {
        columnSortingEvent.sortingFunction = this.sortData.sortingFunction;
      }
      this.sortingChange.emit(columnSortingEvent);
    }
  }

  trackRows(row: TreeTableRow): string {
    return row.id;
  }

  getSortIcon(columnIndex: number): 'selector' | 'chevron-up' | 'chevron-down' {
    if (this.sortData && this.sortData.columnIndex === columnIndex) {
      if (this.sortData.sortOrder === 'asc') {
        return 'chevron-up';
      }
      return 'chevron-down';
    }

    return 'selector';
  }

  private getSortedRows(rowsToSort: TreeTableRow[]): TreeTableRow[] {
    if (this.sortData === undefined) {
      return rowsToSort;
    }
    const { columnIndex, sortingFunction, sortOrder } = this.sortData;
    const comparatorFunction = (a: TreeTableRow, b: TreeTableRow) => {
      let result: number;
      const valueOfA = a.values[columnIndex];
      const valueOfB = b.values[columnIndex];
      if (valueOfA === valueOfB) {
        return 0;
      }
      if (valueOfA === undefined) {
        return 1;
      }
      if (valueOfB === undefined) {
        return -1;
      }
      if (sortingFunction === undefined) {
        result = valueOfB > valueOfA ? 1 : -1;
      } else {
        result = sortingFunction(valueOfA, valueOfB);
      }
      return sortOrder === 'asc' ? result : -result;
    };
    return rowsToSort.sort(comparatorFunction);
  }
}
