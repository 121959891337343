/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  NestedTableDecoratedRow,
  NestedTableRow,
  NestedTableResponse,
  NestedTableDecoratedResponse
} from '@opengamma/ui';
import * as _ from 'lodash';

export function decorateNestedTableRowWithExpansionMetaData(
  node: NestedTableRow,
  expansionSet: Set<string>
): NestedTableDecoratedRow {
  if (!node) {
    return undefined;
  }

  const isExpandable = !_.isEmpty(node.children);
  const isExpanded = expansionSet?.has(node.id);

  return {
    ...node,
    isExpandable,
    isExpanded,
    exportIds: isExpandable
      ? node.children.rows.map(row => row.values.export as string)
      : undefined,
    children:
      isExpandable && isExpanded
        ? decorateNestedTableWithExpansionMetaData(node.children, expansionSet)
        : undefined
  };
}

export function decorateNestedTableWithExpansionMetaData(
  node: NestedTableResponse,
  expansionSet: Set<string>
): NestedTableDecoratedResponse {
  if (!node) {
    return undefined;
  }

  return {
    ...node,
    rows: node.rows.map(row => decorateNestedTableRowWithExpansionMetaData(row, expansionSet))
  };
}
