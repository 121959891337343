<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<og-labeled-entity [label]="label" [size]="size">
  <og-dropdown
    content
    [name]="label"
    [theme]="'dark'"
    [options]="dropdownOptions"
    [selectedOption]="selectedOption"
    [size]="size"
    [loading]="loading"
    [disabled]="disabled"
    (selectedOptionChange)="onSelectionChange($event)"
  ></og-dropdown>
</og-labeled-entity>
