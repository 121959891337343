/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges } from '@angular/core';
import { CurrencyValue } from '@opengamma/ui';

@Component({
  selector: 'og-change-im-labeled-entity',
  templateUrl: './change-im-labeled-entity.component.html',
  styleUrls: ['./change-im-labeled-entity.component.scss']
})
export class ChangeImLabeledEntityComponent implements OnChanges {
  /** The label to display */
  @Input() label: string;
  /** The current im value to display */
  @Input() currentIm: CurrencyValue;
  /** The simulated value */
  @Input() simulatedIm: CurrencyValue;
  /** bound for the short number pipe */
  @Input() bound = 0;
  /** The size of the metrics */
  @Input() size: 'small' | 'large' = 'large';

  imChange: CurrencyValue;

  ngOnChanges() {
    if (!!this.simulatedIm) {
      this.imChange = {
        ...this.currentIm,
        price: this.simulatedIm.price - this.currentIm.price
      };
    } else {
      this.imChange = undefined;
    }
  }
}
