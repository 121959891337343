/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { TableColumnGroup } from '@opengamma/ui';

/**
 * Only return selectedColumns that exist within the latest columns array.
 */
export function getValidatedSelectedColumns(
  selectedColumnGroups: TableColumnGroup[],
  columnGroups: TableColumnGroup[]
): TableColumnGroup[] {
  if (!Array.isArray(selectedColumnGroups)) {
    return undefined;
  }

  const selectedColumns = selectedColumnGroups.flatMap(columnGroup => columnGroup.columns);

  return columnGroups.map(columnGroup => {
    const columns = columnGroup.columns.map(column => {
      const matchingCol = selectedColumns.find(selectedColumn => selectedColumn.id === column.id);
      if (matchingCol) {
        // If the visibility setting in the table column data is false, maintain that setting
        // This is in case the back end chooses to hide certain columns, we are preferring those settings
        // over whatever was stored in local storage
        return { ...column, isVisible: !column.isVisible ? false : matchingCol.isVisible };
      }
      return column;
    });

    return { ...columnGroup, columns };
  });
}
