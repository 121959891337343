/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import { SelectOption } from '@opengamma/ui';

export type FlatViewOption = 'nodes' | 'leaves';

/**
 * If this component is to be used within TableWrapperComponent, but with its functionality handled outside of
 *     TableWrapperComponent, then you must set ** exportFunctionality = true **.
 */
@Component({
  selector: 'og-table-leaf-view-select',
  templateUrl: './table-leaf-view-select.component.html',
  styleUrls: ['./table-leaf-view-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableFlatViewSelectComponent implements OnInit {
  options: SelectOption<FlatViewOption>[] = [
    {
      value: 'nodes',
      text: 'Grouped'
    },
    {
      value: 'leaves',
      text: 'Ungrouped'
    }
  ];

  @Output() selectedOptionChange = new EventEmitter<FlatViewOption>();

  /** Prevent TableWrapperComponent from handling this components logic. */
  @Input() exportFunctionality = false;

  _selectedOption: FlatViewOption;
  @Input()
  set selectedOption(selectedOption: FlatViewOption) {
    if (selectedOption) {
      this._selectedOption = selectedOption;
      this.cdRef.markForCheck();
    }
  }
  get selectedOption(): FlatViewOption {
    return this._selectedOption;
  }

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.selectedOption = this.options[0].value;
  }

  onOptionSelected(option: FlatViewOption): void {
    this.selectedOption = option;
    this.selectedOptionChange.emit(option);
  }

  getSelectedOptionText(): string {
    return this.options.find(option => option.value === this.selectedOption)?.text;
  }
}
