<og-select
  selectPlacement="bottom-start"
  [multiple]="true"
  [selectedOptions]="getSelectedOptions()"
  (selectionChange)="onChipSelectionChange($event)"
  class="select"
>
  <og-select-label>
    <og-icon name="menu-2"></og-icon>
  </og-select-label>

  <og-select-trigger class="select__trigger">
    <div fxLayout="row" fxLayoutGap="4px" class="select__trigger__chips">
      @if (selectedOptions?.length > 0) { @for (option of selectedOptions; track option; let i =
      $index) {
      <og-chip
        [fieldId]="option"
        [removeIcon]="'times'"
        (remove)="onRemoveOption($event)"
        class="legend__item"
        fxLayout="row"
        fxLayoutAlign="center center"
      >
        <div class="legend-item__bar" [style.background-color]="colors[i]"></div>
        <div class="legend-item__label">{{ option }}</div>
      </og-chip>
      } } @else {
      <div class="select__trigger__chips--empty">Select {{ pluralize(grouping) }}...</div>
      }
    </div>
  </og-select-trigger>

  <og-select-panel class="select__panel">
    <og-select-panel-title>Show or hide {{ pluralize(grouping) }}</og-select-panel-title>

    @if (enableSelectAllOption) {
    <og-select-option
      (onSelect)="onSelectAllOptions()"
      [selected]="areAllOptionsSelected()"
      [detached]="true"
    >
      Select all
    </og-select-option>
    } @if (enableSelectNoneOption) {
    <og-select-option
      (onSelect)="onDeselectAllOptions()"
      [selected]="areAllOptionsDeselected()"
      [detached]="true"
    >
      Select none
    </og-select-option>
    } @for (option of options; track option) {
    <og-select-option [value]="option" class="select__option">
      <og-checkbox-noop [checked]="isOptionSelected(option)">
        {{ option }}
      </og-checkbox-noop>
    </og-select-option>
    }
  </og-select-panel>
</og-select>
