/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import * as Highcharts from 'highcharts/highstock';

export const defaultColumnOptions: Highcharts.Options = {
  chart: {
    type: 'column'
  },
  plotOptions: {
    column: {
      borderWidth: 0,
      maxPointWidth: 30
    }
  },
  tooltip: {
    enabled: true,
    formatter: function() {
      const category = this.x;
      const value = Math.round(this.y);
      return `<b>${category}: ${value}</b> <br/>`;
    }
  },
  legend: {
    enabled: true
  }
};
