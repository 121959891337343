/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { ProductDefinition } from 'app/user-auth/models';
import { LoadChildren } from '@angular/router';
import * as _ from 'lodash';
import {
  ScreenEngineResolver,
  UserAuthModels,
  AbstractCommonScreensResolver,
  ModulesDefinition,
  LOCAL_PRODUCT_DEFINITION
} from '@opengamma/ui';
import { carmaApiPath } from 'environments/environment.utils';
import { Provider } from '@angular/core';
import { CarmaScreenEngineResolver } from 'app/shared/services/carma-screen-resolver.service';
import { CarmaCommmonScreenResolver } from 'app/shared/services/common-screen-resolver.service';

export type ProductDateType = 'marginReportingDate' | 'treasuryDate';

export const DEFAULT_DISPLAY_OPTIONS: UserAuthModels.DisplayOptions = {
  positionInMenu: 'left',
  moduleTheme: 'dark'
};

// -------------------------------------------------------------------------

export const LOCALLY_PROVIDED_MODULES: ModulesDefinition = {
  ABOUT: {
    urlPath: 'about',
    name: 'About',
    displayOptions: {
      positionInMenu: 'right-dropdown',
      moduleTheme: 'light',
      hasDivider: true
    },
    screens: {
      ABOUT_MARKET_DATA: {
        urlPath: 'market-data',
        hasMultipleRunsEnabled: false,
        isTestCalcModeSupported: false,
        name: 'Market Data'
      },
      ABOUT_ACCOUNT: {
        urlPath: 'account',
        hasMultipleRunsEnabled: false,
        isTestCalcModeSupported: false,
        name: 'Account'
      }
    }
  }
};

/** The Angular Modules needed to render for each Screen Template Permission. */
export const MODULE_MAP: {
  [screen in string]: { loadChildren: LoadChildren; providers?: Provider[] };
} = {
  MARGIN_EXPLAIN_ACCOUNTS: {
    loadChildren: () =>
      import('app/screens/margin/track/accounts/accounts.module').then(m => m.ExplainAccountsModule)
  },
  MARGIN_EXPLAIN_ATTRIBUTION: {
    loadChildren: () =>
      import('app/screens/margin/track/attribution/attribution.module').then(
        m => m.AttributionModule
      )
  },
  MARGIN_EXPLAIN_INTRADAY_CHANGE: {
    loadChildren: () =>
      import('app/screens/margin/track/change-intraday/change.module').then(m => m.ChangeModule)
  },
  MARGIN_EXPLAIN_RISK_DISPLAY: {
    loadChildren: () =>
      import('app/screens/margin/track/risk-display/risk-display.module').then(
        m => m.RiskDisplayModule
      )
  },
  MARGIN_EXPLAIN_IM_PROFILE: {
    loadChildren: () =>
      import('app/screens/margin/track/im-profile/im-profile.module').then(m => m.ImProfileModule)
  },
  MARGIN_REBALANCE_SCENARIOS: {
    loadChildren: () =>
      import('app/screens/margin/rebalance/scenarios/rebalance-scenarios.module').then(
        m => m.RebalanceScenariosModule
      )
  },
  MARGIN_REBALANCE_RISK: {
    loadChildren: () =>
      import('app/screens/margin/rebalance/risk/risk.module').then(m => m.RiskModule)
  },
  MARGIN_REBALANCE_RISK_EXISTING_POSITIONS: {
    loadChildren: () =>
      import('app/screens/margin/rebalance/existing-positions/existing-positions.module').then(
        m => m.ExistingPositionsModule
      )
  },
  MARGIN_REBALANCE_SAVINGS: {
    loadChildren: () =>
      import('app/screens/margin/rebalance/savings/rebalance-savings.module').then(
        m => m.RebalanceSavingsModule
      )
  },
  MARGIN_REBALANCE_SIMULATION: {
    loadChildren: () =>
      import('app/screens/margin/rebalance/simulation/rebalance-simulation.module').then(
        m => m.RebalanceSimulationModule
      )
  },
  MARGIN_DYNAMIC_WHATIF: {
    loadChildren: () =>
      import('app/screens/margin/what-if/trades/trades.module').then(
        m => m.CarmaMarginWhatIfTradesModule
      )
  },
  MARGIN_WHATIF_DV01: {
    loadChildren: () =>
      import('app/screens/margin/what-if/dv01/dv01.module').then(m => m.MarginWhatIfDv01Module)
  },
  MARGIN_WHATIF_PORTFOLIO: {
    loadChildren: () =>
      import('app/screens/margin/what-if/portfolio/portfolio.module').then(
        m => m.MarginWhatIfPortfolioModule
      )
  },
  MARGIN_WHATIF_ETD: {
    loadChildren: () =>
      import('app/screens/margin/what-if/strategy-etd/strategy-etd.module').then(
        m => m.MarginWhatIfEtdStrategyModule
      )
  },
  MARGIN_WHATIF_METHODOLOGY: {
    loadChildren: () =>
      import('app/screens/margin/what-if/methodology/methodology.module').then(
        m => m.MethodologyModule
      )
  },
  MARGIN_WHATIF_MARGIN_RATES: {
    loadChildren: () =>
      import('app/screens/margin/what-if/margin-rates/margin-rates.module').then(
        m => m.MarginRatesModule
      )
  },
  MARGIN_WHATIF_IM_SENSITIVITIES: {
    loadChildren: () =>
      import('app/screens/margin/what-if/im-sensitivities/im-sensitivities.module').then(
        m => m.ImSensitivitiesModule
      )
  },
  MARGIN_WHATIF_RISK: {
    loadChildren: () =>
      import('app/screens/margin/what-if/risk/risk.module').then(m => m.RiskModule)
  },
  MARGIN_WHATIF_SWITCHES: {
    loadChildren: () =>
      import('app/screens/margin/what-if/switches/switches.module').then(m => m.SwitchesModule)
  },
  MARGIN_WHATIF_CROSS_MARGIN: {
    loadChildren: () =>
      import('app/screens/margin/what-if/x-margin/x-margin.module').then(m => m.XMarginModule)
  },
  MARGIN_VALIDATION: {
    loadChildren: () =>
      import('app/screens/margin/validate/overview/overview.module').then(m => m.OverviewModule)
  },
  MARGIN_CLEARING_LIMITS: {
    loadChildren: () =>
      import('app/screens/margin/monitor/clearing-limits/clearing-limits.module').then(
        m => m.MonitorClearingLimitsModule
      )
  },
  MARGIN_SIMM_DEMO: {
    loadChildren: () =>
      import('app/screens/margin/monitor/simm-demo/simm-demo.module').then(m => m.SIMMDemoModule)
  },
  MARGIN_AANA_DEMO: {
    loadChildren: () =>
      import('app/screens/margin/monitor/aana-demo/aana-demo.module').then(m => m.AanaDemoModule)
  },
  TRANSACTION_COST: {
    loadChildren: () =>
      import('app/screens/total-cost/compliance/transaction-costs/transaction-costs.module').then(
        m => m.TransactionCostsModule
      )
  },
  SCREEN_ENGINE_FLEX_LAYOUT: {
    loadChildren: () => import('@opengamma/ui').then(m => m.ScreenEngineFlexLayoutModule),
    providers: [
      { provide: ScreenEngineResolver, useClass: CarmaScreenEngineResolver },
      { provide: AbstractCommonScreensResolver, useClass: CarmaCommmonScreenResolver }
    ]
  },
  ABOUT_MARKET_DATA: {
    loadChildren: () =>
      import('app/about/market-data/market-data.module').then(m => m.MarketDataModule)
  },
  ABOUT_ACCOUNT: {
    loadChildren: () =>
      import('app/about/account-details/account-details.module').then(m => m.AccountDetailsModule)
  },
  MARGIN_CALCULATIONS_ON_DEMAND: {
    loadChildren: () =>
      import('app/screens/margin/calculations/on-demand/on-demand.module').then(
        m => m.CalculationsOnDemandModule
      )
  },
  MARGIN_CALCULATIONS_SCHEDULE: {
    loadChildren: () =>
      import('app/screens/margin/calculations/schedule/schedule.module').then(
        m => m.CalculationsScheduleModule
      )
  },
  MARGIN_CALCULATIONS_RUNS: {
    loadChildren: () =>
      import('app/screens/margin/calculations/runs/runs.module').then(m => m.CalculationsRunsModule)
  }
};

export function convertLegacyPermissionsToProductDefinition(
  permissions: UserAuthModels.Permissions,
  canUserViewInternalScreens: boolean,
  canUserViewLocalScreens: boolean,
  isTestCalcModeEnabled: boolean
): ProductDefinition {
  const isEntityAvailableBasedOnStatus = (
    status: UserAuthModels.AccessState | undefined
  ): boolean => {
    if (!status || status === 'DISABLED') {
      return false;
    }
    if (status === 'INTERNAL') {
      return canUserViewInternalScreens;
    }
    return true;
  };
  const findPermission = (
    permission: string,
    aliases: string[] | undefined,
    map: { [name in string]: UserAuthModels.AccessState }
  ): UserAuthModels.AccessState | undefined => {
    const accessStates: UserAuthModels.AccessState[] = [
      'ENABLED',
      'INTERNAL',
      'LOCKED',
      'DISABLED'
    ];
    const entityWithAliases = [permission, ...(aliases || [])];
    // choose the most permissive access state out of the module aliases.
    // TODO: this logic can be safely remove, alongside aliases, once EXPLAIN and TRACK are merged on the backend
    for (const accessState of accessStates) {
      for (const entity of entityWithAliases) {
        if (accessState === map[entity]) {
          return accessState;
        }
      }
    }

    return undefined;
  };

  const definition: ProductDefinition = {
    products: []
  };

  const allProducts = Object.keys(LOCAL_PRODUCT_DEFINITION(carmaApiPath()));

  allProducts.forEach(possibleProduct => {
    const productStatus = permissions.products[possibleProduct];

    if (isEntityAvailableBasedOnStatus(productStatus)) {
      const allModules = {
        ...LOCAL_PRODUCT_DEFINITION(carmaApiPath())[possibleProduct].modules,
        ...(canUserViewLocalScreens ? LOCALLY_PROVIDED_MODULES : [])
      };

      const allModulePermissions = Object.keys(allModules);
      const modulesInProduct = [];
      allModulePermissions.forEach(possibleModule => {
        const moduleStatus = findPermission(
          possibleModule,
          allModules[possibleModule].aliases,
          permissions.modules
        );
        const isModuleLocallyProvided = !!LOCALLY_PROVIDED_MODULES[possibleModule];

        if (isEntityAvailableBasedOnStatus(moduleStatus) || isModuleLocallyProvided) {
          const allScreens = allModules[possibleModule].screens;
          const allScreensPermissions = Object.keys(allScreens);
          const screensInModule = [];
          allScreensPermissions.forEach(possibleScreen => {
            const screenStatus = findPermission(
              possibleScreen,
              allScreens[possibleScreen].aliases,
              permissions.screens
            );
            const isScreenLocallyProvided = !!LOCALLY_PROVIDED_MODULES[possibleModule]?.screens[
              possibleScreen
            ];

            if (isEntityAvailableBasedOnStatus(screenStatus) || isScreenLocallyProvided) {
              const screen = allScreens[possibleScreen];
              // Only some screens support the test calc mode due to differences in how each
              // screen sources its data. We use a mixture of Module Responses, Output Beans and
              // Athena queries to represent calc outputs. Currently, we do not store Output Beans
              // for test calcs, so only screens using module responses can display test outputs.
              const screenSupportsTestCalcMode = screen.isTestCalcModeSupported;
              const availableScreen = {
                screenName: screen.name,
                urlPath: screen.urlPath,
                hasMultipleRunsEnabled: screen.hasMultipleRunsEnabled,
                isTestCalcModeSupported: screenSupportsTestCalcMode,
                isInternal: 'INTERNAL' === screenStatus,
                isLocked: 'LOCKED' === screenStatus,
                screenPermission: possibleScreen,
                screenTemplate: screen.screenTemplate || possibleScreen,
                definition: screen.definition
              };
              if (!isTestCalcModeEnabled || (isTestCalcModeEnabled && screenSupportsTestCalcMode)) {
                screensInModule.push(availableScreen);
              }
            }
          });

          if (screensInModule.length > 0) {
            const module = allModules[possibleModule];
            const currentScreens = modulesInProduct[module.name]?.screens ?? [];
            const uniqueScreens = _.uniqBy(
              [...screensInModule, ...currentScreens],
              screen => screen.urlPath
            );
            modulesInProduct[module.name] = {
              moduleName: module.name,
              urlPath: module.urlPath,
              screens: uniqueScreens,
              isLocked: uniqueScreens.every(screen => screen.isLocked),
              isInternal: uniqueScreens.every(screen => screen.isInternal),
              displayOptions: module.displayOptions ?? DEFAULT_DISPLAY_OPTIONS
            };
          }
        }
      });

      if (Object.keys(modulesInProduct).length > 0) {
        const moduleValues = Object.keys(modulesInProduct).map(name => modulesInProduct[name]);
        const product = LOCAL_PRODUCT_DEFINITION(carmaApiPath())[possibleProduct];
        definition.products.push({
          productName: product.name,
          urlPath: product.urlPath,
          dateType: product.dateType,
          modules: moduleValues,
          isExternal: product.isExternal,
          isLocked: moduleValues.every(module => module.isLocked),
          isInternal: moduleValues.every(module => module.isInternal)
        });
      }
    }
  });

  return definition;
}
