<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="legendItems || legendItemsWithColors">
  <div class="legend" fxLayout="row wrap">
    <ng-container *ngIf="legendItems">
      <div
        class="legend__item"
        fxLayout="row"
        fxLayoutAlign="center center"
        *ngFor="let legendItem of legendItems; let i = index"
      >
        <div class="legend-item__bar" [style.background-color]="colors[i]"></div>
        <div class="legend-item__label">{{ legendItem }}</div>
      </div>
    </ng-container>

    <ng-container *ngIf="legendItemsWithColors">
      <div
        class="legend__item"
        fxLayout="row"
        fxLayoutAlign="center center"
        *ngFor="let legendItem of legendItemsWithColors; let i = index"
      >
        <div class="legend-item__bar" [style.background-color]="legendItem.color"></div>
        <div class="legend-item__label">{{ legendItem.name }}</div>
      </div>
    </ng-container>
  </div>
</ng-container>
