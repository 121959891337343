/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { Grouping } from 'app/shared/models';
import { NamedValue } from '@opengamma/ui';

/** Defines the event emitted on grouping selection */
export interface GroupingSelectionEvent {
  /** The name of the selected grouping */
  grouping: string;
  /** The level of the selected grouping */
  level: number;
}

/** The name of the grouping used to deselect groupings. */
const BLANK_GROUPING = '-';

@Component({
  selector: 'og-dropdown-item-chain',
  templateUrl: './dropdown-item-chain.component.html',
  styleUrls: ['./dropdown-item-chain.component.scss']
})
export class DropdownItemChainComponent implements OnChanges {
  /** The grouping items to display. */
  @Input() groupingItems: Grouping[];
  /** The selected grouping items. */
  @Input() selectedGroupings: string[];
  /** The name of the component, used for analytics. */
  @Input() name: string;
  /** Emits on item selection. */
  @Output() itemSelection = new EventEmitter<GroupingSelectionEvent>();

  formattedGroupingItems: NamedValue<string>[][];

  // -------------------------------------------------------------------------
  onGroupingSelected(level: number, grouping: string) {
    this.itemSelection.next({ level, grouping });
  }

  ngOnChanges() {
    if (!!this.groupingItems && !!this.selectedGroupings) {
      this.formattedGroupingItems = this.formatGroupingItems(
        this.groupingItems,
        this.selectedGroupings
      );
    }
  }

  // Returns the possible grouping values given all the groupings and the selected grouping
  private formatGroupingItems(groupings: Grouping[], selected: string[]): NamedValue<string>[][] {
    const firstLevel = groupings.map(grouping => this.namedValue(grouping.name));
    let prevLevel = groupings;

    const nthLevels = selected.map(grouping => {
      prevLevel = prevLevel.find(({ name }) => name === grouping)
        ? prevLevel.find(({ name }) => name === grouping).children
        : [];
      return !!prevLevel
        ? [this.namedValue(BLANK_GROUPING), ...prevLevel.map(x => this.namedValue(x.name))]
        : [];
    });

    return [firstLevel, ...nthLevels.filter(item => item.length > 1)];
  }

  // -------------------------------------------------------------------------
  namedValue(value: string): NamedValue<string> {
    return {
      name: value,
      value: value
    };
  }
}
