<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

@if (enableSearch) {
<input type="text" class="filter" placeholder="Search" (keyup)="updateFilter($event)" />
}

<div class="container">
  <ngx-datatable
    #table
    class="material container__table"
    [rows]="dataTableRows"
    [columns]="dataTableColumns"
    [columnMode]="'force'"
    [selectionType]="'single'"
    [headerHeight]="40"
    [messages]="{ emptyMessage: loading ? 'Loading...' : 'No results' }"
    [loadingIndicator]="loading"
  ></ngx-datatable>

  @if (enableRowRemoval) {
  <div class="container__remove">
    @for (row of dataTableRows; track row; let i = $index) {
    <div class="remove__entry" (click)="onRowRemoval(row, i)">
      <div class="entry__icon">
        <og-icon name="x"></og-icon>
      </div>
    </div>
    }
  </div>
  }
</div>

<ng-template #defaultTemplate let-value="value" let-column="column">
  {{ value }}
</ng-template>

<ng-template #currencyTemplate let-value="value">
  {{ value | currencyValue: false:'1.0-0' }}
</ng-template>

<ng-template #dateTemplate let-value="value">
  {{ value | date: 'dd/MM/yyyy' }}
</ng-template>

<ng-template #numberTemplate let-value="value">
  {{ value | number: '1.0-2' }}
</ng-template>

<ng-template #percentTemplate let-value="value">
  {{ value | percent: '1.0-2' }}
</ng-template>
