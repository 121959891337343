<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div class="pair-container">
  <div class="pair-container__entry">
    <span class="entry__label">{{ leftPositionedEntry.name }}</span>
    <h2 class="entry__value">
      {{ leftPositionedEntry.value | currencyValue }}
    </h2>
  </div>

  <div class="pair-container__entry pair-container__entry--right">
    <span class="entry__label">{{ rightPositionedEntry.name }}</span>
    <h2 class="entry__value">
      {{ rightPositionedEntry.value | currencyValue }}
    </h2>
  </div>
</div>

<div class="chart">
  <og-highchart [options]="options"></og-highchart>
</div>

<div class="labels">
  <span class="labels__bound">-{{ maxBoundary | percent }}</span>
  <span>0</span>
  <span class="labels__bound">{{ maxBoundary | percent }}</span>
</div>
