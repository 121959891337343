/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

// legacy
import { HighchartComponent } from './legacy/highchart/highchart.component';
import { ColumnChartComponent } from './legacy/column-chart/column-chart.component';
import { SimpleDonutComponent } from './legacy/simple-donut/simple-donut.component';
import { DifferencePairChartComponent } from './legacy/difference-pair-chart/difference-pair-chart.component';
import { TimeSeriesChartComponent } from './legacy/timeseries-chart/timeseries-chart.component';
import { NavigatorChartComponent } from './legacy/navigator-chart/navigator-chart.component';
import { SunburstComponent } from './legacy/sunburst/sunburst.component';
import { NumericalLineChartComponent } from './legacy/numerical-line-chart/numerical-line-chart.component';

// modern
import { DonutChartComponent } from './modern/donut-chart/donut-chart.component';
import { OGTimeseriesComponent } from './modern/timeseries-chart/timeseries-chart.component';
import { OGPipesModule } from '@opengamma/ui';
import { OGColumnChartComponent } from 'app/shared/components/charts/modern/column-chart/column-chart.component';
import { OGStackedColumnChartComponent } from 'app/shared/components/charts/modern/stacked-column-chart/stacked-column-chart.component';
import { OGTimeseriesWithThresholdsComponent } from 'app/shared/components/charts/modern/timeseries-with-thresholds-chart/timeseries-with-thresholds-chart.component';

@NgModule({
  imports: [CommonModule, OGPipesModule],
  declarations: [
    OGTimeseriesComponent,
    OGTimeseriesWithThresholdsComponent,
    OGColumnChartComponent,
    OGStackedColumnChartComponent,
    HighchartComponent,
    ColumnChartComponent,
    SimpleDonutComponent,
    DifferencePairChartComponent,
    TimeSeriesChartComponent,
    NavigatorChartComponent,
    SunburstComponent,
    DonutChartComponent,
    NumericalLineChartComponent
  ],
  exports: [
    OGTimeseriesComponent,
    OGTimeseriesWithThresholdsComponent,
    OGColumnChartComponent,
    OGStackedColumnChartComponent,
    HighchartComponent,
    ColumnChartComponent,
    SimpleDonutComponent,
    DifferencePairChartComponent,
    TimeSeriesChartComponent,
    NavigatorChartComponent,
    SunburstComponent,
    DonutChartComponent,
    NumericalLineChartComponent
  ]
})
export class OGChartsModule {}
