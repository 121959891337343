/*
 * Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { environment } from './default.environment';
import { Location } from '@angular/common';

function getApiPath(path: string): string {
  return Location.joinWithSlash(environment.apiPath, path);
}

export function carmaApiPath(): string {
  return getApiPath(environment.serviceApiPaths?.carma || 'analytics-api');
}

export function marginApiPath(): string {
  return getApiPath(environment.serviceApiPaths?.margin || 'margin-api');
}

export function treasuryApiPath(): string {
  return getApiPath(environment.serviceApiPaths?.treasury || 'treasury-api');
}

export function datadogApiPath(): string {
  return getApiPath(environment.serviceApiPaths?.datadog || 'datadog');
}
