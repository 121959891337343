<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<!--Cell in column-->
<og-magic-table-value
  class="cell cell--{{ theme }} cell--{{ theme }}-level{{ row.level }}"
  [type]="column.type"
  [bound]="column.bound"
  [maxBarValue]="column.maxBarValue"
  [value]="getValue()"
  [subtext]="getSubtext()"
  [options]="getOptions()"
  [isEditing]="isCellBeingEdited()"
  [level]="row.level"
  [isExpanded]="!!row.children && expandedRowIndexes.includes(row.index)"
  [editedValue]="row.temporaryValue"
  [isExpandable]="column.isExpandable && !!row.children && row.children.length > 0"
  [displayPlaceholder]="
    column.index === 0 &&
    expandedRowIndexes.includes(row.parentIndex) &&
    !(row.children && row.children.length)
  "
  [class.cell--info]="row.message?.type === 'info'"
  [class.cell--error]="row.message?.type === 'error'"
  [class.cell--warning]="row.message?.type === 'warning'"
  [class.cell--expandable]="row.children && row.children.length"
  [class.cell--no-border]="disableCellBorder && (row.level === 1 || !row.children)"
  [ngStyle]="getStyles()"
  (click)="onCellClick(row, column)"
  (editedValueChange)="onValueEdited(row, column, $event)"
></og-magic-table-value>

<!--Childen rows-->
@if (row.children) {
<div
  class="children-container"
  [class.children-container--left-padded]="column.index === 0"
  [class.children-container--border-bottom]="
    !!row.children && expandedRowIndexes.includes(row.index)
  "
>
  @if (column.index === 0) {
  <div
    class="children-container__border children-container__border--{{ theme }}-level{{ row.level }}"
  ></div>
  } @for (childRow of row.children.slice(0, displayedChildCount[row.index]); track childRow) { @if
  (expandedRowIndexes.includes(row.index)) {
  <og-magic-table-cell
    [class.child-row]="column.index > 0"
    [column]="column"
    [editingColumn]="editingColumn"
    [row]="childRow"
    [editingRow]="editingRow"
    [expandedRowIndexes]="expandedRowIndexes"
    [disableCellBorder]="row.children.length === 1 || checkIfLastChild(childRow)"
    [theme]="theme"
    [displayedChildCount]="displayedChildCount"
    (displayMoreChildren)="onLoadMoreClick($event)"
    (cellClicked)="onCellClick($event.row, $event.column)"
    (valueEditedChange)="onValueEdited($event.row, $event.column, $event.value)"
  ></og-magic-table-cell>
  } } @if ( row.children.length > displayedChildCount[row.index] &&
  expandedRowIndexes.includes(row.index) ) {
  <div
    class="children-container__load-more children-container__load-more--{{ theme }}-level{{
      row.level + 1
    }}"
    (click)="onLoadMoreClick(row.index)"
  >
    @if (column.index === 0) {
    <div class="load-more__text">Load more...</div>
    }
  </div>
  }
</div>
}
