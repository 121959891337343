<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div class="table__wrapper">
  <div class="border-top"></div>
  <div class="placeholder__table">
    <div class="border-side"></div>
    @for (columns of columns; track columns) {
    <div class="parent__column">
      @for (row of rows; track row) {
      <div class="row__cell"></div>
      }
    </div>
    }
    <div class="border-side"></div>
    <div class="placeholder__wrapper">
      <og-icon class="calendar__icon" name="table" [size]="IconSize.XXLarge"></og-icon>
      <p class="placeholder__text">{{ placeholderText }}</p>
    </div>
  </div>
  <div class="border-bottom"></div>
</div>
