<!--
  ~ Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div class="alert alert--{{ type }}" fxLayout="row">
  <og-icon
    [name]="alertIconsByType[type]"
    class="alert__icon alert__icon--{{ type }}"
    [stroke]="IconStroke.XThick"
  ></og-icon>
  <div>
    <ng-content></ng-content>
  </div>
</div>
