/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import * as _ from 'lodash';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { defaultColumnOptions } from './options/column.highchart-options';
import { OGHighchartDirective } from 'app/shared/components/charts/modern/highchart/highchart.component';
import { ColumnChartData } from 'app/shared/components/charts/modern/column-chart/column-chart.model';
import { PageWidthLayoutService } from '@opengamma/ui';

@Component({
  selector: 'og-column',
  templateUrl: '../highchart/highchart.component.html',
  styleUrls: ['../highchart/highchart.component.scss']
})
export class OGColumnChartComponent extends OGHighchartDirective implements OnChanges {
  @Input() data: ColumnChartData[];

  constructor(pageWidthLayoutService: PageWidthLayoutService) {
    super(pageWidthLayoutService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.options = {
      ..._.merge(this.options, defaultColumnOptions),
      ...this.createData()
    };
    super.ngOnChanges(changes);
  }

  private createData(): Highcharts.Options {
    return { series: this.data } as Highcharts.Options;
  }
}
