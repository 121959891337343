/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  Output
} from '@angular/core';
import * as _ from 'lodash';
import { TableColumn, TableColumnGroup } from '@opengamma/ui';

/**
 * If this component is to be used within TableWrapperComponent, but with its functionality handled outside of
 *     TableWrapperComponent, then you must set ** exportFunctionality = true **.
 */
@Component({
  selector: 'og-table-columns-select',
  templateUrl: './table-columns-select.component.html',
  styleUrls: ['./table-columns-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableColumnsSelectComponent {
  /** Prevent TableWrapperComponent from handling this components logic. */
  @Input() exportFunctionality = false;

  @Input() enableSelectAllOption = true;
  @Input() enableSelectNoneOption = true;

  @Output() onColumnSelectionChange = new EventEmitter<TableColumnGroup[]>();

  _columnGroups: TableColumnGroup[];
  _toggleableColumnGroups: TableColumnGroup[];

  @Input() set columnGroups(columnGroups: TableColumnGroup[]) {
    this._columnGroups = columnGroups;
    this._toggleableColumnGroups = this.getToggleableColumns(columnGroups);
    this.cdRef.markForCheck();
  }
  get columnGroups(): TableColumnGroup[] {
    return this._columnGroups;
  }

  constructor(public cdRef: ChangeDetectorRef) {}

  onSelectAllColumns(): void {
    this.setVisibilityForAllColumns(true);
    this.onColumnSelectionChange.emit(this.columnGroups);
  }

  onDeselectAllColumns(): void {
    this.setVisibilityForAllColumns(false);
    this.onColumnSelectionChange.emit(this.columnGroups);
  }

  setVisibilityForAllColumns(isVisible: boolean): void {
    this.columnGroups = this.columnGroups.map(columnGroup => ({
      ...columnGroup,
      columns: columnGroup.columns.map(column => ({
        ...column,
        isVisible
      }))
    }));
  }

  areAllColumnsSelected(): boolean {
    return this.getNumberOfSelectedColumns() === this.getMaximumNumberOfColumns();
  }

  getMaximumNumberOfColumns(): number {
    return this._toggleableColumnGroups.flatMap(group => group.columns).length;
  }

  areAllColumnsDeselected(): boolean {
    return this.getNumberOfSelectedColumns() === 0;
  }

  getSelectedColumnsText(): string {
    return `${this.getNumberOfSelectedColumns()} / ${this.getMaximumNumberOfColumns()} columns`;
  }

  onColumnSelection(selectedColumns: TableColumn[]): void {
    this.columnGroups = this.columnGroups.map(columnGroup => ({
      ...columnGroup,
      columns: columnGroup.columns.map(column => {
        const isColumnSelected = selectedColumns.some(
          selectedColumn => selectedColumn.id === column.id
        );
        return { ...column, isVisible: isColumnSelected };
      })
    }));

    this.onColumnSelectionChange.emit(this.columnGroups);
  }

  /**
   * If a column group option is selected, either select or deselect all columns belonging to
   *    that column group.
   */
  onGroupSelected(group: TableColumnGroup): void {
    const selectedColumnsWithoutSelectedGroupsColumns = this.getSelectedColumns().filter(
      column => !group.columns.some(groupColumn => groupColumn.id === column.id)
    );

    if (this.isGroupOptionSelected(group)) {
      this.onColumnSelection(selectedColumnsWithoutSelectedGroupsColumns);
    } else {
      this.onColumnSelection([...selectedColumnsWithoutSelectedGroupsColumns, ...group.columns]);
    }
  }

  isGroupOptionSelected(group: TableColumnGroup): boolean {
    const groupColumnIds = group.columns.map(column => column.id);
    return this.areAllColumnIdsSelected(groupColumnIds);
  }

  isColumnSelected(column: TableColumn): boolean {
    return this.getSelectedColumns().some(selectedColumn => selectedColumn.id === column.id);
  }

  getSelectedColumns(): TableColumn[] {
    return this._toggleableColumnGroups
      .flatMap(columnGroup => columnGroup.columns)
      .filter(column => column.isVisible);
  }

  trackColumnGroups(columnGroup: TableColumnGroup): string {
    return columnGroup.columns?.reduce((acc, column) => acc + column.id, '');
  }

  trackColumns(column: TableColumn): string {
    return column.id;
  }

  private getNumberOfSelectedColumns(): number {
    return this.getSelectedColumns().length;
  }

  private areAllColumnIdsSelected(columnIds: string[]): boolean {
    const columns = this.getSelectedColumns().map(column => column.id);
    return _.intersection(columns, columnIds).length === columnIds.length;
  }

  private getToggleableColumns(columnGroups: TableColumnGroup[]): TableColumnGroup[] {
    if (!columnGroups) {
      return undefined;
    }

    return columnGroups.map(columnGroup => ({
      ...columnGroup,
      columns: columnGroup.columns.filter(column => !column.preventVisibilityToggling)
    }));
  }
}
