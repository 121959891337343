<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="chartModes">
  <div class="toggle" fxLayout="row" fxLayoutAlign="center center">
    <ng-container *ngFor="let mode of chartModes; let last = last">
      <div
        class="toggle__item"
        [class.toggle__item--selected]="mode.value === selectedChartMode"
        (click)="onToggleChange.emit(mode.value)"
      >
        {{ mode.name }}
      </div>
      <div class="toggle__divider" *ngIf="!last"></div>
    </ng-container>
  </div>
</ng-container>
