<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div fxLayout="row" fxLayoutGap="48px" class="headlines">
  <ng-container *ngIf="!areHeadlinesLoading; else loading">
    <og-labeled-entity
      class="headline"
      *ngFor="let headline of headlineValues"
      [label]="headline.name"
      theme="dark"
    >
      <div content fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
        <ng-container *ngIf="headline?.value?.signed; else simpleEntity">
          <og-change-currency-value
            *ngIf="headline?.value?.amount"
            class="reduction__amount"
            [invertSignColor]="invertSignColor"
            [currency]="headline.value.amount"
          ></og-change-currency-value>
        </ng-container>
        <ng-template #simpleEntity>
          {{ headline?.value?.amount | currencyValue: true:'1.2-2' }}
        </ng-template>
        <div class="reduction__percentage" *ngIf="headline?.value?.percentage">
          ({{ headline.value.percentage | percent: '1.0-2' }})
        </div>
      </div>
    </og-labeled-entity>
  </ng-container>
  <ng-template #loading><og-spinner size="small" class="spinner"></og-spinner></ng-template>
</div>
