/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import {
  DatepickerPortalService,
  PageLayout,
  PageWidthLayoutService,
  ValuationDateDatepickerComponent,
  FromFileViewerActions,
  ProductTheme
} from '@opengamma/ui';
import { ComponentPortal, Portal } from '@angular/cdk/portal';
import { Store } from '@ngrx/store';
import { State } from 'app/shared/store/utils/router-state-utils';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'og-screens-menu',
  templateUrl: './screens-menu.component.html',
  styleUrls: ['./screens-menu.component.scss']
})
export class ScreensMenuComponent implements OnInit, OnChanges {
  sharedDatepickerPortal: ComponentPortal<ValuationDateDatepickerComponent>;
  datepickerPortal$: Observable<Portal<any>>;
  pageLayout$: Observable<PageLayout>;

  /** The theme of the configuration bar. */
  @Input() theme: ProductTheme | 'dark';

  @Input() displayDatepicker = true;
  @Input() displayFileViewerButton = true;
  @Input() displayScreenMenu = true;

  areFilesLoading$: Observable<boolean>;

  constructor(
    private portalService: DatepickerPortalService,
    private store: Store<State>,
    private pageWidthLayoutService: PageWidthLayoutService
  ) {}

  ngOnInit(): void {
    this.pageLayout$ = this.pageWidthLayoutService.getPageLayout();
    this.sharedDatepickerPortal = new ComponentPortal(ValuationDateDatepickerComponent);
    this.datepickerPortal$ = this.portalService.currentPortal.pipe(
      map(portal => portal || this.sharedDatepickerPortal)
    );
  }

  ngOnChanges({ displayFileViewerButton, displayScreenMenu }: SimpleChanges): void {
    if (displayFileViewerButton?.currentValue === undefined) {
      this.displayFileViewerButton = true;
    }

    if (displayScreenMenu?.currentValue === undefined) {
      this.displayScreenMenu = true;
    }
  }

  onFileViewerSidePanelToggle(): void {
    this.store.dispatch(FromFileViewerActions.toggleFileViewerVisibility());
  }
}
