/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { RouterModule } from '@angular/router';
import { PortalModule } from '@angular/cdk/portal';
import { OGPipesModule, OGFormsModule, OGMiscModule } from '@opengamma/ui';
import { LayoutContainerComponent } from 'app/shared/components/layout/layout-container/layout-container.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    OGPipesModule,
    MatDatepickerModule,
    RouterModule,
    MatTooltipModule,
    MatFormFieldModule,
    MatInputModule,
    PortalModule,
    OGFormsModule,
    OGMiscModule
  ],
  declarations: [LayoutContainerComponent],
  exports: [LayoutContainerComponent]
})
export class OGLayoutModule {}
