/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { SummaryCardValue } from './summary-card-metrics.model';

@Component({
  selector: 'og-summary-card-metrics',
  templateUrl: './summary-card-metrics.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryCardMetricsComponent {
  @Input() value: SummaryCardValue;
}
