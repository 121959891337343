/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { DOCUMENT } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { State } from 'app/shared/store/reducers';
import * as fromUser from 'app/user-auth/store/user-auth.selectors';
import {
  FromMarginScreensCommonSelectors,
  FromServiceDeskSelectors,
  UserAuthModels,
  PageLayout,
  PageWidthLayoutService,
  UserDetails
} from '@opengamma/ui';
import { filterUndefined, observableCombineLatest } from 'app/shared/rxjs/rxjs.utils';
import { CarmaProduct } from 'app/user-auth/models';
import { selectUrl } from 'app/shared/store/utils/router-state-utils';
import { map, tap } from 'rxjs/operators';
import * as permissionUtils from 'app/user-auth/services/auth/permission.utils';
import { getUrlPathSegment } from 'app/user-auth/services/auth/permission.utils';
import { ProductTheme } from 'app/shared/models';
import { NewFeatureFlagService } from 'app/shared/services/new-feature-flag.service';

@Component({
  selector: 'og-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {
  // needed for when the route goes outside the product/module/screen realm - for example on the about page
  lastAccessedProduct: string;

  theme$: Observable<ProductTheme>;
  route$: Observable<string>;

  userDetail$: Observable<UserDetails>;

  treasuryProduct$: Observable<CarmaProduct | undefined>;
  products$: Observable<CarmaProduct[]>;
  productMenu$: Observable<UserAuthModels.AvailableProduct[]>;
  modules$: Observable<UserAuthModels.AvailableModule[]>;
  screens$: Observable<UserAuthModels.AvailableScreen[]>;

  selectedProduct$: Observable<string>;
  selectedModule$: Observable<string>;
  selectedScreen$: Observable<string>;
  userGuideRef$: Observable<string>;

  displayFileViewerButton$: Observable<boolean>;
  displayScreenMenu$: Observable<boolean>;

  pageLayout$: Observable<PageLayout>;
  permissionToDisplayServiceDesk$: Observable<boolean>;

  constructor(
    private store: Store<State>,
    private pageWidthLayoutService: PageWidthLayoutService,
    private newFeatureFlagService: NewFeatureFlagService,
    @Inject(DOCUMENT) document: HTMLDocument
  ) {
    this.pageLayout$ = this.pageWidthLayoutService.getPageLayout();
    this.theme$ = this.store.pipe(select(FromMarginScreensCommonSelectors.getProductTheme));
    this.userDetail$ = store.pipe(select(fromUser.getUserData), filterUndefined());

    this.setFavicon();
    this.setAvailableEntities();
    this.permissionToDisplayServiceDesk$ = this.store.select(
      FromServiceDeskSelectors.getPermissionStatus
    );
  }

  private setAvailableEntities() {
    const urlPath = this.store.pipe(select(selectUrl), filterUndefined());
    // ========= Products
    this.products$ = this.store.pipe(
      select(fromUser.getPermissionsData),
      filterUndefined(),
      map(permissions => permissions.productDefinition.products)
    );

    this.treasuryProduct$ = this.products$.pipe(
      map(products => products.find(x => x.productName === 'Treasury'))
    );

    this.productMenu$ = this.products$.pipe(
      map(products => products.map(({ dateType, modules, ...props }) => props))
    );

    this.selectedProduct$ = observableCombineLatest(
      this.products$.pipe(map(products => products.map(product => product.urlPath))),
      urlPath.pipe(map(path => getUrlPathSegment(path, 'product')))
    ).pipe(
      map(([products, productUrl]) => this.getProductUrl(products, productUrl)),
      tap(this.setLastAccessedProduct)
    );

    // ========= Modules
    this.modules$ = observableCombineLatest(this.products$, this.selectedProduct$).pipe(
      map(
        ([products, selectedProduct]) =>
          products.find(product => product.urlPath === selectedProduct)?.modules ||
          products[0].modules
      )
    );

    this.selectedModule$ = urlPath.pipe(map(path => getUrlPathSegment(path, 'module')));

    const displayOptions$: Observable<UserAuthModels.DisplayOptions> = observableCombineLatest(
      this.modules$,
      this.selectedModule$
    ).pipe(
      map(
        ([modules, selectedModule]) =>
          modules.find(module => module.urlPath === selectedModule)?.displayOptions
      )
    );

    this.displayFileViewerButton$ = observableCombineLatest(
      displayOptions$,
      this.store.select(fromUser.getUserData)
    ).pipe(
      map(
        ([options, userDetails]) =>
          (options?.displayFileViewer || options?.displayFileViewer === undefined) &&
          !permissionUtils.isTenantJscc(userDetails)
      )
    );
    this.displayScreenMenu$ = displayOptions$.pipe(map(options => options?.displayScreenMenu));

    // ========= Screens
    this.screens$ = observableCombineLatest(this.modules$, this.selectedModule$).pipe(
      map(
        ([modules, selectedModule]) =>
          modules.find(module => module.urlPath === selectedModule)?.screens
      )
    );
    this.selectedScreen$ = urlPath.pipe(map(path => getUrlPathSegment(path, 'screen')));

    this.userGuideRef$ = this.store.select(fromUser.getUserData).pipe(
      map(userDetails => {
        if (permissionUtils.isTenantJsccEtd(userDetails)) {
          return 'assets/jscc-user-guide.pdf';
        }
        if (permissionUtils.isTenantJsccEtdIrs(userDetails)) {
          return 'assets/jscc-user-guide-irs.pdf';
        }
        return 'assets/user-guide.pdf';
      })
    );
  }

  private setLastAccessedProduct(product: string): void {
    this.lastAccessedProduct = product;
  }

  private getProductUrl(products: string[], productUrl: string): string {
    return products.includes(productUrl) ? productUrl : this.lastAccessedProduct || products[0];
  }

  private setFavicon() {
    // This component is never destroyed so there's no subscription leak
    this.theme$.subscribe(theme =>
      document.getElementById('favicon').setAttribute('href', `assets/favicons/${theme}.ico`)
    );
  }

  togglePageLayout(): void {
    this.pageWidthLayoutService.togglePageWidthLayout();
  }

  onUserDownloadClick(): void {
    this.newFeatureFlagService.markFeatureAsInteractedWith('user-guide-download');
  }

  isFeatureNew(): boolean {
    return this.newFeatureFlagService.isFeatureNew('user-guide-download');
  }
}
