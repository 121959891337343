/*
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { IconStroke } from '@opengamma/ui';

@Component({
  selector: 'og-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AlertComponent {
  /** The type of the alert. */
  @Input() type: 'success' | 'warning' | 'danger' | 'dark' | 'info' = 'info';

  IconStroke = IconStroke;

  alertIconsByType = {
    success: 'check',
    warning: 'exclamation-mark',
    danger: 'unlink',
    dark: 'info-small',
    info: 'info-small'
  };
}
