/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import {
  CellSelectionEvent,
  FormattedColumn,
  FormattedRow,
  MagicTableOptions,
  MagicTableValue,
  MagicTableValueSubtext,
  ValueChangeEvent
} from 'app/shared/legacy-components/display-structures/magic-table/models';
import { getCellOptions } from 'app/shared/legacy-components/display-structures/magic-table/magic-table.utils';

/** Magic table cell. */
@Component({
  selector: 'og-magic-table-cell',
  templateUrl: './magic-table-cell.component.html',
  styleUrls: ['./magic-table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MagicTableCellComponent {
  /** The indexes of the expanded rows */
  @Input() expandedRowIndexes: number[];
  /** The column of the row being displayed */
  @Input() column: FormattedColumn;
  /** The column being edited, if any */
  @Input() editingColumn: FormattedColumn;
  /** The row to display */
  @Input() row: FormattedRow;
  /** The row being edited, if any */
  @Input() editingRow: FormattedRow;
  /** True if the cell border-bottom should be disabled */
  @Input() disableCellBorder: boolean;
  /** The stylistic theme of the magic table. */
  @Input() theme: 'light' | 'dark' | 'navy';
  /** The number of children to display, by index. */
  @Input() displayedChildCount: number[];

  /** Emits when a cell is clicked. */
  @Output() cellClicked = new EventEmitter<CellSelectionEvent>();
  /** Emits when the value is being edited. */
  @Output() valueEditedChange = new EventEmitter<ValueChangeEvent>();
  /** Emits the index when more children are requested. */
  @Output() displayMoreChildren = new EventEmitter<number>();

  /**
   * Propagates up a cell click of the current row.
   */
  onCellClick(row: FormattedRow, column: FormattedColumn) {
    this.cellClicked.next({ row, column });
  }

  /** Loads more children when the respective button is clicked. */
  onLoadMoreClick(index: number) {
    this.displayMoreChildren.emit(index);
  }

  /**
   * Propagates a value change in the current row
   * If a value is edited in the current row - the row, column and edited value are passed in as args
   *
   * @param row the row being edited
   * @param column the column being edited
   * @param value the changed value
   */
  onValueEdited(row: FormattedRow, column: FormattedColumn, value: MagicTableValue) {
    this.valueEditedChange.next({ row, column, value });
  }

  isCellBeingEdited() {
    return this.editingRow === this.row && this.editingColumn === this.column;
  }

  checkIfLastChild(childRow: FormattedRow): boolean {
    return this.row.children[this.row.children.length - 1].index === childRow.index;
  }

  getOptions() {
    return getCellOptions(this.column, this.row);
  }

  getStyles(): object {
    const cellOptions = this.getOptions();

    const getProperty = (property: keyof MagicTableOptions) => {
      return cellOptions[property];
    };

    return {
      'white-space': getProperty('whiteSpace'),
      'font-weight': getProperty('fontWeight'),
      'font-style': getProperty('fontStyle'),
      color: getProperty('color'),
      'background-color': getProperty('backgroundColor'),
      cursor: getProperty('cursor'),
      'font-size': getProperty('fontSize'),
      'border-bottom': getProperty('border') && getProperty('border')['borderBottom'],
      'border-top': getProperty('border') && getProperty('border')['borderTop'],
      'border-right': getProperty('border') && getProperty('border')['borderRight'],
      'border-left': getProperty('border') && getProperty('border')['borderLeft'],
      height: `${!!getProperty('height') ? getProperty('height') + 'px' : undefined}`,
      'max-height': `${!!getProperty('height') ? getProperty('height') + 'px' : undefined}`,
      // account for the additional width provided by the left border of each expanded level (currently 15px)
      width: `${
        !!getProperty('width')
          ? (getProperty('width') as number) - this.row.level * 15 + 'px'
          : undefined
      }`,
      'max-width': `${
        !!getProperty('width')
          ? (getProperty('width') as number) - this.row.level * 15 + 'px'
          : undefined
      }`
    };
  }

  getValue() {
    return this.row.value && (this.row.value as MagicTableValueSubtext).subtext
      ? (this.row.value as MagicTableValueSubtext).value
      : this.row.value;
  }

  getSubtext() {
    return this.row.value && (this.row.value as MagicTableValueSubtext).subtext
      ? (this.row.value as MagicTableValueSubtext).subtext
      : undefined;
  }
}
