/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { select, Store } from '@ngrx/store';
import { AuthService } from 'app/user-auth/services/auth/auth.service';
import { UserService } from 'app/user-auth/services/user/user.service';
import * as fromUser from 'app/user-auth/store/user-auth.selectors';
import { State } from 'app/shared/store/reducers';
import { observableOf, filterUndefined } from 'app/shared/rxjs/rxjs.utils';
import { catchError, map, switchMap, withLatestFrom, tap, filter } from 'rxjs/operators';
import * as userActions from './user-auth.actions';
import { of } from 'rxjs';
import { convertLegacyPermissionsToProductDefinition } from '../services/auth/local-product.definition';
import * as permissionUtils from 'app/user-auth/services/auth/permission.utils';
import {
  UserDetailsService,
  FromServiceDeskActions,
  FromScreenStatusActions,
  LOCAL_PRODUCT_DEFINITION,
  ProductType
} from '@opengamma/ui';
import { JSCCConsentService } from '../services/auth/jscc-consent.service';
import { MatDialog } from '@angular/material/dialog';
import { TermsConsentComponent } from 'app/shared/components/terms-consent/terms-consent.component';
import { carmaApiPath } from 'environments/environment.utils';

@Injectable()
export class UserAuthEffects {
  // Login
  // ----------------------------------------------------------------------
  login = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.login.load),
      switchMap(() =>
        this.authService.authenticate().pipe(
          tap(userInfo => this.userDetailsService.setUser(userInfo)),
          map(userInfo => userActions.login.success({ data: userInfo })),
          catchError(err => {
            console.warn(err);
            return observableOf(userActions.login.error(err));
          })
        )
      )
    )
  );

  loginSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.login.success),
      withLatestFrom(this.store.pipe(select(fromUser.getUserData), filterUndefined())),
      switchMap(() => [
        userActions.permissions.load(),
        FromServiceDeskActions.serviceDeskData.load()
      ])
    )
  );

  jsscConsentNeededCheck = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.login.success),
      filter(user => user.data.jsccTermsConsentRequired),
      switchMap(() => [userActions.getRequireJSCCTermsConsent.load()])
    )
  );

  jsscConsentLoad = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getRequireJSCCTermsConsent.load),
      switchMap(() =>
        this.jsccConsentService.getDoesUserRequireJsccTermsConsent().pipe(
          map(response =>
            userActions.getRequireJSCCTermsConsent.success({
              data: !response.requireJsccTermsConsent
            })
          ),
          catchError(error => {
            console.warn(error);
            return [
              userActions.getRequireJSCCTermsConsent.error({ error: { message: error.message } })
            ];
          })
        )
      )
    )
  );

  jsscConsentSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getRequireJSCCTermsConsent.success),
      filter(isAccepted => isAccepted.data === false),
      switchMap(() => {
        const modal = this.matDialog.open(TermsConsentComponent, { disableClose: true });
        return modal.componentInstance.acceptClicked.pipe(
          map(isAccepted => userActions.consentToJSCCTerms.load(isAccepted))
        );
      })
    )
  );

  consentToJSCCTerms = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.consentToJSCCTerms.load),
      switchMap(action =>
        this.jsccConsentService.updateJsccTermsConsent(action.data).pipe(
          map(() => {
            this.matDialog.closeAll();
            return userActions.consentToJSCCTerms.success({ data: action.data });
          }),
          catchError(error => [
            userActions.consentToJSCCTerms.error({ error: { message: error.message } })
          ])
        )
      )
    )
  );

  loginError = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.login.error),
        tap(() => this.authService.unauthenticate())
      ),
    { dispatch: false }
  );

  // Permissions
  // ----------------------------------------------------------------------
  loadPermissions = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.permissions.load),
      withLatestFrom(
        this.store.pipe(select(fromUser.getUserData), filterUndefined()),
        this.store.pipe(select(fromUser.isOpenGammaUser))
      ),
      switchMap(([, user, isOpenGammaUser]) =>
        this.authService.permissions().pipe(
          tap(permissions => (this.shouldRedirectToOGP(permissions) ? this.redirectToOGP() : null)),
          filter(permissions => !this.shouldRedirectToOGP(permissions)),
          switchMap(permissions => {
            const testCalcModeEnabled = this.isTestCalcModeEnabled();
            const canUserViewInternalScreens = isOpenGammaUser;
            //Disable the About screen for JSCC users
            const canUserViewLocalScreens = !permissionUtils.isTenantJscc(user);
            const productDefinition = convertLegacyPermissionsToProductDefinition(
              permissions,
              canUserViewInternalScreens,
              canUserViewLocalScreens,
              testCalcModeEnabled
            );
            const permissionMetadata = {
              legacyPermissions: permissions,
              productDefinition: productDefinition,
              canUserViewInternalScreens: canUserViewInternalScreens
            };
            return [
              FromScreenStatusActions.valuationDates.load(),
              userActions.permissions.success({ data: permissionMetadata })
            ];
          }),
          catchError(err => observableOf(userActions.permissions.error(err)))
        )
      )
    )
  );

  // Here we are fetching the query parameter `testCalcMode` from the URL to determine
  // whether we are fetching normal or test calc outputs. We can get away with checking
  // the URL here, because the permissions data is loaded before the app routing logic is set
  // (see app-routing.module) and header component is rendered (see header.component).
  private isTestCalcModeEnabled(): boolean {
    const queryParameterString = window.location.search;
    const urlParams = new URLSearchParams(queryParameterString);
    const testCalcMode = urlParams.get('testCalcMode');
    const testCalcModeEnabled = testCalcMode === 'true';
    this.store.dispatch(
      FromScreenStatusActions.selectTestCalcMode({ testCalcMode: testCalcModeEnabled })
    );
    return testCalcModeEnabled;
  }

  // Mfa
  // ----------------------------------------------------------------------
  updateMfa = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateMFA.load),
      withLatestFrom(this.store.pipe(select(fromUser.getUserData))),
      switchMap(([action, userInfo]) =>
        this.userService.updateMfa({ enabled: userInfo.isMfaEnabled }).pipe(
          map(() => userActions.updateMFA.success({ data: null })),
          catchError(err => observableOf(userActions.updateMFA.error(err)))
        )
      )
    )
  );

  mfaUpdate = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.setMfa),
      map(() => userActions.updateMFA.load())
    )
  );

  mfaSuccess = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.updateMFA.success),
        tap(() => this.authService.unauthenticate())
      ),
    { dispatch: false }
  );

  mfaFail = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.updateMFA.error),
      map(() => userActions.login.load())
    )
  );

  // get tenant demo status
  // ----------------------------------------------------------------------

  getDemoStatus = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.login.success),
      map(() => userActions.getDemoTenantStatus.load())
    )
  );

  setUserDemoStatus = createEffect(() =>
    this.actions$.pipe(
      ofType(userActions.getDemoTenantStatus.load),
      withLatestFrom(this.store.pipe(select(fromUser.getUserData), filterUndefined())),
      switchMap(([, userData]) =>
        this.authService.getDemoTenantStatus(userData.companyName).pipe(
          map(result => userActions.getDemoTenantStatus.success({ data: result })),
          catchError(err => observableOf(userActions.getDemoTenantStatus.error(err)))
        )
      )
    )
  );

  warmUp = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.permissions.success),
        withLatestFrom(this.store.select(fromUser.getProductDefinition)),
        filter(
          ([, definition]) =>
            definition.products.filter(product => product.productName === 'Treasury').length > 0
        ),
        switchMap(() =>
          this.authService.warmUp().pipe(
            catchError(err => {
              console.error(err);
              return of();
            })
          )
        )
      ),
    { dispatch: false }
  );

  constructor(
    private actions$: Actions,
    private store: Store<State>,
    private userService: UserService,
    private authService: AuthService,
    private userDetailsService: UserDetailsService,
    private jsccConsentService: JSCCConsentService,
    private matDialog: MatDialog
  ) {}

  private shouldRedirectToOGP(permissions): boolean {
    /* If Carma is opened from OGP it sends a 'ignoreRedirect' queryparam to stop user from being
            redirected back to OGP if OGP is configured as the default landing product
            */
    const ignoreRedirect = new URL(window.location.href).searchParams.get('ignoreRedirect');
    return !ignoreRedirect && permissions.defaultProduct === ProductType.OGP;
  }

  private redirectToOGP(): void {
    const redirectUrl = LOCAL_PRODUCT_DEFINITION(carmaApiPath())[ProductType.OGP].urlPath;
    window.location.href = redirectUrl;
  }
}
