<!--
  ~ Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div fxLayout="column" fxLayoutGap="8px">
  <div class="timeline__title" (click)="isCompact = !isCompact">
    {{ isCompact ? 'Show' : 'Hide' }} {{ timeline.title }}
    <og-icon [name]="isCompact ? 'caret-down-filled' : 'caret-up-filled'"></og-icon>
  </div>
  <ng-container *ngIf="!isCompact">
    <div class="timeline__content">
      <div
        *ngFor="let node of timeline.nodes; let isLast = last"
        fxLayout="row"
        fxLayoutAlign="start start"
      >
        <div fxLayout="row" fxLayoutAlign="start start">
          <div fxLayout="column" fxLayoutAlign="start center">
            <og-icon
              [name]="node.icon.name"
              class="color--{{ node.icon.color }} border--{{ node.icon.color }} timeline__icon"
              [size]="IconSize.XSmall"
            ></og-icon>
            <div *ngIf="!isLast" class="timeline__line"></div>
          </div>
          <og-xofy [data]="node.description" class="timeline__x-dash-y"></og-xofy>
        </div>
      </div>
    </div>
  </ng-container>
</div>
