/*
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { getRouterSelectors, RouterReducerState } from '@ngrx/router-store';
import { createFeatureSelector, Store, select } from '@ngrx/store';
import { UnaryFunction } from 'rxjs/internal/types';
import { Observable } from 'rxjs';
import { observablePipe, filterUndefined } from 'app/shared/rxjs/rxjs.utils';
import { withLatestFrom, filter } from 'rxjs/operators';
import { RouterStateUrl } from '@opengamma/ui';
import { getScreenUrlByTemplate } from 'app/user-auth/store/user-auth.selectors';

/**
 *  ngrx state interface for our router
 */
export interface State {
  router: RouterReducerState<RouterStateUrl>;
}

/**
 *  feature selector for the ngrx router instate the ngrx state
 */
export const selectRouter = createFeatureSelector<State, RouterReducerState<RouterStateUrl>>(
  'router'
);

/**
 *  route selectors provided by ngrx router
 */
export const {
  selectQueryParams, // select the current route query params
  selectQueryParam, // factory function to select a query param
  selectRouteParams, // select the current route params
  selectRouteParam, // factory function to select a route param
  selectRouteData, // select the current route data
  selectUrl // select the current url
} = getRouterSelectors(selectRouter);

/**
 * Observable utility that filters a stream based on the current route matching a provided route
 *
 * @param store a reference to the active store object containing the route state
 * @param screen a screen reference used for accessing the route to said screen
 * @return a new rxjs operator
 */
export function filterByRoute<T>(
  store: Store<State>,
  screen: string
): UnaryFunction<Observable<T>, Observable<[T, string, {}]>> {
  return observablePipe(
    withLatestFrom(
      store.pipe(select(selectUrl), filterUndefined()),
      store.pipe(select(getScreenUrlByTemplate))
    ),
    filter(([, currentUrl, screenUrls]) => currentUrl === screenUrls[screen])
  );
}
