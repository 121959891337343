/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { FormattedColumn, FormattedRow, MagicTableOptions } from './models';

export function getCellOptions(column: FormattedColumn, row?: FormattedRow): MagicTableOptions {
  const defaults = {
    textAlign: column.index === 0 ? 'left' : 'center'
  } as MagicTableOptions;

  if (!row || (!row.options && !row.cellStyles)) {
    return {
      ...defaults,
      ...column.options
    };
  }

  const cellOptions = !!row.cellStyles
    ? row.cellStyles(
        column.index,
        row.index,
        row.level,
        !!row.children ? row.children.length : 0,
        row.value
      )
    : row.options;

  if (!column.options) {
    return {
      ...defaults,
      ...cellOptions
    };
  }
  return {
    ...defaults,
    ...cellOptions,
    ...column.options
  };
}
