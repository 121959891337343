/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import * as _ from 'lodash';

import {
  CellDataType,
  CellType,
  DecoratedRow,
  FlatViewMetaData,
  SortData,
  TableColumn,
  TableColumnGroup,
  TableRow,
  TableRowValues,
  Comparators,
  EntryFieldDataType
} from '@opengamma/ui';
import { TreeTableColumnGroup } from 'app/shared/legacy-components/tree-table/models';

/**
 * Add isExpandable and isExpanded properties to each row. Used for representing their expansion status
 *    within the HTML template.
 */
export function convertRowGroup(rows: TableRow[], expansionSet: Set<string>): DecoratedRow[] {
  return rows?.map(row => {
    if (!row) {
      return undefined;
    }

    const isExpandable = row.children?.length > 0;
    const isExpanded = expansionSet?.has(row.id);

    return {
      ...row,
      isExpandable,
      isExpanded,
      children: isExpandable && isExpanded ? convertRowGroup(row.children, expansionSet) : undefined
    };
  });
}

export function getNewExpansionSet(
  nodePath: string[],
  currentSet: Set<string>,
  allowMultipleExpandingRows: boolean
): Set<string> {
  const lastNode = nodePath.slice(-1)[0];
  if (lastNode === undefined) {
    throw new Error('Row has no defined id');
  }
  if (currentSet.has(lastNode)) {
    currentSet.delete(lastNode);
  } else {
    if (allowMultipleExpandingRows) {
      currentSet.add(lastNode);
    } else {
      // if only one expanded row is allowed at any time per level,
      // delete all expanded rows so far and expand all nodes down the tree where the currently expanded node is
      currentSet = new Set<string>(nodePath.filter(pathPart => pathPart !== ''));
    }
  }
  return currentSet;
}

/**
 * Generate a sort data object, used to track the state of table sorting.
 */
export function getSortData(column: TableColumn, sortData: SortData): SortData {
  if (sortData && column.id === sortData.columnId) {
    return {
      ...sortData,
      sortOrder: sortData.sortOrder === 'desc' ? 'asc' : 'desc'
    };
  }

  return {
    columnId: column.id,
    columnType: column.type,
    sortOrder: 'desc'
  };
}

export function transformRowsToFlatView(
  rows: TableRow[],
  flatViewMetaData: FlatViewMetaData
): TableRow[] {
  return flatViewMetaData?.renderLeafView
    ? transformRows(rows, flatViewMetaData.columnToReplace.id, flatViewMetaData.columns, {})
    : rows;
}

function transformRows(
  rows: TableRow[],
  columnToReplace: string,
  newColumns: TableColumn[],
  newValues: object
): TableRow[] {
  return rows.flatMap(row => {
    const accumulatedNewValues = {
      ...newValues,
      [newColumns[0].id]: row.values[columnToReplace]
    };

    if (_.isEmpty(row.children)) {
      const rowWithNewValues = { ...row, values: { ...row.values, ...accumulatedNewValues } };
      return [rowWithNewValues];
    }
    return transformRows(row.children, columnToReplace, newColumns.slice(1), accumulatedNewValues);
  });
}

export function transformColumnsFromFlatView(
  groups: TreeTableColumnGroup[],
  flatViewMetaData: FlatViewMetaData
): TableColumnGroup[] {
  return groups.reduce((acc, group) => {
    const indexOfFirstFlatViewColumn = group.columns.findIndex(column =>
      flatViewMetaData.columns.find(flatViewCol => flatViewCol.id === column.id)
    );

    if (indexOfFirstFlatViewColumn === -1) {
      return [...acc, group];
    }

    const newGroupColumns = [
      ...group.columns.slice(0, indexOfFirstFlatViewColumn),
      flatViewMetaData.columnToReplace,
      ...group.columns.slice(indexOfFirstFlatViewColumn + flatViewMetaData.columns.length)
    ];

    if (newGroupColumns.length === 0) {
      return [...acc, group];
    }

    return [...acc, { ...group, columns: newGroupColumns }];
  }, []);
}

export function transformColumnsToFlatView(
  groups: TreeTableColumnGroup[],
  flatViewMetaData: FlatViewMetaData
): TableColumnGroup[] {
  return groups.reduce((acc, group) => {
    const indexOfColumnToReplace = group.columns.findIndex(
      column => column.id === flatViewMetaData.columnToReplace.id
    );

    if (indexOfColumnToReplace === -1) {
      return [...acc, group];
    }

    const newGroupColumns = [
      ...group.columns.slice(0, indexOfColumnToReplace),
      ...flatViewMetaData.columns,
      ...group.columns.slice(indexOfColumnToReplace + 1)
    ];

    return [...acc, { ...group, columns: newGroupColumns }];
  }, []);
}

/**
 * Sort a group of TableRow[] and their children by the sortData object properties.
 */
export function sortRows(rows: TableRow[], sortData: SortData): TableRow[] {
  return getSortedRows(rows, sortData)?.map(row => {
    return {
      ...row,
      children: row.children && sortRows(row.children, sortData)
    };
  });
}

/**
 * Sort TableRow[].
 */
function getSortedRows(rowsToSort: TableRow[], sortData: SortData): TableRow[] {
  if (!rowsToSort) {
    return undefined;
  }
  if (!sortData) {
    return rowsToSort;
  }

  const { sortOrder, columnId, columnType } = sortData;
  const comparatorForColumnType = getComparatorForColumnType(columnType);

  const comparator = (a: DecoratedRow, b: DecoratedRow) => {
    const valueOfA = a.values[columnId];
    const valueOfB = b.values[columnId];
    const result = comparatorForColumnType(valueOfA, valueOfB);
    return sortOrder === 'asc' ? -result : result;
  };

  return [...rowsToSort].sort(comparator);
}

/**
 * Find a given cell types comparator.
 */
function getComparatorForColumnType(
  columnType: CellType
): (a: CellDataType | EntryFieldDataType, b: CellDataType | EntryFieldDataType) => number {
  switch (columnType) {
    case 'text':
      return Comparators.text;

    case 'currency_value':
      return Comparators.currencyValue;

    case 'percentage':
    case 'number':
      return Comparators.number;

    case 'status':
      return Comparators.status;

    default:
      return Comparators.default;
  }
}

/**
 * Filter a TableRow[] and their children.
 * If any child node matches a filter, then display its parent nodes.
 * If any parent node matches a filter, then display all its child nodes.
 */
type FilterType = 'inclusive' | 'exclusive';
export function filterRows(
  rows: TableRow[],
  filters: string[],
  filterType: FilterType = 'inclusive'
): TableRow[] {
  if (!rows?.length) {
    return undefined;
  }

  return rows.reduce((acc, row) => {
    if (doesRowMatchFilters(row.values, filters, filterType)) {
      return [...acc, row];
    }

    if (row.children) {
      const filteredChildren = filterRows(row.children, filters, filterType);

      if (filteredChildren?.length > 0) {
        return [
          ...acc,
          {
            ...row,
            children: filteredChildren
          }
        ];
      }
    }

    return acc;
  }, []);
}

/**
 * Evaluate whether at least one provided filter matches with at least one value of type string.
 */
function doesRowMatchFilters(
  rowValues: TableRowValues,
  filters: string[],
  filterType: FilterType
): boolean {
  const doesStringMatch = (filter: string, value: string) =>
    value.toLowerCase().includes(filter.toLowerCase());

  return Object.entries(rowValues)
    .filter(([, value]) => typeof value === 'string')
    .some(([, value]: [string, string]) =>
      filterType === 'inclusive'
        ? filters.some(filter => doesStringMatch(filter, value))
        : filters.every(filter => doesStringMatch(filter, value))
    );
}

export function filterColumnsFromColumnGroups(
  columnGroups: TableColumnGroup[],
  columnsId: string[]
): TableColumnGroup[] {
  return columnGroups
    .reduce((prev, curr) => {
      return [
        ...prev,
        { ...curr, columns: curr.columns.filter(col => !columnsId.some(id => col.id === id)) }
      ];
    }, [])
    .filter(groups => groups.columns.length !== 0);
}
