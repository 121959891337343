<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div fxLayout="row" fxLayoutGap="48px" class="headlines">
  @if (!areHeadlinesLoading) { @for (headline of headlineValues; track headline) {
  <og-labeled-entity class="headline" [label]="headline.name" theme="dark">
    <div content fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="start center">
      @if (headline?.value?.signed) { @if (headline?.value?.amount) {
      <og-change-currency-value
        class="reduction__amount"
        [invertSignColor]="invertSignColor"
        [currency]="headline.value.amount"
      ></og-change-currency-value>
      } } @else {
      {{ headline?.value?.amount | currencyValue: true:'1.2-2' }}
      } @if (headline?.value?.percentage) {
      <div class="reduction__percentage">({{ headline.value.percentage | percent: '1.0-2' }})</div>
      }
    </div>
  </og-labeled-entity>
  } } @else {
  <og-spinner size="small" class="spinner"></og-spinner>
  }
</div>
