/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { State } from 'app/shared/store/reducers';
import { Store } from '@ngrx/store';
import { environment } from 'environments/default.environment';
import { FromMarginScreensCommonActions } from '@opengamma/ui';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(private injector: Injector) {}

  handleError(error: any) {
    const store = this.injector.get<Store<State>>(Store);

    if (!environment.suppressErrors) {
      if (!error.toString().includes('ExpressionChangedAfterItHasBeenCheckedError')) {
        store.dispatch(FromMarginScreensCommonActions.showErrorMessage({ error: error }));
      }
    }
    console.error(error);
  }
}
