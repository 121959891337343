<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<og-select
  *ngIf="columnGroups"
  selectPlacement="bottom-start"
  [multiple]="true"
  [selectedOptions]="getSelectedColumns()"
  (selectionChange)="onColumnSelection($event)"
  class="select"
>
  <og-select-label>
    <og-icon name="columns-3"></og-icon>
  </og-select-label>

  <og-select-trigger class="select__trigger">
    {{ getSelectedColumnsText() }}
  </og-select-trigger>

  <og-select-panel class="select__panel">
    <og-select-panel-title>
      Show or hide table columns
    </og-select-panel-title>

    <og-select-option
      *ngIf="enableSelectAllOption"
      (onSelect)="onSelectAllColumns()"
      [selected]="areAllColumnsSelected()"
      [detached]="true"
    >
      Select all
    </og-select-option>

    <og-select-option
      *ngIf="enableSelectNoneOption"
      (onSelect)="onDeselectAllColumns()"
      [selected]="areAllColumnsDeselected()"
      [detached]="true"
    >
      Select none
    </og-select-option>

    <ng-container *ngFor="let group of _toggleableColumnGroups; trackBy: trackColumnGroups">
      <ng-container *ngIf="!group.label; else displayAsGroup">
        <og-select-option
          *ngFor="let column of group.columns; trackBy: trackColumns"
          [value]="column"
          class="select__option"
        >
          <og-checkbox-noop [checked]="isColumnSelected(column)">
            {{ column.label }}
          </og-checkbox-noop>
        </og-select-option>
      </ng-container>

      <ng-template #displayAsGroup>
        <og-select-option
          (onSelect)="onGroupSelected(group)"
          [selected]="isGroupOptionSelected(group)"
          [detached]="true"
          class="select__option"
        >
          <og-checkbox-noop [checked]="isGroupOptionSelected(group)">
            {{ group.label }}
          </og-checkbox-noop>
        </og-select-option>

        <og-select-option
          *ngFor="let column of group.columns; trackBy: trackColumns"
          [value]="column"
          class="select__option select__option--indented"
        >
          <og-checkbox-noop [checked]="isColumnSelected(column)">
            {{ column.label }}
          </og-checkbox-noop>
        </og-select-option>
      </ng-template>
    </ng-container>
  </og-select-panel>
</og-select>
