/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { UserAuthState } from './user-auth.state';
import { createSelector, createFeatureSelector } from '@ngrx/store';
import { ProductDefinition } from 'app/user-auth/models';
import { ProductDateType } from 'app/user-auth/services/auth/local-product.definition';
import { EntityBreakdown, UserAuthModels } from '@opengamma/ui';

// -------------------------------------------------------------------------
export const getUserState = createFeatureSelector<UserAuthState>('user');

// -------------------------------------------------------------------------
export const getUser = createSelector(getUserState, state => state.user);
export const getUserData = createSelector(getUser, state => state.data);
export const isOpenGammaUser = createSelector(getUser, state =>
  state.data?.email?.includes('@opengamma.com')
);

export const isDemoTenant = createSelector(getUserState, state => state.isDemoTenant?.data);

// -------------------------------------------------------------------------
export const getPermissions = createSelector(getUserState, state => state.permissions);
export const getPermissionsData = createSelector(getPermissions, state => state.data);
export const getModuleHistoryMap = createSelector(getUserState, state => state.moduleToScreenMap);

export const getIsJSCCTCsAccepted = createSelector(
  getUserState,
  state => state.isJSCCTCsAccepted.data
);

export const getIsJSCCTCsAcceptedLoadable = createSelector(
  getUserState,
  state => state.isJSCCTCsAccepted
);

export const getAreAllNeededApiCallsCompleted = createSelector(
  getIsJSCCTCsAcceptedLoadable,
  isJSCCTCsAcceptedLoadable => !isJSCCTCsAcceptedLoadable.loading
);

export const getProductDefinition = createSelector(
  getPermissionsData,
  data => data?.productDefinition
);

export const getScreenUrlByTemplate = createSelector(getProductDefinition, productDefinition => {
  if (!productDefinition) {
    return {};
  }

  const map: { [key: string]: string } = {};
  productDefinition.products.forEach(product => {
    product.modules.forEach(module => {
      module.screens.forEach(screen => {
        if (map[screen.screenTemplate]) {
          map[screen.screenTemplate + '_OTHER'] = [
            '',
            product.urlPath,
            module.urlPath,
            screen.urlPath
          ].join('/');
        } else {
          map[screen.screenTemplate] = ['', product.urlPath, module.urlPath, screen.urlPath].join(
            '/'
          );
        }
      });
    });
  });
  return map;
});

export const getScreensByUrl = createSelector(getProductDefinition, productDefinition => {
  if (!productDefinition) {
    return {};
  }

  return getScreenMapByUrl(productDefinition);
});

export const getDateTypesByUrl = createSelector(getProductDefinition, productDefinition => {
  if (!productDefinition) {
    return {};
  }

  return getDateTypeMapByUrl(productDefinition);
});

export const getDateTypeForCurrentUrl = createSelector(
  getDateTypesByUrl,
  getProductDefinition,
  (dateTypesByUrl, productDefinition) => {
    if (dateTypesByUrl[location.pathname]) {
      return dateTypesByUrl[location.pathname];
    }

    // When the user navigates to a URL that is not defined within the product definition,
    // set the dateType to that provided within their first available product
    const dateTypesAvailable = productDefinition?.products.map(product => product.dateType);
    if (dateTypesAvailable !== undefined && dateTypesAvailable.length > 0) {
      return dateTypesAvailable[0];
    }

    return 'marginReportingDate';
  }
);

export const getScreenNameByPermission = createSelector(getProductDefinition, productDefinition => {
  if (!productDefinition) {
    return {};
  }

  return getScreenNameMapByPermission(productDefinition);
});

function getScreenMapByUrl(
  productDefinition: ProductDefinition
): { [url: string]: UserAuthModels.AvailableScreen } {
  const map = {};
  productDefinition.products.forEach(product => {
    product.modules.forEach(module => {
      module.screens.forEach(screen => {
        const url = ['', product.urlPath, module.urlPath, screen.urlPath].join('/');
        map[url] = screen;
      });
    });
  });
  return map;
}

function getDateTypeMapByUrl(
  productDefinition: ProductDefinition
): { [url: string]: ProductDateType } {
  const map = {};
  productDefinition.products.forEach(product => {
    product.modules.forEach(module => {
      module.screens.forEach(screen => {
        const url = ['', product.urlPath, module.urlPath, screen.urlPath].join('/');
        map[url] = product.dateType;
      });
    });
  });
  return map;
}

function getScreenNameMapByPermission(
  productDefinition: ProductDefinition
): { [permission: string]: EntityBreakdown } {
  const map = {};
  productDefinition.products.forEach(product => {
    product.modules.forEach(module => {
      module.screens.forEach(screen => {
        map[screen.screenPermission] = {
          product: product.productName,
          module: module.moduleName,
          screen: screen.screenName
        };
      });
    });
  });
  return map;
}
