/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

export const BLACK = '#000000';
export const WHITE = '#FFFFFF';

export const OMIKROM = '#1DE81D';
export const OMIKROM_LIGHTER = '#94e89f';
export const ZETA = '#3CDBC0';
export const ZETA_LIGHTER = '#46ffe1';
export const ZETA_DARKER = '#299986';
export const DELTA = '#05C3DE';
export const SIGMA = '#BB29BB';
export const ALPHA = '#D60054';
export const ALPHA_DARKER = '#950040';
export const ALPHA_LIGHTER = '#ff4974';
export const KAPPA = '#FF5F00';
export const BETA = '#F7EA48';
export const GAMMA = '#0092FE';

export const OMEGA = '#212121';
export const THETA = '#D9D9D9';
export const TUNDORA = '#4A4949';
export const BOULDER = '#777777';
export const MINE_SHAFT = '#313131';

export const TURQUOISE = '#50E3C2';
export const POLOROUS = '#3FB2C2';
export const MATISSE = '#246AAD';
export const PERSIAN_BLUE = '#1A3FA6';
export const SHERPA_BLUE = '#01434D';

export const JUNGLE_GREEN = '#30B19A';

export const CERISE_RED = '#DE3276';
export const FUCHSIA_PINK = '#C853C8';

export const MINE_SHAFT_LIGHT = '#3B3B3B';
export const GALLERY = '#EEEEEE';
export const SILVER = '#C3C3C3';

export const TOMATO = '#FF6347';
export const PALE_TOMATO = '#de6962';

// dataviz

const blueToYellow = ['#246AAD', '#00adcf', '#3CDBC0', '#7dd54f', '#ffd000'];

const blueToYellowDarkened = ['#13426d', '#005869', '#176f5d', '#2e651d', '#ad8300'];

const blueToYellowExtended = [
  'rgb(36, 106, 173)',
  'rgb(32, 129, 185)',
  'rgb(36, 150, 188)',
  'rgb(46, 169, 183)',
  'rgb(62, 185, 173)',
  'rgb(81, 199, 157)',
  'rgb(104, 210, 138)',
  'rgb(128, 218, 116)',
  'rgb(153, 224, 92)',
  'rgb(178, 227, 69)',
  'rgb(201, 228, 48)',
  'rgb(221, 227, 28)',
  'rgb(238, 223, 13)',
  'rgb(249, 216, 3)',
  'rgb(255, 208, 0)'
];

export const CHART_COLOURS = [GAMMA, ALPHA, BETA, SIGMA, KAPPA];
export const MARGIN_BLUE_HUES = blueToYellow;
export const MARGIN_BLUE_HUES_DARK = blueToYellowDarkened;
export const MARGIN_BLUE_HUES_EXTENDED = blueToYellowExtended;

export const MARGIN_BLUE_DIVERGENT = blueToYellow;
