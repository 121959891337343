<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div
  class="container container--{{ theme }}"
  [ngClass]="displayScreenMenu ? 'sub-header-height' : 'sub-header-height--sm'"
>
  @if (displayScreenMenu) {
  <div
    class="container__content container__content--{{ pageLayout$ | async }}"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div>
      <ng-content></ng-content>
    </div>
    <div fxLayout="row" fxLayoutGap="12px">
      @if (displayDatepicker) {
      <ng-template [cdkPortalOutlet]="datepickerPortal$ | async"></ng-template>
      } @if (displayFileViewerButton && !(areFilesLoading$ | async)) {
      <og-file-viewer-button (click)="onFileViewerSidePanelToggle()" type="menu">
        <og-icon name="folder-open" class="file-viewer__toggle-button-icon"></og-icon>
      </og-file-viewer-button>
      }
    </div>
  </div>
  }
</div>
<og-file-side-panel></og-file-side-panel>
