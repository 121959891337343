/*
 * Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { Icon, IconSize } from '@opengamma/ui';

@Component({
  selector: 'og-icon-cell',
  templateUrl: './icon-cell.component.html',
  styleUrls: ['./icon-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class IconCellComponent {
  @Input() icon: Icon;
  IconSize = IconSize;
}
