<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div class="pickers">
  @if (isMinAdjustable) {
  <div
    class="pickers__slider pickers__slider--{{ theme }}"
    (mousedown)="startDraggingMin($event)"
    [style.left]="minOffsetPx"
  >
    <div class="slider__value">
      {{ selectedMin | number: '1.0-0' }}
    </div>
  </div>
  }
  <div
    class="pickers__slider pickers__slider--{{ theme }}"
    (mousedown)="startDraggingMax($event)"
    [style.left]="maxOffsetPx"
  >
    <div class="slider__value">
      {{ selectedMax | number: '1.0-0' }}
    </div>
  </div>
</div>
<div class="background-bar" #bar></div>
<div
  class="selected-bar selected-bar--{{ theme }}"
  [style.left]="minOffsetPx"
  [style.width]="barWidthPx"
></div>
<div class="edge-values">
  <div class="edge-values__min">{{ min }}</div>
  <div class="edge-values__max">{{ max }}</div>
</div>
