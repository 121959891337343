/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Location, PopStateEvent } from '@angular/common';
import { Component, OnDestroy } from '@angular/core';
import { SubscriptionLike } from 'rxjs';

@Component({
  selector: 'og-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss']
})
export class PageNotFoundComponent implements OnDestroy {
  private subscription: SubscriptionLike;

  constructor(private location: Location) {
    // When you're already on a 404 page and navigate to another page
    // that does not exist, you'll be within the same PageNotFoundComponent.
    // Therefore a subscription is needed to capture all URL changes.
    this.subscription = location.subscribe(value => this.logIf404(value));
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

  private logIf404(event: PopStateEvent): void {
    if (event.url === '/404') {
      // Log all 404s to the console as errors. We are notified
      // of all console errors that occur during automated UI tests.
      console.error('Could not find page, showing 404');
    }
  }

  navigateBack() {
    this.location.back();
  }
}
