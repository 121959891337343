/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { WHITE } from 'app/shared/utils/colors';
import * as Highcharts from 'highcharts/highstock';

export const defaultPieOptions: Highcharts.Options = {
  chart: {
    type: 'pie',
    plotBackgroundColor: undefined,
    plotBorderWidth: undefined,
    plotShadow: false
  },
  plotOptions: {
    pie: {
      innerSize: '60%',
      borderWidth: 1,
      borderColor: 'rgba(0, 0, 0, 0.2)',
      cursor: 'pointer',
      dataLabels: {
        enabled: true,
        style: {
          color: WHITE,
          textOverflow: 'none'
        },
        distance: 20
      },
      showInLegend: true
    }
  },
  tooltip: {
    shared: false,
    split: false,
    formatter: function() {
      return `<div>${Math.trunc(
        this.point.percentage
      )}%</div><br><div style="font-size: 9px; opacity: 0.5; width: 20px">${this.point.name}</div>`;
    }
  }
};
