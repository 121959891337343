/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'og-simple-labeled-entity',
  templateUrl: './simple-labeled-entity.component.html'
})
export class SimpleLabeledEntityComponent {
  /** The label to display */
  @Input() label: string;
  /** The text to display */
  @Input() text: string;
  /** The subtext to display below the metric */
  @Input() subtext: string;
  /** The size of the component */
  @Input() size: 'small' | 'large' = 'large';
  /** Style for the component */
  @Input() theme: 'dark' | 'light' = 'light';
}
