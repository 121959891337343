/*
 * Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { IconSize } from '@opengamma/ui';
import { Timeline } from 'app/shared/components/metrics/timeline/timeline.model';

@Component({
  selector: 'og-timeline',
  templateUrl: './timeline.component.html',
  styleUrls: ['./timeline.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimelineComponent {
  isCompact = true;
  @Input() timeline: Timeline;
  IconSize = IconSize;
}
