/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges } from '@angular/core';
import * as _ from 'lodash';
import { ALPHA, DELTA, GAMMA, OMEGA, OMIKROM, MINE_SHAFT } from 'app/shared/utils/colors';
import { Currency } from 'app/shared/models';
import { CurrencyValuePipe } from '@opengamma/ui';

/** An item for the sunburst chart. */
export interface SunburstTree {
  /** The name of the node. */
  name: string;
  /** The children of the node. */
  children: (SunburstTree | SunburstLeaf)[];
}

/** A leaf on the sunburst chart. */
export interface SunburstLeaf {
  /** The name of the leaf. */
  name: string;
  /** The value of the leaf. */
  value: number;
}

export type SunburstNode = SunburstTree | SunburstLeaf;

// -------------------------------------------------------------------------
// TODO: Replace with https://www.highcharts.com/demo/pie-donut
@Component({
  selector: 'og-sunburst',
  templateUrl: './sunburst.component.html',
  styleUrls: ['./sunburst.component.scss']
})
export class SunburstComponent implements OnChanges {
  /** The data to display. */
  @Input() data: SunburstNode[];

  /** The colors; affectedDataKeys the root level only. */
  @Input() colors: string[] = [GAMMA, DELTA, OMIKROM, OMEGA, ALPHA];

  /** The currency to format values in, optional. */
  @Input() currencyFormat?: Currency;

  // -------------------------------------------------------------------------

  options?: object; // highcharts object

  constructor(private currencyPipe: CurrencyValuePipe) {}

  ngOnChanges() {
    const parent = {
      name: 'Total',
      children: this.data
    };
    const data = this.composeHighchartsItems(parent, 0, '').sort((a, b) => b.value - a.value);
    this.options = {
      chart: {
        type: 'sunburst'
      },
      plotOptions: {
        sunburst: {
          cursor: 'pointer',
          allowDrillToNode: false,
          allowPointSelect: false,
          dataLabels: {
            formatter: function() {
              const shapeArgs = this.point.node.shapeArgs;
              const innerArcFraction = (shapeArgs.end - shapeArgs.start) / (2 * Math.PI);

              if (innerArcFraction > 0.03) {
                return this.point.name;
              }
            },
            rotationMode: 'perpendicular'
          },
          levels: [
            {
              level: 1,
              levelIsConstant: false,
              dataLabels: {
                rotationMode: 'parallel'
              }
            },
            {
              level: 2,
              colorVariation: {
                key: 'brightness',
                to: 0.5
              }
            },
            {
              level: 3,
              colorVariation: {
                key: 'brightness',
                to: -0.5
              }
            },
            {
              level: 4,
              colorVariation: {
                key: 'brightness',
                to: 0.5
              }
            },
            {
              level: 5,
              colorVariation: {
                key: 'brightness',
                to: -0.5
              }
            }
          ]
        }
      },
      series: [
        {
          data: data,
          drillUpButton: {
            text: undefined
          }
        }
      ],
      tooltip: {
        pointFormat: `<b>{point.name}:</b> {point.prettyValue}`
      }
    };
  }

  // -------------------------------------------------------------------------
  // formats the number with the given formatter (or returns the value by default)
  private prettyValue(value: number) {
    if (this.currencyFormat) {
      const currencyValue = {
        ...this.currencyFormat,
        price: value
      };
      return this.currencyPipe.transform(currencyValue);
    }
    return value;
  }

  // returns true if the given item is a leaf
  private isLeaf(item: SunburstTree | SunburstLeaf) {
    return (<SunburstLeaf>item).value !== undefined;
  }

  // returns a list of highcharts items from a tree
  private composeHighchartsItems(node: SunburstNode, index: number, parentId: string): any[] {
    const id = `${parentId}.${index}`;
    if (this.isLeaf(node)) {
      return [
        {
          id,
          parent: parentId,
          value: Math.abs((<SunburstLeaf>node).value),
          prettyValue: this.prettyValue((<SunburstLeaf>node).value),
          color: this.getColor(parentId, index),
          name: node.name
        }
      ];
    }
    const children = _.flatMap((<SunburstTree>node).children, (child, childIndex) =>
      this.composeHighchartsItems(<SunburstTree>child, childIndex, id)
    );
    const childTotal = children.reduce((prev, curr) => prev + curr.value, 0);
    return [
      {
        id,
        parent: parentId,
        name: node.name,
        prettyValue: this.prettyValue(childTotal),
        color: this.getColor(parentId, index)
      },
      ...children
    ];
  }

  // returns the color for the given node
  private getColor(parentId: string, index: number): string {
    if (parentId === '') {
      return MINE_SHAFT;
    }
    if (parentId === '.0') {
      return this.colors[index % this.colors.length];
    }
    return undefined;
  }
}
