/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'og-short-number-value-cell',
  templateUrl: './short-number-value-cell.component.html'
})
export class ShortNumberValueCellComponent {
  @Input() value: number;
}
