/*
 * Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { Injectable } from '@angular/core';

export enum THEME {
  DARK_BLUE = 'dark-blue'
}

/**
 * Responsible for attaching/detaching theme classes to the global html element
 */
@Injectable({ providedIn: 'root' })
export class ThemeService {
  setTheme(theme: THEME): void {
    document.getElementsByTagName('html')[0].classList.add('og-theme--' + theme);
  }
  clearTheme(): void {
    const appliedThemeClass = Array.from(
      document.getElementsByTagName('html')[0].classList
    ).find(cssClass => cssClass.includes('og-theme'));
    if (appliedThemeClass !== undefined) {
      document.getElementsByTagName('html')[0].classList.remove(appliedThemeClass);
    }
  }
}
