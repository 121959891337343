<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div class="height--100" fxLayout="row" fxLayoutAlign="space-between center">
  <nav class="nav" fxLayoutAlign="left center">
    <div class="nav__start"></div>
    @for (module of modules | filterModulesByPosition: 'left'; track module) {
    <ng-container *ngTemplateOutlet="moduleItem; context: { $implicit: module }"></ng-container>
    }
    <div class="nav__end"></div>
  </nav>
  <nav class="nav" fxLayoutAlign="left center">
    @for (module of modules | filterModulesByPosition: 'right'; track module) {
    <ng-container *ngTemplateOutlet="moduleItem; context: { $implicit: module }"></ng-container>
    }
  </nav>
</div>

<ng-template #moduleItem let-module>
  <a
    id="og-module-link--{{ module.urlPath }}"
    class="nav__item nav__item--{{ theme }}"
    [routerLink]="getModuleRoute(module.urlPath)"
    [routerLinkActive]="'nav__item--active'"
    [class.nav__item__about]="module.moduleName === 'About'"
    [class.nav__item--locked]="module.isLocked"
    [class.nav__item--with-divider]="module.displayOptions?.hasDivider"
  >
    @if (module.displayOptions?.icon) {
    <og-icon [name]="module.displayOptions.icon" class="item__icon"></og-icon>
    }
    {{ module.moduleName }}
    @if (module.isLocked) {
    <og-icon class="item__lock" name="lock" [size]="IconSize.XLarge"></og-icon>
    }
  </a>
</ng-template>
