/*
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import {
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
  HttpEvent,
  HttpResponse,
  HttpEventType
} from '@angular/common/http';
import { Observable } from 'rxjs';
import { observableOf } from 'app/shared/rxjs/rxjs.utils';
import { tap } from 'rxjs/operators';
import { isAnalyticsRequest } from 'app/core/http.utils';
import { HttpCacheManager } from '@ngneat/cashew';

// The cache expiry time, in milliseconds.
const CACHE_MAX_AGE = 60 * 60 * 1000; // 1h

/** Interceptor for caching data. */
@Injectable()
export class CacheInterceptor implements HttpInterceptor {
  constructor(private cacheService: HttpCacheManager) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isAnalyticsRequest(req.url)) {
      return next.handle(req);
    }

    const headers = req.headers;

    headers.set('Cache-Control', 'no-cache, no-store, no-transform').set('Pragma', 'no-cache');
    const shouldCache = req.headers.get('og-cache');

    if (req.method === 'GET' && shouldCache !== 'false') {
      const cacheKey = req.urlWithParams;
      if (this.cacheService.validate(cacheKey)) {
        return observableOf(new HttpResponse<any>(this.cacheService.get(cacheKey)));
      }

      return next.handle(req.clone({ headers: headers })).pipe(
        tap(response => {
          if (response.type === HttpEventType.Response) {
            this.cacheService.set(cacheKey, response, {
              ttl: CACHE_MAX_AGE
            });
          }
        })
      );
    }

    return next.handle(req.clone({ headers: headers }));
  }
}
