/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  Output,
  EventEmitter,
  ViewChild
} from '@angular/core';
import { TimeSeries } from 'app/shared/models';
import { Color, Options } from 'highcharts';
import { ALPHA, BOULDER, GAMMA, TUNDORA } from 'app/shared/utils/colors';
import * as _ from 'lodash';
import { DateTime } from 'luxon';

import { DateRange } from '@opengamma/ui';
import { FormatterMetaData } from 'app/shared/utils/highcharts-tooltip-formatters';
import { HighchartComponent } from 'app/shared/components/charts/legacy/highchart/highchart.component';

// -------------------------------------------------------------------------
/** A time series with an optional name. */
export interface NamedTimeSeries {
  /** The name of the time series, optional. */
  name?: string;
  /** The human-friendly name of the time series, optional. */
  displayName?: string;
  /** The time series. */
  timeSeries: TimeSeries;
}

/** A line on the time series graph. */
export interface TimeSeriesPlotLine {
  /** The color of the line. */
  color: string;
  /** The value at which the line should appear. */
  date: string;
  /** The text on the line. */
  text?: string;
}

/** A line on the y axis of a time series graph. */
export interface TimeSeriesPlotLineYaxis {
  /** The color of the line. */
  color: string;
  /** The value at which the line should appear. */
  value: number;
  /** The text on the line. */
  text?: string;
}

/** A region on the time series graph. */
export interface TimeSeriesRegion {
  /** The color of the region. */
  color: string;
  /** The start date. */
  startDate: string;
  /** The end date. */
  endDate: string;
  /** The opacity of the region, defaulted to 1. */
  opacity: number;
}

/** A highcharts style of line. */
export type LineStyle =
  | 'solid'
  | 'shortDash'
  | 'shortDot'
  | 'shortDashDot'
  | 'shortDashDotDot'
  | 'dot'
  | 'dash'
  | 'longDash'
  | 'dashDot'
  | 'longDashDot'
  | 'longDashDotDot';

/** X-axis style zone. */
export interface TimeSeriesStyleZone {
  /** The date from which this style should apply, empty for remaining dates. */
  date?: string;
  /** The color of the zone, empty for default. */
  color?: string;
  /** The line style of the zone, empty for default. */
  lineStyle?: LineStyle;
}
/** Y-axis style zone */
export interface TimeSeriesStyleZoneY {
  /** The starting value for which this z. */
  value?: number;
  /** The color of the zone, empty for default. */
  color: string;
  /** The line style of the zone, empty for default. */
  lineStyle?: LineStyle;
}

// -------------------------------------------------------------------------
// all possible format types, to ensure parity between available types and formatters
type Format = 'percent' | 'number';

// -------------------------------------------------------------------------
/**
 * Represents a time series line chart.
 *
 * Can be used to display a single time series as well as multiple
 * time series'. Both the primary inputs will accept arrays as well
 * as single values.
 */
@Component({
  selector: 'og-timeseries-chart',
  styleUrls: ['./timeseries-chart.component.scss'],
  templateUrl: './timeseries-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeSeriesChartComponent implements OnChanges {
  // Extract and expose the highcharts API object through this reference to the base component
  @ViewChild('chart') chartRef: HighchartComponent;

  /** Start date for selector */
  @Input() startDate: DateTime;

  /** End date for selector */
  @Input() endDate: DateTime;

  /** File name for exports */
  @Input() fileName: string;

  /** The time series data. */
  @Input() data: NamedTimeSeries | NamedTimeSeries[];

  // -------------------------------------------------------------------------
  /** The title of the chart, optional. */
  @Input() chartTitle?: string;

  /** The colours to be used for each respective series. */
  @Input() colors: string[] = [];

  /** If set, numbers below the threshold appear red. */
  @Input() threshold?: number;

  /**
   * The format of the input data, used for displaying the Y axis.
   *
   * - 'number': Will display values in the default format, shortening numbers.
   *             eg, -541452 becomes '-541k'
   * - 'percent': Will multiply numbers by 100, and then display as with 'number'
   *              followed by a percentage (%) sign.
   *              eg, -0.001 becomes '-0.1%'
   *
   * The formatter used is defined in {@link FORMATTERS}.
   */
  @Input() format: Format = 'number';

  /** True if hover should be enabled. */
  @Input() enableTooltip = false;

  /** True if graph zooming should be enabled. */
  @Input() enableZoom = false;

  /** Formats the value in the tooltip, defaulted to identity. */
  @Input() tooltipFormatter: (val: number, metaData?: object) => string;

  /** A list of lines to display on the time series, optional. */
  @Input() plotLines?: TimeSeriesPlotLine[];

  /** A list of y-axis lines to be drawn */
  @Input() plotYLines?: TimeSeriesPlotLineYaxis[];

  /** A list of regions to highlight on the time series, optional. */
  @Input() plotRegions?: TimeSeriesRegion[];

  /** The style zones to apply, optional. */
  @Input() styleZones?: TimeSeriesStyleZone[];

  /** Style zones for Y axis */
  @Input() styleZonesY?: TimeSeriesStyleZoneY[];

  /** Whether the legend should be put on the right of the chart */
  @Input() alignLegendRight = false;

  /** Whether the legend should shown */
  @Input() showLegend = true;

  /** Whether the navigator should shown */
  @Input() showNavigator = false;

  /** Whether the chart is an area chart */
  @Input() isArea = false;

  /** Whether the chart is a scatter chart */
  @Input() isScatter = false;

  /** Determines whether the dates/times include local offset or just UTC date/time */
  @Input() isLocallyOffset = false;

  /** Is the x-axis visible */
  @Input() xAxisVisible = true;

  /** Is the y-axis visible */
  @Input() yAxisVisible = true;

  /** Is the x-axis visible */
  @Input() xAxisWidth = 0;

  /** Should the labels on x-axis be visible */
  @Input() xAxisLabelsVisible = true;

  /** Should the labels on y-axis be visible */
  @Input() yAxisLabelsVisible = true;

  /** Enable scrollbar */
  @Input() enableScrollbar = true;

  /** Enable exporting to csv */
  @Input() enableExporting = false;

  @Input() extraOptions: Options = {};

  @Input() formatterMetaData: FormatterMetaData;

  @Input() tickPixelInterval = 100;

  /** Output the date range selected via dragging on the chart */
  @Output() rangeChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();

  /** Emits the date range that the navigator selects. */
  @Output() navigatorChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();

  /** Emits the date range when the navigator is dragged. */
  @Output() navigatorDrag: EventEmitter<DateRange> = new EventEmitter<DateRange>();

  // -------------------------------------------------------------------------
  options?: Options; // highcharts object

  ngOnChanges() {
    if (!this.data) {
      this.options = undefined;
      return;
    }

    const startTime = this.startDate && this.startDate.toMillis();

    const endTime = this.endDate && this.endDate.toMillis();

    const currentDate = DateTime.local().toFormat('yyyyMMdd');

    const normalizedData = ((this.data as NamedTimeSeries[]).length !== undefined
      ? this.data
      : [this.data]) as NamedTimeSeries[];

    const showThreshold = normalizedData.some(series =>
      series.timeSeries.some(({ value }) => value >= this.threshold)
    );

    const seriesData = normalizedData.map((datum, index) => {
      return {
        type: this.isArea ? 'area' : this.isScatter ? 'scatter' : 'line',
        animation: false,
        name: datum.displayName ? datum.displayName : datum.name,
        negativeColor: showThreshold ? this.colors[index % this.colors.length] : undefined,
        color: showThreshold ? ALPHA : this.colors[index % this.colors.length],
        fillOpacity: 0.2,
        zones: this.styleZonesY,
        data: datum.timeSeries.map(({ date, value }) => [
          DateTime.fromISO(date, { zone: 'utc' }).toMillis(),
          value
        ])
      };
    });

    const showLegend = this.showLegend && seriesData.some(data => !!data.name);

    const tooltipFormatterCallback = this.tooltipFormatter;
    const formatterMetaDataRef = this.formatterMetaData;
    const tooltipOptions = tooltipFormatterCallback
      ? {
          pointFormatter: function() {
            return tooltipFormatterCallback(this, formatterMetaDataRef);
          }
        }
      : { pointFormat: '<b>{series.name}: </b>{point.y:,.1f}<br>' };

    this.options = _.merge(
      {
        exporting: {
          enabled: this.enableExporting,
          filename: ['OpenGamma', this.fileName, currentDate].join('_'),
          csv: {
            dateFormat: '%Y-%m-%d'
          },
          buttons: {
            contextButton: {
              menuItems: ['downloadCSV', 'downloadXLS']
            }
          }
        },
        time: {
          timezoneOffset: this.isLocallyOffset ? -DateTime.local().offset : undefined
        },
        chart: {
          time: {
            timezoneOffset: this.isLocallyOffset ? -DateTime.local().offset : undefined
          },
          events: {
            selection: event => {
              if (event.xAxis) {
                this.rangeChange.emit({
                  start: DateTime.fromMillis(event.xAxis[0].min),
                  end: DateTime.fromMillis(event.xAxis[0].max)
                });
                return this.enableZoom;
              }
            }
          },
          zoomType: this.rangeChange ? 'x' : undefined
        },
        navigator: {
          maskFill: new Color(GAMMA).setOpacity(0.15).get(),
          enabled: this.showNavigator,
          yAxis: {
            visible: false,
            labels: false
          },
          xAxis: {
            visible: false,
            labels: false,
            scrollbar: {
              enabled: false
            }
          },
          height: 40,
          handles: {
            borderColor: new Color(GAMMA).get(),
            backgroundColor: new Color(GAMMA).get(),
            width: 2,
            height: 40
          },
          series: {
            fillOpacity: 0.05,
            lineWidth: 1,
            lineColor: new Color(GAMMA).setOpacity(0.6).get(),
            fillColor: new Color(GAMMA).setOpacity(0.05).get()
          },
          outlineColor: TUNDORA
        },
        legend: {
          enabled: showLegend,
          navigation: {
            enabled: false
          },
          layout: this.alignLegendRight ? 'vertical' : 'horizontal',
          verticalAlign: this.alignLegendRight ? 'middle' : 'bottom',
          align: this.alignLegendRight ? 'right' : 'center',
          lineHeight: 12,
          margin: 12,
          padding: 8,
          symbolRadius: 0
        },
        title: {
          text: this.chartTitle,
          style: {
            color: 'rgba(216, 216, 216, .7)',
            fontSize: '12px'
          }
        },
        tooltip: {
          crosshairs: true,
          padding: 6,
          enabled: this.enableTooltip,
          split: true,
          ...tooltipOptions
        },
        xAxis: {
          visible: true,
          min: startTime,
          max: endTime,
          type: 'datetime',
          events: {
            setExtremes: event => {
              const dateRange = {
                start: DateTime.fromMillis(event.min),
                end: DateTime.fromMillis(event.max)
              };
              this.navigatorDrag.emit(dateRange);
              // navigator drag on the drag area - Dom event exists
              if (event && event.DOMEvent && event.DOMEvent.type === 'mouseup') {
                this.navigatorChange.emit(dateRange);
              } else if (event.triggerOp !== 'navigator-drag') {
                // navigator click outside the drag area - Dom event doesn't exist (for some reason)
                this.navigatorChange.emit(dateRange);
              }
            }
          },
          showLastLabel: true,
          showFirstLabel: true,
          gridLineWidth: 1,
          gridLineDashStyle: 'ShortDash',
          gridLineColor: 'rgb(216, 216, 216, .05)',
          gridZIndex: 5,
          labels: {
            enabled: this.xAxisLabelsVisible,
            style: {
              color: 'rgba(216, 216, 216, .3)'
            }
          },
          legend: {
            enabled: showLegend,
            navigation: {
              enabled: false
            },
            layout: this.alignLegendRight ? 'vertical' : 'horizontal',
            verticalAlign: this.alignLegendRight ? 'middle' : 'bottom',
            align: this.alignLegendRight ? 'right' : 'center',
            lineHeight: 12,
            margin: 12,
            padding: 8,
            symbolRadius: 0
          },
          plotLines: this.plotLines
            ? this.plotLines.map(line => ({
                color: line.color,
                value: Date.parse(line.date),
                width: 1,
                dashStyle: 'dash',
                label: line.text
                  ? {
                      text: line.text,
                      style: {
                        color: line.color,
                        fontWeight: 'bold'
                      }
                    }
                  : undefined
              }))
            : undefined,
          plotBands: !_.isEmpty(this.plotRegions)
            ? this.plotRegions.map(region => ({
                color: new Color(region.color)
                  .setOpacity(region.opacity !== undefined ? region.opacity : 1)
                  .get(),
                from: Date.parse(region.startDate),
                to: Date.parse(region.endDate)
              }))
            : undefined,
          tickPixelInterval: this.tickPixelInterval
        },
        yAxis: {
          visible: this.yAxisVisible,
          title: {
            text: this.chartTitle,
            style: {
              color: 'rgba(216, 216, 216, .7)',
              fontSize: '12px'
            }
          },
          labels: {
            style: {
              color: 'rgba(216, 216, 216, .3)'
            },
            align: 'center',
            x: -8
          },
          crosshair: {
            color: BOULDER
          },
          opposite: false,
          plotLines: [
            {
              color: 'rgba(216, 216, 216, .1)',
              width: 1,
              value: 0,
              label: {
                align: 'right'
              }
            },
            ...(this.plotYLines
              ? this.plotYLines.map(({ color, text, value }) => ({
                  color,
                  value,
                  width: 1,
                  label: text && {
                    text
                  },
                  dashStyle: 'LongDash',
                  zIndex: 5
                }))
              : [])
          ]
        },
        lineColor: BOULDER,
        lineWidth: 1,
        plotOptions: {
          line: {
            lineWidth: 2,
            states: {
              hover: {
                lineWidth: 2
              }
            },
            marker: this.isScatter
              ? {
                  symbol: 'diamond'
                }
              : false
          },
          enableMouseTracking: this.enableTooltip,
          threshold: this.threshold,
          zoneAxis: 'x',
          zones: _.isEmpty(this.styleZones)
            ? undefined
            : this.styleZones.map(({ date, color, lineStyle }) => ({
                color,
                value: date ? Date.parse(date) : undefined,
                dashStyle: lineStyle
              }))
        },
        series: seriesData,
        rangeSelector: {
          enabled: false
        },
        scrollbar: {
          enabled: this.enableScrollbar,
          height: 0,
          buttonArrowColor: 'transparent'
        }
      },
      this.extraOptions
    );
  }
}
