/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@opengamma/ui';

/**
 * A service which uses local-storage to indicate whether a user has interacted with newly developed features.
 */
@Injectable({ providedIn: 'root' })
export class NewFeatureFlagService {
  constructor(private localStorageService: LocalStorageService) {}

  isFeatureNew(featureTag: string): boolean {
    const recordedFeatures: string[] = this.localStorageService.getData('recorded-features') || [];
    return recordedFeatures.indexOf(featureTag) < 0;
  }

  markFeatureAsInteractedWith(featureTag: string): void {
    if (this.isFeatureNew(featureTag)) {
      const recordedFeatures: string[] =
        this.localStorageService.getData('recorded-features') || [];
      this.localStorageService.setData('recorded-features', [...recordedFeatures, featureTag]);
    }
  }
}
