<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div class="filter__container" *ngIf="searchableColumnIndexes.length > 0">
  <og-icon class="container__icon" name="search"></og-icon>
  <input
    type="text"
    class="container__filter"
    placeholder="Search"
    [(ngModel)]="filterTerm"
    (keyup)="updateFilter($event)"
  />
</div>

<div class="descriptions" *ngIf="formattedColumnDescriptions.length > 0">
  <div
    class="descriptions__entry"
    [class.descriptions__entry--no-border]="!description.label"
    *ngFor="let description of formattedColumnDescriptions"
    [ngStyle]="description.styles"
  >
    <span>{{ description.label }}</span>
  </div>
</div>

<div #table class="table table--{{ theme }}">
  <!--Group of columns-->
  <div
    class="table__group table__group--{{ i }}"
    [ngStyle]="getColumnGroupStyles(group)"
    [class.table__group--expandable]="isExpandable()"
    *ngFor="let group of formattedData; let i = index"
  >
    <div
      class="group__name group__name--{{ theme }}"
      [ngStyle]="getCustomColumnHeaderStyles(group.groupLabelOptions)"
      [class.group__name--sticky]="stickyHeader"
      *ngIf="group.label"
    >
      {{ group.label }}
    </div>

    <div class="group__columns">
      <!--Column in group-->
      <div
        class="column column--{{ theme }}"
        *ngFor="let column of group.columns; let columnIndex = index"
        [class.column--no-right-border]="
          columnIndex === group.columns.length - 1 && i === formattedData.length - 1
        "
        [ngStyle]="getColumnGroupWidth(column.headerOptions)"
      >
        <div
          class="column__header column__header--{{ theme }}"
          [class.column__header--more-padding]="areAllGroupsUnlabelled()"
          [class.column__header--sortable]="column.isSortable"
          [class.column__header--sticky]="stickyHeader"
          [class.column__header--sticky-grouped]="stickyHeader && group.label"
          [ngStyle]="getCustomColumnHeaderStyles(column.headerOptions)"
          (click)="column.isSortable && sortColumn(column)"
        >
          <div class="header__value header__value--{{ customHeaderHeight }}">
            <div class="value__contents">
              <span>{{ column.label }}</span>
              <ng-container *ngIf="column.isSortable">
                <og-icon
                  *ngIf="columnToSort?.index === column.index"
                  class="header__icon header__icon--{{ columnSortingOrder }}"
                  [name]="columnSortingOrder === 'asc' ? 'chevron-up' : 'chevron-down'"
                ></og-icon>
                <og-icon
                  class="header__icon"
                  *ngIf="columnToSort?.index !== column.index"
                  name="selector"
                ></og-icon>
              </ng-container>
            </div>
            <div *ngIf="column.icon" class="header__icon header__icon--pull-right">
              <ng-container
                *ngIf="column.icon && column.icon.tooltip !== undefined; else tooltiplessIcon"
              >
                <og-icon
                  [name]="column.icon.name"
                  [class.header__icon--clickable]="column.icon.onClick"
                  [matTooltip]="column.icon.tooltip"
                  (click)="column.icon.onClick ? column.icon.onClick(column.id) : undefined"
                ></og-icon>
              </ng-container>
              <ng-template #tooltiplessIcon>
                <og-icon
                  [name]="column.icon.name"
                  [class.header__icon--clickable]="column.icon.onClick"
                  (click)="column.icon.onClick ? column.icon.onClick(column.id) : undefined"
                ></og-icon>
              </ng-template>
            </div>
          </div>
        </div>

        <ng-container *ngFor="let row of filterRows(column)">
          <og-magic-table-cell
            [column]="column"
            [editingColumn]="editingColumn"
            [row]="row"
            [editingRow]="editingRow"
            [expandedRowIndexes]="expandedIndexes"
            [displayedChildCount]="displayedChildCount"
            [theme]="theme"
            [class.cell--sticky]="stickyFirstRow && row.index === 1"
            (cellClicked)="onCellClick($event)"
            (valueEditedChange)="onValueEdited($event)"
            (displayMoreChildren)="onDisplayMoreChildren($event)"
          ></og-magic-table-cell>
        </ng-container>
      </div>
    </div>
  </div>
</div>
