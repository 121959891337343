/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { BOULDER } from 'app/shared/utils/colors';
import * as Highcharts from 'highcharts/highstock';

export const defaultTimeseriesOptions: Highcharts.Options = {
  yAxis: {
    visible: true,
    labels: {
      style: {
        color: 'rgba(216, 216, 216, .3)'
      },
      align: 'center',
      x: -8
    },
    crosshair: {
      color: BOULDER
    },
    opposite: false
  },
  rangeSelector: {
    enabled: false
  },
  xAxis: {
    type: 'datetime',
    tickPixelInterval: 150,
    dateTimeLabelFormats: {
      day: '%e %b',
      week: '%e %b',
      month: '%e %b',
      year: '%e %b, %Y'
    }
  }
};
