/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { LoadableState, defaultLoadableState, UserAuthModels, UserDetails } from '@opengamma/ui';
import { ProductDefinition } from 'app/user-auth/models';

/** Permissions Metadata */
export interface PermissionsMetadata {
  legacyPermissions: UserAuthModels.Permissions;
  /**
   * The hierarchy of products/modules/screens that drives headers and routes.
   */
  productDefinition: ProductDefinition;
  /**
   * Whether the user has the role that allows them to see screens marked as internal
   */
  canUserViewInternalScreens: boolean;
}

/** User state. */
export interface UserAuthState {
  /** The user details. */
  user: LoadableState<UserDetails>;

  /** The user permissions. */
  permissions: LoadableState<PermissionsMetadata>;

  /**
   * Utility map recording the last screen visited in each module.
   * When a user switches modules, they should be taken back to the last visited screen.
   */
  moduleToScreenMap: { [moduleUrl: string]: string };
  /**
   * Indicates whether the currently logged in tenant is a demo env or not.
   */
  isDemoTenant: LoadableState<boolean>;
  /**
   * Stores information about JSCC terms and condition consent
   */
  isJSCCTCsAccepted: LoadableState<boolean>;
}

/** Initial user state. */
export const INITIAL_STATE: UserAuthState = {
  user: defaultLoadableState(),
  permissions: defaultLoadableState(),
  moduleToScreenMap: {},
  isDemoTenant: defaultLoadableState(),
  isJSCCTCsAccepted: defaultLoadableState()
};

// -------------------------------------------------------------------------
export function initializer(state = INITIAL_STATE): UserAuthState {
  return state;
}
