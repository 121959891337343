/*
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  EventEmitter,
  Output,
  SimpleChanges
} from '@angular/core';
import { NamedTimeSeries } from 'app/shared/components/charts/legacy/timeseries-chart/timeseries-chart.component';
import { Color } from 'highcharts';
import { GAMMA } from 'app/shared/utils/colors';
import { DateTime } from 'luxon';

import { DateRange } from '@opengamma/ui';

// -------------------------------------------------------------------------
/**
 * Represents a navigator for a time-series chart.
 *
 * Used to display a single timeseries-based line-chart and to select a time interval guided by said chart
 */
@Component({
  selector: 'og-navigator-chart',
  templateUrl: './navigator-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NavigatorChartComponent implements OnChanges {
  /** Start date for selector */
  @Input() startDate: DateTime;

  /** End date for selector */
  @Input() endDate: DateTime;

  /** The time series data. */
  @Input() data: NamedTimeSeries | NamedTimeSeries[];

  /** Output. */
  @Output() rangeChange: EventEmitter<DateRange> = new EventEmitter<DateRange>();

  // -------------------------------------------------------------------------
  options?: object; // highcharts object

  ngOnChanges(changes: SimpleChanges) {
    if (!this.data) {
      this.options = undefined;
      return;
    }

    const { startDate, endDate } = changes;

    if (
      startDate &&
      startDate.currentValue === this.startDate &&
      endDate &&
      endDate.currentValue === this.endDate
    ) {
      return;
    }

    const startTime = this.startDate && this.startDate.toMillis();

    const endTime = this.endDate && this.endDate.toMillis();

    const normalizedData = ((this.data as NamedTimeSeries[]).length !== undefined
      ? this.data
      : [this.data]) as NamedTimeSeries[];

    const seriesData = normalizedData.map((datum, index) => {
      return {
        type: 'spline',
        name: datum.name,
        data: datum.timeSeries.map(({ date, value }) => [Date.parse(date), value])
      };
    });

    this.options = {
      chart: {
        height: 44,
        margin: [0, 1, 0, 2]
      },
      navigator: {
        maskFill: 'rgba(255, 255, 255, 0.05)',
        yAxis: {
          visible: false,
          labels: false
        },
        xAxis: {
          visible: false,
          labels: false,
          scrollbar: {
            enabled: false
          }
        },
        height: 30,
        handles: {
          borderColor: 'transparent',
          backgroundColor: 'rgba(255, 255, 255, 0.5)',
          width: 2,
          height: 30
        },
        series: {
          fillOpacity: 0.15,
          lineWidth: 2,
          lineColor: new Color(GAMMA).setOpacity(0.6).get(),
          fillColor: new Color(GAMMA).setOpacity(0.05).get()
        },
        outlineColor: 'transparent'
      },
      tooltip: {
        enabled: false
      },
      yAxis: {
        visible: false,
        labels: false
      },
      xAxis: {
        events: {
          setExtremes: e => {
            this.rangeChange.emit({
              start: DateTime.fromMillis(e.min),
              end: DateTime.fromMillis(e.max)
            });
          }
        },
        min: startTime,
        max: endTime,
        showLastLabel: true,
        showFirstLabel: true,
        labels: {
          enabled: false
        },
        tickColor: 'rgba(216, 216, 216, .2)',
        type: 'datetime',
        dateTimeLabelFormats: {
          millisecond: '%H:%M:%S.%L',
          second: '%H:%M:%S',
          minute: '%H:%M',
          hour: '%H:%M',
          day: '%e/%-m',
          week: '%e. %b',
          month: "%b '%y",
          year: '%Y'
        },
        scrollbar: {
          enabled: false
        }
      },
      series: seriesData,
      rangeSelector: {
        enabled: false
      },
      scrollbar: {
        enabled: true,
        height: 0,
        barBorderColor: 'rgba(0, 0, 0, 0)',
        buttonArrowColor: 'rgba(0, 0, 0, 0)'
      }
    };
  }
}
