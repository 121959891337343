import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import _ from 'lodash';
import pluralize from 'pluralize';

@Component({
  selector: 'og-timeseries-multi-select',
  templateUrl: './timeseries-multi-select.component.html',
  styleUrls: ['./timeseries-multi-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeseriesMultiSelectComponent {
  @Input() options: string[];
  @Input() selectedOptions: string[];
  @Input() colors: string[];
  @Input() enableSelectAllOption = true;
  @Input() enableSelectNoneOption = true;
  @Input() grouping = 'items';

  @Output() onSelectionChange = new EventEmitter<string[]>();

  getSelectedOptions(): string[] {
    return this.selectedOptions;
  }
  onChipSelectionChange(selection: string[]): void {
    this.onSelectionChange.emit(selection);
  }

  onRemoveOption(option: string): void {
    this.onSelectionChange.emit(
      this.selectedOptions.filter(selectedOption => selectedOption !== option)
    );
  }

  areAllOptionsSelected(): boolean {
    return this.getNumberOfSelectedOptions() === this.getMaximumNumberOfOptions();
  }

  areAllOptionsDeselected(): boolean {
    return this.getNumberOfSelectedOptions() === 0;
  }

  private getNumberOfSelectedOptions(): number {
    return this.selectedOptions?.length;
  }
  private getMaximumNumberOfOptions(): number {
    return this.options.length;
  }

  onDeselectAllOptions(): void {
    this.onSelectionChange.emit([]);
  }

  onSelectAllOptions(): void {
    this.onSelectionChange.emit(this.options);
  }

  isOptionSelected(option: string): boolean {
    return this.getSelectedOptions()?.includes(option);
  }

  pluralize(value: string): string {
    return pluralize(value === value.toUpperCase() ? value : _.lowerCase(value));
  }
}
