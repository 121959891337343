/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges } from '@angular/core';
import { ShortNumberPipe } from '@opengamma/ui';
import { ColumnChartParams } from './column-chart.models';

import { columnChartOptions } from './column.util';

@Component({
  selector: 'og-column-chart',
  templateUrl: './column-chart.component.html',
  styleUrls: ['column-chart.component.scss']
})
export class ColumnChartComponent implements OnChanges {
  /** The column chart params to use in the chart options */
  @Input() columnParams: ColumnChartParams;

  options: object;

  constructor(private shortNumberPipe: ShortNumberPipe) {}

  ngOnChanges() {
    if (!!this.columnParams && this.columnParams.series) {
      this.options = columnChartOptions(this.columnParams, this.shortNumberPipe);
    }
  }
}
