<!--
  ~ Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div class="selectable-metrics" fxLayout="row" fxFlex="grow">
  <ng-container *ngFor="let metric of metrics; let last = last">
    <div
      fxLayout="column"
      fxLayoutGap="6px"
      fxLayoutAlign="center start"
      class="selectable-metric"
      [class.selectable-metric--active]="metric.name === selectedMetric"
      (click)="onSelect(metric.name)"
    >
      <div class="selectable-metric__name">{{ metric.name }}</div>
      <div *ngIf="metric.value && metric.value.price" class="selectable-metric__value">
        {{ metric.value | boundedCurrencyValue: 1e6:'1.2-2' }}
      </div>
    </div>
    <div *ngIf="!last" class="selectable-metric__divider"></div>
  </ng-container>
</div>
