/*
 * Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { CardComponent } from 'app/shared/components/card/card.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatInputModule } from '@angular/material/input';
import { IconModule, OGFormsModule, OGMiscModule } from '@opengamma/ui';
import { OGTimeseriesCardHeaderModule } from 'app/shared/components/card/timeseries-card-header/timeseries-card-header.module';
import { SummaryCardMetricsComponent } from 'app/shared/components/card/summary-card/summary-card-metrics/summary-card-metrics.component';
import { SummaryCardComponent } from 'app/shared/components/card/summary-card/summary-card.component';
import { SummaryCardsScreenEngineComponent } from 'app/shared/components/card/summary-card/summary-cards-screen-engine/summary-cards-screen-engine.component';
import { OGMetricsModule } from 'app/shared/components/metrics/metrics.module';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    IconModule,
    MatTooltipModule,
    MatInputModule,
    OGFormsModule,
    OGMetricsModule,
    OGMiscModule
  ],
  declarations: [
    CardComponent,
    SummaryCardComponent,
    SummaryCardsScreenEngineComponent,
    SummaryCardMetricsComponent
  ],
  exports: [
    CardComponent,
    OGTimeseriesCardHeaderModule,
    SummaryCardComponent,
    SummaryCardsScreenEngineComponent,
    SummaryCardMetricsComponent
  ]
})
export class OGCardModule {}
