/*
 * Copyright (C) 2023 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

/* COPY OF THE UNSUBSCRIBER COMPONENT IN OG-UI. FOR SOME REASON UNKNOWN,
EXTENDING AN OG-UI COMPONENT WITHIN SWC DOES NOT WORK */

import { Component, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Component({ selector: 'og-unsubscriber', template: '' })
export class UnsubscriberComponent implements OnDestroy {
  private unsubscriber$$ = new Subject<void>();
  isDestroyed$ = this.unsubscriber$$.asObservable();

  //Unsubscribe all observers
  ngOnDestroy(): void {
    this.unsubscriber$$.next();
    this.unsubscriber$$.complete();
  }
}
