/*
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Observable } from 'rxjs';
import { PageLayout, PageWidthLayoutService } from '@opengamma/ui';

@Component({
  selector: 'og-layout-container',
  templateUrl: './layout-container.component.html',
  styleUrls: ['./layout-container.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LayoutContainerComponent {
  pageLayout$: Observable<PageLayout>;
  constructor(pageWidthLayoutService: PageWidthLayoutService) {
    this.pageLayout$ = pageWidthLayoutService.getPageLayout();
  }
}
