/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from './shared/store/reducers';
import { FromMarginScreensCommonSelectors } from '@opengamma/ui';
import * as fromUser from 'app/user-auth/store/user-auth.selectors';

@Component({
  selector: 'og-app',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  theme$ = this.store.select(FromMarginScreensCommonSelectors.getModuleTheme);
  areAllNeededApisCompleted$ = this.store.select(fromUser.getAreAllNeededApiCallsCompleted);

  constructor(private store: Store<State>) {}
}
