<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div class="card" [class.card--active]="isSelected" fxLayout="column" fxLayoutGap="24px">
  <div fxLayout="row" fxLayoutAlign="space-between center">
    <span class="card__badge">{{ title }}</span>
    <og-icon
      [size]="IconSize.XSmall"
      class="card__icon"
      (click)="isCollapsed = !isCollapsed"
      [name]="getCollapseIcon()"
    ></og-icon>
  </div>

  @if (isCollapsed) {
  <div class="content" fxFlex fxLayout="column">
    <div fxLayout="row" fxLayoutAlign="space-between center">
      <div fxLayout="row" fxLayoutGap="4px">
        <ng-content></ng-content>
      </div>
      <og-icon
        class="card__icon"
        name="arrow-right"
        [size]="IconSize.XSmall"
        (click)="onRightArrowClick.emit($event)"
      ></og-icon>
    </div>
  </div>
  }
</div>
