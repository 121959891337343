<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<og-select
  class="select"
  [selectedOption]="selectedBound"
  (selectionChange)="onBoundSelected($event)"
  selectPlacement="bottom-start"
>
  <og-select-label class="select__label">
    000.0
  </og-select-label>

  <og-select-trigger class="select__trigger">
    {{ shortBound }}
  </og-select-trigger>

  <og-select-panel>
    <og-select-panel-title>
      Select cell bounds
    </og-select-panel-title>

    <og-select-option *ngFor="let option of bounds" [value]="option.value">
      {{ option.text }}
    </og-select-option>
  </og-select-panel>
</og-select>
