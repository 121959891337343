/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import * as highcharts from 'highcharts/highstock';
import { WHITE, SILVER, BOULDER } from 'app/shared/utils/colors';
import * as HC_exportData from 'highcharts/modules/exporting';
import * as HC_exportDataToCSV from 'highcharts/modules/export-data';
/** A highcharts chart. */

// enabling exporting module for exporting chart data
HC_exportData.default(highcharts);
HC_exportDataToCSV.default(highcharts);

@Component({
  selector: 'og-highchart',
  templateUrl: 'highchart.component.html',
  styleUrls: ['highchart.component.scss']
})
export class HighchartComponent implements OnChanges {
  /** The highchart options. */
  @Input() options: highcharts.Options;

  @ViewChild('chart', { static: true }) element: ElementRef;

  chart: highcharts.Chart;

  constructor() {
    highcharts.setOptions({
      lang: {
        numericSymbols: ['k', 'm', 'b', 't']
      },
      chart: {
        backgroundColor: 'transparent',
        style: {
          fontFamily: 'Roboto, ArialMT, Arial',
          fontSize: '12px',
          fontWeight: '500'
        },
        reflow: true
      },
      credits: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
      title: {
        text: undefined
      },
      legend: {
        enabled: false,
        itemStyle: {
          color: SILVER,
          fontWeight: 'normal'
        },
        itemHoverStyle: {
          color: WHITE,
          fontWeight: 'normal'
        },
        itemHiddenStyle: {
          color: BOULDER,
          fontWeight: 'normal'
        }
      },
      // -------------------------------------------------------------------------
      xAxis: {
        title: {
          align: 'high',
          margin: 8,
          style: {
            color: WHITE,
            fontSize: '14px',
            fontWeight: 'bold'
          }
        },
        gridZIndex: 0,
        gridLineWidth: 1,
        gridLineDashStyle: 'Dash',
        gridLineColor: 'rgba(255, 255, 255, 0.05)',
        minorGridLineWidth: 1,
        minorGridLineDashStyle: 'Dash',
        minorGridLineColor: 'rgba(255, 255, 255, 0.02)',
        tickWidth: 0,
        lineWidth: 0,
        lineColor: 'rgb(93, 101, 126)',
        labels: {
          style: {
            fontSize: '12px',
            color: 'rgba(255, 255, 255, 0.5)'
          },
          padding: 15
        }
      },
      yAxis: {
        title: {
          align: 'high',
          margin: 13,
          style: {
            color: WHITE,
            fontSize: '14px',
            fontWeight: 'bold'
          }
        },
        gridZIndex: 0,
        gridLineWidth: 1,
        gridLineDashStyle: 'Solid',
        gridLineColor: 'rgba(255, 255, 255, 0.05)',
        minorGridLineWidth: 1,
        minorGridLineDashStyle: 'Solid',
        minorGridLineColor: 'rgba(255, 255, 255, 0.02)',
        lineWidth: 0,
        lineColor: 'rgb(93, 101, 126)',
        labels: {
          style: {
            fontSize: '12px',
            color: 'rgba(255, 255, 255, 0.5)'
          },
          padding: 15
        }
      }
    });
  }

  ngOnChanges() {
    this.createChart();
  }

  private createChart() {
    if (this.element && this.element.nativeElement && this.options) {
      this.chart = new highcharts.Chart(this.element.nativeElement, this.options);
    }
  }
}
