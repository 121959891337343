/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { NgModule } from '@angular/core';
import { SharedModule } from 'app/shared/shared.module';
import { Action, ActionReducer, StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import { flattenReducers } from 'app/shared/store/utils/flatten-reducers';

import { UserAuthEffects } from './store/user-auth.effects';

import * as user from './store/user-auth.reducer';

import { initializer, UserAuthState } from './store/user-auth.state';

@NgModule({
  imports: [
    SharedModule,
    StoreModule.forFeature('user', flattenUserReducers),
    EffectsModule.forFeature([UserAuthEffects])
  ],
  providers: []
})
export class UserAuthModule {}

export function flattenUserReducers(state, action): ActionReducer<UserAuthState, Action> {
  return flattenReducers(initializer, user.reducer)(state, action);
}
