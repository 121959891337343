import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AbstractFileViewerResolver, FileViewerScreenModel, filterUndefined } from '@opengamma/ui';
import { Observable, combineLatest } from 'rxjs';
import { map } from 'rxjs/operators';
import { State } from '../store/reducers';
import * as fromUser from '../../user-auth/store/user-auth.selectors';
import * as urlUtils from 'app/user-auth/services/auth/permission.utils';

@Injectable()
export class CarmaFileViewerResolver extends AbstractFileViewerResolver {
  productName = 'carma';

  constructor(private store: Store<State>) {
    super();
  }

  getScreen(): Observable<FileViewerScreenModel> {
    return combineLatest([
      this.store.pipe(select(fromUser.getScreensByUrl), filterUndefined()),
      this.store.pipe(
        select(({ router }) => router?.state?.url),
        filterUndefined()
      )
    ]).pipe(
      map(([screensByUrl, screenUrl]) => ({
        id: screensByUrl[screenUrl]?.screenPermission,
        name: urlUtils.getScreenNameFromPath(screenUrl),
        url: screenUrl
      }))
    );
  }
}
