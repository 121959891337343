/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';
import { DateTimeService, DateRange } from '@opengamma/ui';

@Component({
  selector: 'og-timeseries-range',
  templateUrl: './timeseries-range.component.html',
  styleUrls: ['./timeseries-range.component.scss']
})
export class TimeseriesRangeComponent {
  formattedRange: string;

  @Input() set selectedDateRange(range: DateRange) {
    if (range) {
      const start = this.dateTimeService.getYearAgnosticShortDate(range.start);
      const end = this.dateTimeService.getYearAgnosticShortDate(range.end);
      this.formattedRange = `${start} - ${end}`;
    }
  }

  constructor(private dateTimeService: DateTimeService) {}
}
