<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="isValidPipeArgument(value)">
  <span [matTooltip]="value | percent: decimalPipeArgs" matTooltipPosition="right">
    {{ value | percent: decimalPipeArgs }}

    <og-subtext *ngIf="subtext" [subtext]="subtext"></og-subtext>
  </span>
</ng-container>
