<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div class="chain">
  @for (items of formattedGroupingItems; track items; let index = $index) {
  <og-dropdown
    class="chain__item"
    (selectedOptionChange)="onGroupingSelected(index, $event.name)"
    theme="darker"
    size="small"
    [selectedOption]="namedValue(selectedGroupings[index])"
    [options]="items"
    name="{{ name }} grouping (level {{ index + 1 }})"
  ></og-dropdown>
  }
</div>
