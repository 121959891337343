/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { UserAuthModels, UserDetails } from '@opengamma/ui';

/** Contains authentication-related methods. */
export abstract class AuthService {
  /** The current idToken */
  abstract idToken: string;

  /** Authenticates or attempts to renew authentication. */
  abstract authenticate(): Observable<UserDetails>;

  /** Unauthenticates the user. */
  abstract unauthenticate(): void;

  /** Returns headers with which to make requests. */
  abstract getRequestHeaders(): HttpHeaders;

  /**
   * Returns data from the /permissions endpoint.
   *
   * @return data from the /permissions endpoint
   */
  abstract permissions(): Observable<UserAuthModels.Permissions>;

  /**
   * Determines whether the currently logged in tenant is a demo environment or not.
   */
  abstract getDemoTenantStatus(tenant: string): Observable<boolean>;

  /**
   * Pre-warm the creation of an IAM policy by the
   * auth lambda that sits in front of this one (which can take 2-5 seconds).
   *
   * This is required because if the Lambda function is not invoked for a while and then invoked later,
   * it may not be as responsive as we would wish it be. Therefore, we call this method to ensure
   * that when the Lambda function is called later on, response time is faster.
   */
  abstract warmUp(): Observable<void>;
}
