/*
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CellForTemplate, CellOptions, NumericBound } from '@opengamma/ui';

@Component({
  selector: 'og-table-cell',
  templateUrl: './table-cell.component.html',
  styleUrls: ['./table-cell.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableCellComponent {
  @Input() cell: CellForTemplate;

  @Input() bound: NumericBound;

  @Input() options: CellOptions;

  getDecimalPipeArgs(decimalPlaces: number): string {
    if (decimalPlaces || decimalPlaces === 0) {
      return `1.${decimalPlaces}-${decimalPlaces}`;
    } else {
      return '1.2-2';
    }
  }
}
