/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  Component,
  Input,
  ElementRef,
  ViewChild,
  OnChanges,
  ChangeDetectionStrategy,
  SimpleChanges
} from '@angular/core';
import { DELTA, SHERPA_BLUE } from 'app/shared/utils/colors';

/**
 * A loading bar with an optional message.
 */
@Component({
  selector: 'og-loading-bar',
  templateUrl: './loading-bar.component.html',
  styleUrls: ['./loading-bar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingBarComponent implements OnChanges {
  @ViewChild('bar', { static: true }) bar: ElementRef;

  /** The value on the progress bar, between 0 and 1. */
  @Input() percent: number;

  /** The theme of the loading bar. */
  @Input() theme: 'light' | 'dark' = 'dark';

  /** The color of the progress bar. */
  @Input() color: string = DELTA;

  /** The background color of the progress bar. */
  @Input() backgroundColor: string = SHERPA_BLUE;

  /** The text to display by the loading bar. */
  @Input() text = '';

  // -------------------------------------------------------------------------
  barWidthPx: string;

  // -------------------------------------------------------------------------
  ngOnChanges(changes: SimpleChanges) {
    this.barWidthPx = `${this.bar.nativeElement.offsetWidth * this.percent}px`;
  }
}
