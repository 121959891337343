import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { AbstractScreenStatusResolver, ScreenStatusScreenModel } from '@opengamma/ui';
import { filterUndefined } from 'app/shared/rxjs/rxjs.utils';
import { getScreensWithSelectableRunsUrlPaths } from 'app/user-auth/services/auth/screen.path.utils';
import { combineLatest, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import * as fromUser from '../../user-auth/store/user-auth.selectors';
import { State } from '../store/reducers';

@Injectable()
export class CarmaScreenStatusResolver extends AbstractScreenStatusResolver {
  constructor(private store: Store<State>) {
    super();
  }

  getRunsUrl(): Observable<string> {
    return this.store.pipe(
      select(fromUser.getScreenUrlByTemplate),
      filterUndefined(),
      map(urlMap => urlMap['MARGIN_CALCULATIONS_RUNS'])
    );
  }

  getScreen(): Observable<ScreenStatusScreenModel> {
    return combineLatest([
      this.store.pipe(select(fromUser.getScreensByUrl), filterUndefined()),
      this.store.pipe(
        select(({ router }) => router?.state?.url),
        filterUndefined()
      )
    ]).pipe(
      map(([screensByUrl, screenUrl]) => ({
        id: screensByUrl[screenUrl]?.screenPermission,
        name: screensByUrl[screenUrl]?.screenName,
        url: screenUrl,
        screenFound: !!screensByUrl[screenUrl]
      }))
    );
  }

  //this util method depends on production definition which is not migrated over yet
  //once migrated over we can remove this method and reference directly from screen status within og-ui
  getScreensWithSelectableRunsUrlPaths(): string[] {
    return getScreensWithSelectableRunsUrlPaths();
  }

  getUrlByScreen(): Observable<{ [key: string]: string }> {
    return this.store.select(fromUser.getScreenUrlByTemplate);
  }

  isOpenGammaUser(): Observable<boolean> {
    return this.store.pipe(select(fromUser.isOpenGammaUser));
  }
}
