/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/shared/store/reducers';
import { FromMarginScreensCommonActions } from '@opengamma/ui';

@Component({
  selector: 'og-timeseries-export-button',
  templateUrl: './timeseries-export-button.component.html'
})
export class TimeseriesExportButtonComponent {
  @Input() pathForDownload = 'chart';
  @Input() featureName: string;
  @Input() selectedItems?: string[];

  onExportChartToCSVButtonClicked(): void {
    this.store.dispatch(
      FromMarginScreensCommonActions.exportTimeSeriesDataToCsv({
        featureName: this.featureName,
        chartPath: this.pathForDownload,
        selectedItems: this.selectedItems
      })
    );
  }

  constructor(private store: Store<State>) {}
}
