<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

@if (legendItems || legendItemsWithColors) {
<div class="legend" fxLayout="row wrap">
  @if (legendItems) { @for (legendItem of legendItems; track legendItem; let i = $index) {
  <div class="legend__item" fxLayout="row" fxLayoutAlign="center center">
    <div class="legend-item__bar" [style.background-color]="colors[i]"></div>
    <div class="legend-item__label">{{ legendItem }}</div>
  </div>
  } } @if (legendItemsWithColors) { @for (legendItem of legendItemsWithColors; track legendItem; let
  i = $index) {
  <div class="legend__item" fxLayout="row" fxLayoutAlign="center center">
    <div class="legend-item__bar" [style.background-color]="legendItem.color"></div>
    <div class="legend-item__label">{{ legendItem.name }}</div>
  </div>
  } }
</div>
}
