<!--
~ Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
~
~ Please see distribution for license.
-->

<div fxLayout="row" fxLayoutGap="8px">
  <og-icon
    name="alert-circle-filled"
    class="color--{{ icon.color }}"
    [size]="IconSize.Large"
    [matTooltip]="icon.tooltip"
  ></og-icon>

  @if (icon.text) {
  <div>
    {{ icon.text }}
  </div>
  }
</div>
