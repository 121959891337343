<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<og-select
  class="select"
  [selectedOption]="selectedOption"
  (selectionChange)="onOptionSelected($event)"
  selectPlacement="bottom-end"
>
  <og-select-label class="select__label">
    <og-icon name="table"></og-icon>
  </og-select-label>

  <og-select-trigger class="select__trigger">
    {{ getSelectedOptionText() }}
  </og-select-trigger>

  <og-select-panel>
    <og-select-panel-title>
      Change table grouping structure
    </og-select-panel-title>

    @for (option of options; track option) {
    <og-select-option [value]="option.value">
      {{ option.text }}
    </og-select-option>
    }
  </og-select-panel>
</og-select>
