<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div
  class="sign"
  [class.sign--positive]="currency.price > 0 && !invertSignColor"
  [class.sign--negative]="currency.price < 0 && !invertSignColor"
  [class.sign--positive-inverted]="currency.price > 0 && invertSignColor"
  [class.sign--negative-inverted]="currency.price < 0 && invertSignColor"
></div>
<div [matTooltip]="currency.price | number">
  {{ currency.symbol }}{{ getAbsPrice(currency.price) | shortNumber: decimalPipeArgs || '1.2-2' }}
</div>
