<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<og-modal [displayCloseIcon]="true" (closeModalClick)="onCloseModalClicked()">
  <div class="header">
    <h2 class="header__file-name">
      {{ fileName }}
      <span class="header__line-number">line {{ lineNumber }}</span>
    </h2>
  </div>

  <div class="table">
    <og-magic-table
      [columnGroups]="errorMetadataTableColumnGroups"
      [rows]="errorMetadataTableRows"
    ></og-magic-table>
  </div>
</og-modal>
