import { Component, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { FromScreenStatusSelectors } from '@opengamma/ui';
import { State } from 'app/shared/store/reducers';

@Component({
  selector: 'og-app-main',
  templateUrl: './app-main.component.html',
  styleUrls: ['./app-main.component.scss']
})
export class AppMainComponent implements OnInit {
  isScreenStatusLoading$: Observable<boolean>;
  isDataAvailable$: Observable<boolean>;

  ngOnInit() {
    this.isScreenStatusLoading$ = this.store.pipe(
      select(FromScreenStatusSelectors.isScreenStatusLoading)
    );
    this.isDataAvailable$ = this.store.pipe(
      select(FromScreenStatusSelectors.isDataAvailableForCurrentScreen)
    );
  }

  constructor(private store: Store<State>) {}
}
