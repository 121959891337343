/*
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, Inject, NgModule } from '@angular/core';
import { Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { first } from 'rxjs/operators';

import { State } from 'app/shared/store/reducers';

import { AuthenticateInterceptor } from './authenticate.interceptor';
import { CacheInterceptor } from './cache.interceptor';
import { OGPipesModule, UserDetails } from '@opengamma/ui';
import * as userActions from 'app/user-auth/store/user-auth.actions';
import { datadogRum } from '@datadog/browser-rum';
import { datadogApiPath } from 'environments/environment.utils';
import { environment } from 'environments/default.environment';
import { Location } from '@angular/common';
import { SelectedRunQueryParamInterceptor } from './selected-run-query-param.interceptor';

@NgModule({
  imports: [OGPipesModule],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: SelectedRunQueryParamInterceptor,
      deps: [[new Inject(Actions)]],
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: CacheInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthenticateInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: initializer,
      deps: [[new Inject(Store)], [new Inject(Actions)]],
      multi: true
    }
  ]
})
export class CoreModule {}

/**
 * THIS IS THE POINT OF ENTRY INTO THE APPLICATION
 * This factory returns a promise which signals Angular that it should render the application
 */
export function initializer(store: Store<State>, actions: Actions): Function {
  return () => {
    /** Attempt to log in the user */
    store.dispatch(userActions.login.load());

    /** Initialise the app only when permissions finish loading */
    const loginResponsePromise = actions
      .pipe(ofType(userActions.ROUTES_CREATED), first())
      .toPromise();

    /** Initialise the app only when permissions finish loading */
    actions
      .pipe(ofType(userActions.login.success), first())
      .toPromise()
      .then(userData => {
        /** Start monitoring if there exists a datadog token for this environment */
        if (environment.datadogClientToken) {
          setUpDatadogMonitoring(userData.data);
        }
      });

    return loginResponsePromise;
  };
}

function setUpDatadogMonitoring(userDetails: UserDetails) {
  const proxyUrl = Location.joinWithSlash(datadogApiPath(), 'forward');
  datadogRum.init({
    applicationId: environment.datadogApplicationId,
    clientToken: environment.datadogClientToken,
    site: 'datadoghq.com',
    env: environment.datadogEnvName,
    service: environment.datadogServiceName,
    sampleRate: 100,
    trackInteractions: true,
    proxyUrl: proxyUrl,
    defaultPrivacyLevel: 'mask-user-input',
    useSecureSessionCookie: true,
    useCrossSiteSessionCookie: false
  });

  try {
    const user = {
      name: userDetails.name,
      email: userDetails.email,
      company: userDetails.companyName,
      type: getUserType(userDetails.email),
      organization: userDetails.organizationId
    };
    datadogRum.setUser(user);
    datadogRum.addRumGlobalContext('user', user);
  } catch (ex) {
    console.error(ex);
  }

  try {
    // See TenantTag in MUI for possible values
    const tags = {
      demo: userDetails.companyTags.includes('demo'),
      poc: userDetails.companyTags.includes('poc'),
      prod: userDetails.companyTags.includes('prod'),
      test: userDetails.companyTags.includes('test'),
      onboarding: userDetails.companyTags.includes('onboarding')
    };
    datadogRum.addRumGlobalContext('tags', tags);
  } catch (ex) {
    console.error(ex);
  }
}

/** Distinguish internal, synthetic (datadog) and external user traffic. */
function getUserType(email: string): string {
  if (email.includes('@opengamma.com')) {
    return email.includes('datadog') ? 'Synthetic' : 'Internal';
  }
  return 'External';
}
