<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<og-labeled-entity [label]="label" [size]="size">
  <div class="entity" content>
    <div class="entity__input" (click)="showCalendar = !showCalendar">
      <input class="input__field" [(ngModel)]="displayDate" />
      <og-icon name="calendar" class="input__icon"></og-icon>
    </div>

    <og-calendar
      *ngIf="showCalendar"
      class="entity__calendar"
      [maxDate]="maxDate"
      [selectedDate]="date"
      (dateChanged)="onDateChange($event)"
    ></og-calendar>
  </div>
</og-labeled-entity>
