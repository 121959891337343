/*
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { CurrencyValue, NamedValue } from '@opengamma/ui';

@Component({
  selector: 'og-selectable-metric',
  templateUrl: './selectable-metrics.component.html',
  styleUrls: ['./selectable-metrics.component.scss']
})
export class SelectableMetricsComponent {
  /** The metrics to be displayed */
  @Input() metrics: NamedValue<CurrencyValue>[];
  /** The metric that is currently active */
  @Input() selectedMetric: string;
  /** The event which fires when a metric is selected */
  @Output() select = new EventEmitter<string>();

  onSelect(value: string) {
    if (value !== this.selectedMetric) {
      this.select.emit(value);
    }
  }
}
