/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';
import { CurrencyValue } from '@opengamma/ui';

@Component({
  selector: 'og-change-currency-value',
  templateUrl: './change-currency-value.component.html',
  styleUrls: ['./change-currency-value.component.scss']
})
export class ChangeCurrencyValueComponent {
  /** The currency to be displayed */
  @Input() currency: CurrencyValue;
  /** The scale of the number to be displayed */
  @Input() bound = 1e6;
  /** Whether to invert the color of the sign */
  @Input() invertSignColor = false;
  /** The decimal pipe formatting options */
  @Input() decimalPipeArgs: string;

  getAbsPrice(price: number): number {
    return Math.abs(price);
  }
}
