/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { WHITE } from 'app/shared/utils/colors';
import { ShortNumberPipe } from '@opengamma/ui';
import { ColumnChartParams } from './column-chart.models';

export const columnChartOptions: Function = (
  params: ColumnChartParams,
  shortNumberPipe: ShortNumberPipe
) => {
  const options = {
    chart: {
      backgroundColor: 'transparent',
      type: 'column'
    },
    title: {
      text: !!params.title ? params.title : undefined,
      style: {
        fontSize: '13px',
        fontWeight: '600'
      }
    },
    xAxis: {
      enabled: true,
      categories: params.xAxisCategories,
      labels: {
        rotation: !!params.rotation ? params.rotation : 0,
        useHTML: true,
        style: {
          color: params.xAxisCategoriesColor || 'rgba(255, 255, 255, 0.25)',
          fontSize: params.xAxisCategoriesFontSize || '10px'
        },
        staggerLines: 2
      },
      lineWidth: 1
    },
    legend: params.legendParams
      ? {
          itemMarginBottom: 8,
          align: params.legendParams.align || 'center',
          enabled: true,
          layout: params.legendParams.layout || 'horizontal',
          verticalAlign: params.legendParams.verticalAlign || 'bottom',
          itemStyle: {
            color: WHITE,
            ...(params.legendParams.disableClick ? { cursor: 'default' } : {})
          },
          itemHoverStyle: {
            color: WHITE
          },
          padding: 0,
          margin: params.legendParams.padding || 8,
          symbolRadius: 0
        }
      : {},
    yAxis: {
      visible: !params.hideYAxis,
      labels: {
        formatter: function() {
          return shortNumberPipe.transform(this.value);
        },
        style: {
          color: 'rgba(255, 255, 255, 0.25)'
        }
      },
      plotLines: [
        {
          value: 0,
          color: 'rgba(255, 255, 255, 0.1)',
          width: 1,
          zIndex: 4
        }
      ],
      tickInterval: params.yAxisTickInterval,
      // eslint enforces undefined over null, but highcharts requires null for this default. Undefined breaks the chart.
      // eslint-disable-next-line no-null/no-null
      tickPixelInterval: params.yAxisTickPixelInterval ? params.yAxisTickPixelInterval : null,
      lineWidth: 1,
      title: {
        text: undefined
      }
    },
    tooltip: params.tooltip ? params.tooltip : { enabled: false },
    plotOptions: {
      column: {
        pointPadding: 0,
        borderWidth: 0,
        groupPadding: 0.2,
        shadow: false,
        pointWidth: params.customPointWidth ? params.customPointWidth : undefined,
        stacking: params.isStacked ? 'normal' : undefined
      },
      series: params.legendParams?.disableClick
        ? {
            events: {
              legendItemClick: () => false
            }
          }
        : {}
    },
    series: [
      ...params.series.map(
        ({ data, color, colors, name, legendIndex, showInLegend, stack, fontSize = '13px' }) => ({
          name,
          data,
          stack,
          legendIndex,
          showInLegend,
          color,
          dataLabels: {
            enabled: params.enableDataLabels,
            allowOverlap: true,
            align: 'center',
            color: color,
            crop: false,
            overflow: 'none',
            formatter: function() {
              return shortNumberPipe.transform(this.y);
            },
            style: { fontSize, textOutline: 'none' }
          },
          enableMouseTracking: true
        })
      )
    ]
  };

  return options;
};
