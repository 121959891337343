/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';

/** Data to be exported as a CSV. */
export interface CsvExportableData {
  /** The list of headers used for the groupings. */
  groupingHeaders: string[];

  /** The list of headers used for the values. */
  valueHeaders: string[];

  /** The rows. */
  rows: CsvExportableRow[];
}

/** A row of data with children to be exported as a CSV. */
export interface CsvExportableRow {
  /** The row grouping, corresponding to the depth of the row. */
  groupings?: string | string[];

  /** The row values. */
  values: any[];

  /** The child rows. */
  children?: CsvExportableRow[];
}

// -------------------------------------------------------------------------
/** Provides the ability to generate CSV files from tree-table structures. */
@Injectable()
export class CsvExporterService {
  /**
   * Serialise the given data and return the CSV string.
   *
   * The CSV file will be formatted such that every child row is indented by one cell from the parents,
   * with the common grouping value empty. For example, data which looks as follows:
   * <pre>
   *   {
   *     groupingHeaders: ['CCP', 'Broker'],
   *     valueHeaders: ['IM'],
   *     rows: [{
   *       grouping: 'EUREX',
   *       values: [123456],
   *       children: [{
   *         grouping: 'JPM',
   *         values: [98765]
   *       }, {
   *         grouping: 'BARC',
   *         values: [87654]
   *       }]
   *     }, {
   *       grouping: 'LCH',
   *       values: [234565],
   *       children: [{
   *         grouping: 'MS',
   *         values: [9876]
   *       }, {
   *         grouping: 'GS',
   *         values: [8765]
   *       }]
   *     }]
   *   }
   * </pre>
   * Should be exported to a CSV which, when formatted, looks as follows:
   * <pre>
   *  _________________________
   * | CCP   | Broker | IM     |
   * | EUREX |        | 123456 |
   * |       | JPM    | 98765  |
   * |       | BARC   | 87654  |
   * | LCH   |        | 234565 |
   * |       | MS     | 9876   |
   * |       | GS     | 8765   |
   *  ‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾‾
   * </pre>
   *
   */

  serializeData(data: CsvExportableData): string {
    const headers = data.groupingHeaders
      .concat(data.valueHeaders)
      .map(this.formatItem)
      .join(',')
      .concat('\n');

    const values = data.rows
      .map(row => this.formatRow(row, 0, data.groupingHeaders.length))
      .join('');

    return headers + values;
  }

  /**
   * Exports the given data as a CSV.
   *
   * See {@link #serializeData} for details on how the serialisation works.
   *
   * @param data the data to export
   * @param fileName the file name to export as
   */
  exportData(data: CsvExportableData, fileName: string) {
    const csv = this.serializeData(data);
    this.exportRawData(csv, fileName);
  }

  /**
   * Exports the given raw string data to a CSV file.
   *
   * @param rawData the raw file data
   * @param filename the filename
   */
  exportRawData(rawData: string, filename: string) {
    const blob = new Blob([rawData], { type: 'text/csv;charset=utf8' });
    FileSaver.saveAs(blob, filename);
  }

  // formats a row for use in a CSV file
  protected formatRow(row: CsvExportableRow, level: number, totalLevels: number): string {
    const result = this.buildInitialRowValues(row, level)
      .concat(new Array(totalLevels - level - 1 > 0 ? totalLevels - level - 1 : 0))
      .concat(row.values)
      .map(this.formatItem)
      .join(',')
      .concat('\n');

    if (row.children && row.children.length) {
      return result.concat(
        row.children.map(child => this.formatRow(child, level + 1, totalLevels)).join('')
      );
    }

    return result;
  }

  // formats an item for use in a CSV file
  protected formatItem(item: any): string {
    if (item === undefined) {
      return '';
    }
    if (typeof item === 'number') {
      item = item.toString();
    }
    // csv spec requires quotes to be escaped with another quote (ie, `foo "bar"` -> `"foo ""bar"""`)
    return JSON.stringify(item).replace(/\\"/g, '""');
  }

  private buildInitialRowValues(row: CsvExportableRow, level: number): string[] {
    const groupingsArray = this.buildGroupingsArray(row);
    return Array.isArray(row.groupings) ? groupingsArray : new Array(level).concat(groupingsArray);
  }

  private buildGroupingsArray(row: CsvExportableRow): string[] {
    if (!row.groupings) {
      return [];
    } else if (!Array.isArray(row.groupings)) {
      return [row.groupings];
    }
    return row.groupings;
  }
}
