/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {
  TradeTableColumn,
  TradeTableColumnType,
  TradeTableRow,
  TradeTableRowRemovalEvent
} from './trade-table.models';

@Component({
  selector: 'og-trade-table',
  templateUrl: './trade-table.component.html',
  styleUrls: ['./trade-table.component.scss']
})
export class TradeTableComponent implements OnChanges {
  /** The columns for the table. */
  @Input() columns: TradeTableColumn[];
  /** The row data. */
  @Input() rows: TradeTableRow[];
  /** True if the table should display a loading animation. */
  @Input() loading = false;
  /** Defines if the search should be enabled */
  @Input() enableSearch = false;
  /** Defines if a red cross should appear next to each row, allowing for row removal */
  @Input() enableRowRemoval = false;

  /** Emits table filter changes. */
  @Output() filterChange = new EventEmitter<string>();
  /** Emits row removal clicks. */
  @Output() rowRemoval = new EventEmitter<TradeTableRowRemovalEvent>();

  dataTableColumns: any[];
  dataTableRows: any[];

  completeDataTableRows: any[];

  // ------------------------------------------------------------------------
  // cell templates
  @ViewChild('currencyTemplate') private currencyTemplate: TemplateRef<any>;
  @ViewChild('dateTemplate') private dateTemplate: TemplateRef<any>;
  @ViewChild('numberTemplate') private numberTemplate: TemplateRef<any>;
  @ViewChild('percentTemplate') private percentTemplate: TemplateRef<any>;
  @ViewChild('defaultTemplate') private defaultTemplate: TemplateRef<any>;

  // ------------------------------------------------------------------------
  updateFilter(event) {
    const query = event.target.value.toLowerCase();
    this.filterChange.next(query);

    // clear filter
    if (!query) {
      this.dataTableRows = this.completeDataTableRows;
      return;
    }

    this.dataTableRows = this.completeDataTableRows.filter(row => {
      for (const column of this.columns) {
        const val = row[column.label];
        switch (column.type) {
          case 'currency':
            if (
              val.price
                .toString()
                .toLowerCase()
                .indexOf(query) >= 0
            ) {
              return true;
            }
            break;
          case 'number':
            if (
              (Math.round(val * 100) / 100)
                .toString()
                .toLowerCase()
                .indexOf(query) >= 0
            ) {
              return true;
            }
            break;
          default:
            if (
              val
                .toString()
                .toLowerCase()
                .indexOf(query) >= 0
            ) {
              return true;
            }
        }
      }
      return false;
    });
  }

  ngOnChanges() {
    if (this.columns) {
      this.dataTableColumns = this.columns.map(col => {
        return {
          name: col.label,
          prop: col.label,
          draggable: false, // dragging seems to be fairly buggy
          ...this.getColumnMetadata(col.type)
        };
      });
    }

    if (this.rows && this.columns) {
      this.completeDataTableRows = this.rows.map(row =>
        row.reduce((acc, val, index) => {
          acc[this.columns[index].label] = val;
          return acc;
        }, {})
      );

      this.dataTableRows = this.completeDataTableRows;
    }
  }

  // ------------------------------------------------------------------------
  // comparators
  private dateComparator(a, b) {
    return Date.parse(a) - Date.parse(b);
  }

  private currencyComparator(a, b) {
    return a.price - b.price;
  }

  // ------------------------------------------------------------------------
  private getColumnMetadata(columnType: TradeTableColumnType) {
    switch (columnType) {
      case 'currency':
        return {
          cellTemplate: this.currencyTemplate,
          comparator: this.currencyComparator
        };
      case 'date':
        return {
          cellTemplate: this.dateTemplate,
          comparator: this.dateComparator
        };
      case 'percent':
        return {
          cellTemplate: this.percentTemplate
        };
      case 'number':
        return {
          cellTemplate: this.numberTemplate
        };
      default:
        return {
          cellTemplate: this.defaultTemplate
        };
    }
  }

  onRowRemoval(row: TradeTableRow, index: number) {
    this.rowRemoval.next({ row, index });
  }
}
