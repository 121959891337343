<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<ng-template
  [ngTemplateOutlet]="table"
  [ngTemplateOutletContext]="{
    $implicit: {
      columnGroups: columnGroups,
      columns: getColumnsFromColumnGroups(columnGroups),
      rows: rows$ | async,
      level: 0,
      path: ['']
    }
  }"
></ng-template>

<!-- Table Template -->
<ng-template #table let-table>
  <table class="table">
    <thead class="table__head">
      <ng-container *ngIf="table.columnGroups.length > 1">
        <tr class="table__header-row">
          <th
            *ngFor="let columnGroup of table.columnGroups; let last = last"
            class="table__header-cell"
            [class.table__header-cell--no-border]="last"
            [class.table__header-cell--no-padding]="!columnGroup.label"
            [attr.colspan]="columnGroup.columns.length"
          >
            <div fxLayout="row">
              <div class="cell__content" fxLayout="row" fxFlex="grow" fxLayoutAlign="center center">
                <ng-template
                  [ngTemplateOutlet]="columnTitle"
                  [ngTemplateOutletContext]="{
                    $implicit: { label: columnGroup.label }
                  }"
                ></ng-template>
              </div>
            </div>
          </th>
        </tr>
      </ng-container>

      <tr class="table__header-row">
        <th
          *ngFor="let column of table.columns; let last = last"
          class="table__header-cell"
          [class.table__header-cell--no-border]="last"
          [class.checkbox-column]="last && column.id === 'export'"
          [class.table__header-cell--inner]="table.level !== 0"
        >
          <ng-template
            [ngTemplateOutlet]="columnTitle"
            [ngTemplateOutletContext]="{
              $implicit: { label: column.label }
            }"
          ></ng-template>
        </th>
      </tr>
    </thead>

    <tbody class="table__body">
      <ng-template
        [ngTemplateOutlet]="tableRows"
        [ngTemplateOutletContext]="{
          $implicit: {
            rows: table.rows,
            columns: table.columns,
            level: table.level,
            path: table.path
          }
        }"
      ></ng-template>
    </tbody>
  </table>
</ng-template>

<!-- Column Title Template -->
<ng-template #columnTitle let-title>
  <div *ngIf="title.tooltip" [matTooltip]="title.tooltip" class="table__header-cell-icon">
    <og-icon name="question-circle"></og-icon>
  </div>
  {{ title.label }}
</ng-template>

<!-- Rows Template -->
<ng-template #tableRows let-node>
  <ng-container
    *ngFor="
      let row of node.rows.slice(0, getNumberOfRowsToDisplayForPath(node.path));
      trackBy: trackRows
    "
  >
    <tr
      class="table__row"
      [class.table__row--expandable]="row?.isExpandable"
      [class.table__row--border-bottom]="node.level == 0"
    >
      <td
        *ngFor="let column of node.columns; let first = first; let last = last"
        class="table__cell"
        (click)="column.type !== 'export' && row?.isExpandable && onRowExpand(row.id)"
        [class.checkbox-column]="last && column.id === 'export'"
        [class.table__cell--no-border-right]="last"
      >
        <div
          class="table__cell-content"
          fxLayout="row"
          [fxLayoutAlign]="first ? 'start center' : 'center center'"
        >
          <ng-container *ngIf="first">
            <div class="table__expansion-icon">
              <og-icon
                *ngIf="row?.isExpandable"
                [name]="row?.isExpanded ? 'minus' : 'plus'"
                [stroke]="IconStroke.XThick"
              ></og-icon>
            </div>
          </ng-container>

          <ng-container *ngIf="getCellForTemplate(row, column).type !== 'export'; else checkBox">
            <og-table-cell
              [cell]="getCellForTemplate(row, column)"
              [bound]="column?.options?.columnBound || bound"
              [options]="column.options"
            ></og-table-cell>
          </ng-container>

          <ng-template #checkBox>
            <og-checkbox-noop
              class="cell__checkbox"
              [checked]="isChecked((row?.values)[column.id], row)"
              [indeterminate]="isIndeterminate(row)"
              (click)="onCheck((row?.values)[column.id], row)"
            ></og-checkbox-noop>
          </ng-template>
        </div>
      </td>
    </tr>

    <!-- Inner table -->
    <ng-container *ngIf="row.children">
      <tr class="table__row">
        <td [attr.colspan]="getColumnsFromColumnGroups(columnGroups).length">
          <ng-template
            [ngTemplateOutlet]="table"
            [ngTemplateOutletContext]="{
              $implicit: {
                columnGroups: row.children.columnGroups,
                columns: getColumnsFromColumnGroups(row.children.columnGroups),
                rows: row.children.rows,
                level: node.level + 1,
                path: node.path.concat(row.id)
              }
            }"
          ></ng-template>
        </td>
      </tr>
    </ng-container>
    <!-- Vertical pagination -->
  </ng-container>
  <ng-container *ngIf="getNumberOfRowsToDisplayForPath(node.path) as numberOfRowsToDisplay">
    <tr
      class="table__row table__row--show-more table__row--{{ node.level }}"
      *ngIf="!node.isTotal && node.rows && node.rows.length > numberOfRowsToDisplay"
      (click)="onShowMoreRows(node.path)"
      test-ref="show-more-row"
    >
      <td
        class="table__cell table__cell--show-more table__cell--no-border-right"
        [attr.colspan]="node.columns.length"
      >
        <div
          class="table__show-more table__show-more--{{ node.level }}"
          fxLayout="row"
          fxLayoutAlign="space-between center"
        >
          <div class="show-more__call-to-action" fxLayout="row">
            <div class="table__expansion-icon">
              <og-icon name="chevron-down"></og-icon>
            </div>
            <div>Show {{ rowPaginationCount }} more</div>
          </div>
          <div class="show-more__info">
            Showing
            <span class="show-more__row-count">
              {{ numberOfRowsToDisplay }}
            </span>
            rows of
            <span class="show-more__row-count">{{ node.rows.length }}</span>
          </div>
        </div>
      </td>
    </tr>
  </ng-container>
</ng-template>
