<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div class="entity entity--{{ size }} entity--{{ theme }}">
  <div class="entity__title entity__title--{{ size }} entity__title--{{ theme }}">
    {{ label }}
  </div>
  <div class="entity__value entity__value--{{ size }}">
    <ng-content select="[content]"></ng-content>
  </div>
  <ng-container *ngIf="subtext">
    <div class="entity__divider"></div>
    <div class="entity__subtext entity__subtext--{{ size }} entity__subtext--{{ theme }}">
      {{ subtext }}
    </div>
  </ng-container>
</div>
