/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges } from '@angular/core';
import { CsvOptions } from './simple-csv-download.model';
import { DatePipe } from '@angular/common';
import { CalcResultCsvModel } from 'app/screens/margin/what-if/portfolio/model/calc-result-csv.model';
import { CsvExporterService } from 'app/shared/services/csv-exporter.service';
import { IconSize } from '@opengamma/ui';

/** @deprecated prefer {@link TreeCsvDownloadButtonComponent} due to less shit typing */
@Component({
  selector: 'og-simple-csv-download-button',
  templateUrl: './simple-csv-download-button.component.html',
  styleUrls: ['./simple-csv-download-button.component.scss']
})
export class SimpleCsvDownloadButtonComponent implements OnChanges {
  /** The name of the csv file */
  @Input() fileName: string;
  /** The data to plug in */
  @Input() data: CalcResultCsvModel[];
  /** The csv options to use for the CSV creation  */
  @Input() csvOptions: CsvOptions;

  timestampedName: string;
  IconSize = IconSize;

  constructor(private datePipe: DatePipe, private csvExporterService: CsvExporterService) {}

  onDownloadCsvClick() {
    // Angular2Csv object creation triggers the download (not ideal)
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions

    const scvExportabeData = {
      groupingHeaders: [],
      valueHeaders: ['component', 'baseValue', 'whatIfValue'],
      rows: this.data.map(datum => ({
        values: [datum.component, datum.baseValue, datum.whatIfValue]
      }))
    };
    this.csvExporterService.exportData(scvExportabeData, this.fileName);
  }

  ngOnChanges() {
    if (this.fileName) {
      this.timestampedName = `${this.fileName}-${this.datePipe.transform(
        new Date(),
        'yyyy-MM-dd HH:mm:ss'
      )}`;
    }
  }
}
