<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<og-labeled-entity [label]="label" [size]="size">
  <div class="container" content>
    <input
      class="container__input container__input--{{ size }}"
      placeholder="{{ placeholder }}"
      type="{{ inputType }}"
      step="{{ step }}"
      [(ngModel)]="value"
      (input)="onInputChange($event.target.value)"
    />
  </div>
</og-labeled-entity>
