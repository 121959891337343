/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, Component } from '@angular/core';

@Component({
  selector: 'og-timeseries-card-header',
  templateUrl: './timeseries-card-header.component.html',
  styleUrls: ['./timeseries-card-header.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TimeseriesCardHeaderComponent {}
