/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import * as Highcharts from 'highcharts/highstock';
import { Component, Input, OnChanges, SimpleChanges, SimpleChange } from '@angular/core';
import { NamedTimeSeries } from 'app/shared/components/charts/legacy/timeseries-chart/timeseries-chart.component';
import * as _ from 'lodash';
import { OGTimeseriesComponent } from 'app/shared/components/charts/modern/timeseries-chart/timeseries-chart.component';
import {
  NamedTimeSeriesWithThresholds,
  NamedTimeSeriesWithThresholdsColors,
  TimeSeriesPoint
} from 'app/shared/models';
import { HighchartsZone } from 'app/shared/components/charts/modern/timeseries-chart/timeseries-chart.model';
import {
  CoordinatePair,
  Coordinate
} from 'app/shared/components/charts/modern/timeseries-with-thresholds-chart/timeseries-with-thresholds-chart.model';
import * as colors from 'app/shared/utils/colors';
import { convertToHighchartsTimeseries } from 'app/shared/components/charts/modern/timeseries-chart/timeseries-chart.utils';
import {
  DateTimeService,
  BoundedShortNumberPipe,
  Comparators,
  PageWidthLayoutService
} from '@opengamma/ui';

const BLUES = colors.MARGIN_BLUE_HUES.slice(0, 5);
const DARKER_BLUES = colors.MARGIN_BLUE_HUES_DARK.slice(0, 5);

@Component({
  selector: 'og-timeseries-with-thresholds',
  templateUrl: '../highchart/highchart.component.html',
  styleUrls: ['../highchart/highchart.component.scss']
})
export class OGTimeseriesWithThresholdsComponent extends OGTimeseriesComponent
  implements OnChanges {
  @Input() seriesWithThresholds: NamedTimeSeriesWithThresholds[];

  @Input() colorsWithThresholds: NamedTimeSeriesWithThresholdsColors[] = BLUES.map(
    (color, index) => ({
      trend: color,
      threshold: DARKER_BLUES[index]
    })
  );

  constructor(
    dateTimeService: DateTimeService,
    boundedShortNumberPipe: BoundedShortNumberPipe,
    pageWidthLayoutService: PageWidthLayoutService
  ) {
    super(dateTimeService, boundedShortNumberPipe, pageWidthLayoutService);
  }

  ngOnChanges(changes: SimpleChanges): void {
    let extraOptionsSimpleChange: SimpleChange;

    if (changes.seriesWithThresholds?.currentValue) {
      const data = changes.seriesWithThresholds.currentValue;

      const deduplicatedAndSortedSeriesData = data.map(series => ({
        trend: this.deduplicateSeriesDates(series.trend),
        threshold: this.deduplicateSeriesDates(series.threshold)
      }));

      const extraOptions = {
        ...(changes.extraOptions ?? {}),
        ...this.getHighchartsSeries(deduplicatedAndSortedSeriesData)
      };

      this.options = _.merge(this.options, extraOptions);

      extraOptionsSimpleChange = new SimpleChange(undefined, extraOptions, false);
    }

    super.ngOnChanges({ ...changes, extraOptions: extraOptionsSimpleChange });
  }

  private deduplicateSeriesDates(series: NamedTimeSeries): NamedTimeSeries {
    return {
      ...series,
      timeSeries: _.uniqBy(series.timeSeries, point => point.date).sort((a, b) =>
        Comparators.default(b.date, a.date)
      )
    };
  }

  private getHighchartsSeries(data: NamedTimeSeriesWithThresholds[]): Highcharts.Options {
    return {
      series: data.flatMap((namedTimeSeries, index) => [
        {
          id: namedTimeSeries.threshold.name,
          name: namedTimeSeries.threshold.name,
          data: convertToHighchartsTimeseries(namedTimeSeries.threshold),
          type: 'line',
          color: this.colorsWithThresholds[index % this.colorsWithThresholds.length].threshold,
          dashStyle: 'LongDash'
        },
        {
          id: namedTimeSeries.trend.name,
          name: namedTimeSeries.trend.name,
          data: convertToHighchartsTimeseries(namedTimeSeries.trend),
          type: 'line',
          color: this.colorsWithThresholds[index % this.colorsWithThresholds.length].trend,
          zoneAxis: 'x',
          zones: this.getZones(
            namedTimeSeries,
            this.colorsWithThresholds[index % this.colorsWithThresholds.length]
          )
        }
      ])
    };
  }

  /**
   * Calculate intersections between threshold and trend series to plot zones.
   */
  private getZones(
    timeSeries: NamedTimeSeriesWithThresholds,
    seriesColors: NamedTimeSeriesWithThresholdsColors
  ): HighchartsZone[] {
    const zones: HighchartsZone[] = [];
    let currentZone: HighchartsZone;

    for (let i = 0; i < timeSeries.trend.timeSeries.length; i++) {
      const trendDatum = timeSeries.trend.timeSeries[i];
      const thresholdDatum = timeSeries.threshold.timeSeries[i];
      const previousTrendDatum = timeSeries.trend.timeSeries[i - 1];
      const previousThresholdDatum = timeSeries.threshold.timeSeries[i - 1];

      if (trendDatum.value > thresholdDatum.value) {
        if (!currentZone) {
          // Else, if currentZone is not set, set threshold color
          currentZone = {
            value: 0,
            color: seriesColors.threshold
          };
        } else if (currentZone.color === seriesColors.trend) {
          // Else, if currentZone is set, but it is a trend zone, save that zone and start a
          // new threshold zone
          currentZone = this.buildCurrentZoneWithIntersectValue(
            previousTrendDatum,
            trendDatum,
            previousThresholdDatum,
            thresholdDatum,
            currentZone
          );

          zones.push(currentZone);

          currentZone = {
            value: 0,
            color: seriesColors.threshold
          };
        }
      } else {
        if (!currentZone) {
          // Else, if currentZone is not set, set trend color
          currentZone = {
            value: 0,
            color: seriesColors.trend
          };
        } else if (currentZone.color === seriesColors.threshold) {
          // Else, if currentZone is set, save that zone and start a new trend zone
          currentZone = this.buildCurrentZoneWithIntersectValue(
            previousTrendDatum,
            trendDatum,
            previousThresholdDatum,
            thresholdDatum,
            currentZone
          );

          zones.push(currentZone);

          currentZone = {
            value: 0,
            color: seriesColors.trend
          };
        }
      }

      // Register the first point's zone color in case there is an intersection between points 0 and 1
      if (i === 0) {
        zones.push(currentZone);
      }
    }

    // Save the last available zone with value undefined so that the last zone series colour applies from the point
    // of the final intersection
    zones.push({ ...currentZone, value: undefined });

    return zones;
  }

  private buildCurrentZoneWithIntersectValue(
    previousTrendDatum: TimeSeriesPoint,
    trendDatum: TimeSeriesPoint,
    previousThresholdDatum: TimeSeriesPoint,
    thresholdDatum: TimeSeriesPoint,
    currentZone: HighchartsZone
  ): HighchartsZone {
    const trendPoints: CoordinatePair = {
      first: this.convertToCoordinates(previousTrendDatum),
      second: this.convertToCoordinates(trendDatum)
    };
    const thresholdPoints: CoordinatePair = {
      first: this.convertToCoordinates(previousThresholdDatum),
      second: this.convertToCoordinates(thresholdDatum)
    };
    return {
      ...currentZone,
      value: this.getXAxisIntersection(trendPoints, thresholdPoints)
    };
  }

  private convertToCoordinates(point: TimeSeriesPoint): Coordinate {
    return {
      x: this.dateTimeService.getDateTimeFromISO(point.date).toMillis(),
      y: point.value
    };
  }

  /**
   * Find the x value of the intersection of the trend and threshold lines.
   * This is calculated by finding the equations of each straight line created by any two adjacent points on the chart,
   *    and equating these equations to find the x value.
   * Points are chosen such that between the points, the trend and threshold lines intersect.
   */
  private getXAxisIntersection(
    trendCoords: CoordinatePair,
    thresholdCoords: CoordinatePair
  ): number {
    const trendGradient =
      (trendCoords.second.y - trendCoords.first.y) / (trendCoords.second.x - trendCoords.first.x);
    const trendYIntersect = trendCoords.first.y - trendCoords.first.x * trendGradient;

    const thresholdGradient =
      (thresholdCoords.second.y - thresholdCoords.first.y) /
      (thresholdCoords.second.x - thresholdCoords.first.x);
    const thresholdYIntersect =
      thresholdCoords.first.y - thresholdCoords.first.x * thresholdGradient;

    const intersection =
      (thresholdYIntersect - trendYIntersect) / (trendGradient - thresholdGradient);

    return Math.round(intersection);
  }
}
