<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
-->
<section
  class="config__tile config__tile--{{ size }}"
  *ngIf="!hidden"
  [@slideIn]
  [ngClass]="[status | lowercase, theme]"
>
  <h3>
    {{ title }}
    <span *ngIf="status === 'COMPLETE'" class="success_wrapper">
      <og-icon name="circle-check-filled" [size]="IconSize.Large"></og-icon>
    </span>
  </h3>

  <div class="error-container" *ngIf="error">
    <og-error [text]="error" [theme]="'light'" (refreshClick)="onErrorRetryClicked()">
      <div class="error__change-selection" (click)="onErrorChangeSelectionClicked()">
        Change Selection
      </div>
    </og-error>
  </div>

  <div class="content__wrapper">
    <div *ngIf="loading" class="loading__container">
      <og-spinner></og-spinner>
    </div>
    <ng-content *ngIf="!error"></ng-content>
  </div>
  <div *ngIf="showCancelButton" class="cancel-button__wrapper" (click)="onCancelButtonClicked()">
    <span class="cancel__button">CANCEL</span>
  </div>
</section>
