/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, EventEmitter, Output } from '@angular/core';
import { NamedValue } from '@opengamma/ui';
import { ChartMode } from 'app/shared/components/card/timeseries-card-header/timeseries-card-header.model';

@Component({
  selector: 'og-timeseries-toggle',
  templateUrl: './timeseries-toggle.component.html',
  styleUrls: ['./timeseries-toggle.component.scss']
})
export class TimeseriesToggleComponent {
  @Input() selectedChartMode: ChartMode;
  @Input() chartModes: NamedValue<ChartMode>[];
  @Output() onToggleChange = new EventEmitter<ChartMode>();
}
