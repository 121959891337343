<og-header></og-header>
<og-screen-status-header></og-screen-status-header>
<og-layout-container>
  @let isScreenStatusLoading = isScreenStatusLoading$ | async; @let isDataAvailable =
  isDataAvailable$ | async; @let noResultsPlaceHolderAlreadyDefined =
  noResultsPlaceHolderAlreadyDefined$ | async;

  <og-spinner [class.hidden]="!isScreenStatusLoading" display="overlay"></og-spinner>

  <div class="main__content" [class.hidden]="isScreenStatusLoading">
    <router-outlet [class.hidden]="!isDataAvailable"></router-outlet>

    @if(!isDataAvailable && !noResultsPlaceHolderAlreadyDefined) {
    <og-placeholder-icon
      label="No results available for the current selected date."
    ></og-placeholder-icon>
    }
  </div>
</og-layout-container>
