/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  AfterContentChecked,
  AfterContentInit,
  ChangeDetectionStrategy,
  Component,
  ContentChild,
  Input,
  OnDestroy
} from '@angular/core';
import { TableComponent } from 'app/shared/components/table/table.component';
import { TableTextFilterComponent } from 'app/shared/components/table-wrapper/table-text-filter/table-text-filter.component';
import { TableBoundsSelectComponent } from 'app/shared/components/table-wrapper/table-bounds-select/table-bounds-select.component';
import { Observable, Subscription } from 'rxjs';
import { TableColumnsSelectComponent } from 'app/shared/components/table-wrapper/table-columns-select/table-columns-select.component';
import { debounceTime, takeUntil, withLatestFrom } from 'rxjs/operators';
import {
  FlatViewMetaData,
  TableColumnGroup,
  LocalStorageService,
  NumericBound
} from '@opengamma/ui';
import {
  FlatViewOption,
  TableFlatViewSelectComponent
} from 'app/shared/components/table-wrapper/table-leaf-view-select/table-leaf-view-select.component';
import {
  transformColumnsFromFlatView,
  transformColumnsToFlatView
} from 'app/shared/components/table/utils/table.utils';
import { getValidatedSelectedColumns } from 'app/shared/components/table-wrapper/table-wrapper.utils';
import { UnsubscriberComponent } from 'app/directives/unsubscriber/unsubscriber.directive';

/**
 * Provides the table component found in OGTableModule with some standard functionality.
 *
 * If you are to manually control a components functionality that is used by TableWrapperComponent, such as
 *    TableBoundsSelectComponent then you must provide that component with the property
 *    ** exportFunctionality = true **. Otherwise TableWrapperComponent will also control TableComponent,
 *    potentially causing hard to find bugs.
 */
@Component({
  selector: 'og-table-wrapper',
  templateUrl: './table-wrapper.component.html',
  styleUrls: ['./table-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableWrapperComponent extends UnsubscriberComponent
  implements AfterContentInit, AfterContentChecked, OnDestroy {
  /** A table wrapper specific identifier used for meta data stored in localStorage, e.g. selected columns. */
  private _id: string;
  private subscriptionList: Subscription[] = [];
  /** We keep track of the previous id value to avoid unnecessary processing. */
  private prevId: string;

  @ContentChild(TableComponent) tableComponent: TableComponent;
  @ContentChild(TableTextFilterComponent) tableTextFilterComponent: TableTextFilterComponent;
  @ContentChild(TableBoundsSelectComponent) tableBoundsSelectComponent: TableBoundsSelectComponent;
  @ContentChild(TableColumnsSelectComponent)
  tableColumnsSelectComponent: TableColumnsSelectComponent;
  @ContentChild(TableFlatViewSelectComponent)
  tableFlatViewSelectComponent: TableFlatViewSelectComponent;

  /**
   * Note: if localStorageForSelectedColumns is enabled and the table utilises flatViewMetaData, you must
   *    modify the table id provided by the host component of TableWrapperComponent to account for the
   *    current flat view mode, i.e., whether FlatViewMetaData.renderLeafView is enabled or not.
   * Otherwise, TableWrapperComponent will not be able to distinguish between locally stored selected columns
   *    for standard and flat view tables.
   */
  @Input() disableLocalStorageForSelectedColumns = false;
  @Input() disableLocalStorageForSelectedBounds = false;

  @Input()
  set id(id: string) {
    if (!id) {
      throw new Error('No table wrapper ID');
    }

    this._id = `table-${id}`;
  }
  get id(): string {
    return this._id;
  }

  @Input() columnGroups$: Observable<TableColumnGroup[]>;
  constructor(private localStorageService: LocalStorageService) {
    super();
  }

  ngAfterContentInit(): void {
    this.columnGroups$.pipe(takeUntil(this.isDestroyed$)).subscribe(columnGroups => {
      this.tableColumnsSelectComponent.columnGroups = columnGroups;
    });
  }

  /**
   * We need to utilise ngAfterContentChecked to overcome potential timing issues between inputs on the table
   *    component from its host, and this wrapping component.
   * We only re-initialise the setup process if the input table id is different from the previous. If this doesn't
   *    happen, we encounter serious performance issues, as setupWrapper runs on every ngDoCheck().
   */
  ngAfterContentChecked(): void {
    if (this.tableComponent && this.prevId !== this.id) {
      this.prevId = this.id;
      this.setupWrapper();
    }
  }

  ngOnDestroy(): void {
    this.clearSubscriptions();
  }

  private setupWrapper(): void {
    this.clearSubscriptions();
    this.setupTextFilter();
    this.setupBoundSelect();
    this.setupColumnSelect();
    this.setupFlatViewSelect();
  }

  private setupTextFilter(): void {
    if (this.tableTextFilterComponent && !this.tableTextFilterComponent.exportFunctionality) {
      this.tableTextFilterComponent.filter = '';
      this.tableComponent.textFilter = '';

      const textFilterSubscription = this.tableTextFilterComponent.onFilterChange
        .pipe(debounceTime(250))
        .subscribe(filter => {
          this.tableComponent.textFilter = filter;
        });

      this.subscriptionList.push(textFilterSubscription);
    }
  }

  private setupBoundSelect(): void {
    if (this.tableBoundsSelectComponent && !this.tableBoundsSelectComponent.exportFunctionality) {
      const selectedBoundId =
        !this.disableLocalStorageForSelectedBounds &&
        this.localStorageService.generateId(this.id, 'selected-bound');
      const initBound =
        (!this.disableLocalStorageForSelectedBounds &&
          this.localStorageService.getData<NumericBound>(selectedBoundId)) ||
        1e6;

      if (this.tableBoundsSelectComponent.selectedBound !== undefined) {
        this.tableComponent.bound = this.tableBoundsSelectComponent.selectedBound;
      } else if (initBound) {
        this.tableComponent.bound = initBound;
        this.tableBoundsSelectComponent.selectedBound = initBound;
      }

      const boundSelectSubscription = this.tableBoundsSelectComponent.selectedBoundChange.subscribe(
        (bound: NumericBound) => {
          this.tableComponent.bound = bound;
          if (!this.disableLocalStorageForSelectedBounds) {
            this.localStorageService.setData(selectedBoundId, bound);
          }
        }
      );

      this.subscriptionList.push(boundSelectSubscription);
    }
  }

  /**
   * If the table components columnGroups change, it will either be
   *    a) because of the TableWrapperComponent and the TableColumnSelectComponent
   *    b) because of some external functionality, in which case the TableColumnSelectComponent
   *        will be inactive
   * Therefore, we only need to fetch the initially set columnGroups value from the TableComponent,
   *    and we don't need to track it with time
   */
  private async setupColumnSelect(): Promise<void> {
    if (this.tableColumnsSelectComponent && !this.tableColumnsSelectComponent.exportFunctionality) {
      const selectedColumnsId =
        !this.disableLocalStorageForSelectedColumns &&
        this.localStorageService.generateId(this.id, 'selected-columns');

      this.tableColumnsSelectComponent.columnGroups =
        (!this.disableLocalStorageForSelectedColumns &&
          getValidatedSelectedColumns(
            this.localStorageService.getData<TableColumnGroup[]>(selectedColumnsId),
            this.tableColumnsSelectComponent.columnGroups
          )) ||
        this.tableColumnsSelectComponent.columnGroups;

      this.setTableComponentColumnGroups(this.tableColumnsSelectComponent.columnGroups);

      const columnsSelectSubscription = this.tableColumnsSelectComponent.onColumnSelectionChange.subscribe(
        (columnGroups: TableColumnGroup[]) => {
          this.setTableComponentColumnGroups(columnGroups);
          if (!this.disableLocalStorageForSelectedColumns) {
            this.localStorageService.setData(selectedColumnsId, columnGroups);
          }
        }
      );

      this.subscriptionList.push(columnsSelectSubscription);
    }
  }

  /**
   * If renderLeafView is true, modify columnGroups to contain columns - columnToReplace + leaf view columns
   * If renderLeafView is false, modify columnGroups to contain columns - leaf view columns +
   *    columnToReplace
   */
  private setupFlatViewSelect(): void {
    if (
      this.tableFlatViewSelectComponent &&
      !this.tableFlatViewSelectComponent.exportFunctionality
    ) {
      const flatViewMetaDataSubscription = this.tableComponent._flatViewMetaData$.subscribe(
        flatViewMetaData => {
          this.tableFlatViewSelectComponent.selectedOption = flatViewMetaData?.renderLeafView
            ? 'leaves'
            : 'nodes';
        }
      );

      const flatViewSelectComponentSubscription = this.tableFlatViewSelectComponent.selectedOptionChange
        .pipe(withLatestFrom(this.tableComponent._flatViewMetaData$))
        .subscribe(([option, flatViewMetaData]: [FlatViewOption, FlatViewMetaData]) => {
          const renderFlatView = option === 'leaves';

          this.tableComponent.flatViewMetaData = {
            ...flatViewMetaData,
            renderLeafView: renderFlatView
          };

          if (renderFlatView) {
            this.tableColumnsSelectComponent.columnGroups = transformColumnsToFlatView(
              this.tableColumnsSelectComponent.columnGroups,
              flatViewMetaData
            );
          } else {
            this.tableColumnsSelectComponent.columnGroups = transformColumnsFromFlatView(
              this.tableColumnsSelectComponent.columnGroups,
              flatViewMetaData
            );
          }

          this.setupColumnSelect();
        });

      this.subscriptionList.push(flatViewMetaDataSubscription, flatViewSelectComponentSubscription);
    }
  }

  private setTableComponentColumnGroups(columnGroups: TableColumnGroup[]) {
    const filteredColumnGroups = columnGroups.reduce((acc, group) => {
      const filteredColumns = group.columns.filter(
        column => column.preventVisibilityToggling || column.isVisible
      );
      return filteredColumns.length === 0 ? acc : [...acc, { ...group, columns: filteredColumns }];
    }, []);
    this.tableComponent.columnGroups = filteredColumnGroups;
  }

  private clearSubscriptions(): void {
    this.subscriptionList.forEach(subscription => subscription?.unsubscribe());
  }
}
