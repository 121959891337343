import { LegacyDefinition } from '@opengamma/ui';

/**
 *
 * Utility function to statically retrieve the screenId from the Carma url path
 *
 * @param localProductDefinition
 * @param urlPath e.g. margin/track/attribution
 * @returns Screen Id
 */
export const findScreenByUrlPath = (
  localProductDefinition: LegacyDefinition,
  urlPath: string
): string => {
  for (const productKey in localProductDefinition) {
    const product = localProductDefinition[productKey];
    for (const moduleKey in product.modules) {
      const module = product.modules[moduleKey];
      for (const screenKey in module.screens) {
        const screen = module.screens[screenKey];
        if (
          (screen.baseApiPath && screen.baseApiPath.endsWith(urlPath)) ||
          urlPath.includes(`${product.urlPath}/${module.urlPath}/${screen.urlPath}`)
        ) {
          return screenKey;
        }
      }
    }
  }

  return null;
};
