/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Location } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { carmaApiPath } from 'environments/environment.utils';

/** Service to retrive and update JSCC consent */
@Injectable()
export class JSCCConsentService {
  private apiUrl = carmaApiPath();

  constructor(private client: HttpClient) {}

  getDoesUserRequireJsccTermsConsent(): Observable<any> {
    return this.client.get(Location.joinWithSlash(this.apiUrl, 'user/jscc-terms-consent'));
  }

  updateJsccTermsConsent(accepted: boolean): Observable<any> {
    return this.client.patch(Location.joinWithSlash(this.apiUrl, 'user/jscc-terms-consent'), {
      accepted
    });
  }
}
