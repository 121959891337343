/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { Component, Input } from '@angular/core';
import { CurrencyValue, Subtext } from '@opengamma/ui';

@Component({
  selector: 'og-currency-value-cell',
  templateUrl: './currency-value-cell.component.html',
  styleUrls: ['./currency-value-cell.component.scss']
})
export class CurrencyValueCellComponent {
  /** The bound / order of magnitude to display the currency value with. */
  private _bound: number;
  /** The decimal format for the currency value. */
  private _decimalPipeArgs = '1.2-2';

  /** The placeholder to display if value is undefined */
  undefinedValuePlaceholder = '-';

  @Input() value: CurrencyValue;

  @Input() subtext: Subtext;

  @Input() alignment: 'LEFT' | 'CENTER' | 'RIGHT' = 'CENTER';

  @Input()
  set bound(bound: number) {
    if (!bound || bound < 1) {
      this._bound = 1;
    } else {
      this._bound = bound;
    }
  }
  get bound(): number {
    return this._bound;
  }

  @Input()
  set decimalPipeArgs(decimalPipeArgs: string) {
    if (!decimalPipeArgs) {
      this._decimalPipeArgs = '1.2-2';
    } else {
      this._decimalPipeArgs = decimalPipeArgs;
    }
  }
  get decimalPipeArgs(): string {
    return this._decimalPipeArgs;
  }
}
