<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div fxLayout="row" fxLayoutGap="4px">
  <og-change-currency-value
    [currency]="value.amount"
    [invertSignColor]="value.signed"
  ></og-change-currency-value>
  <div *ngIf="value.percentage">({{ value.percentage | percent: '1.0-0' }})</div>
</div>
