/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { CsvExporterService, CsvExportableRow } from './csv-exporter.service';

/**
 * Provides the ability to generate CSV files from tree-table structures displaying data in a
 * flat table format.
 */

@Injectable()
export class CsvExporterFlatService extends CsvExporterService {
  constructor() {
    super();
  }

  formatRow(row: CsvExportableRow): string {
    const result = []
      .concat(row.groupings || [])
      .concat(row.values)
      .map(this.formatItem)
      .join(',')
      .concat('\n');

    if (row.children && row.children.length) {
      return result.concat(row.children.map(child => this.formatRow(child)).join(''));
    }

    return result;
  }
}
