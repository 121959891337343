<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div class="items" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="left center">
  <ng-container *ngFor="let screen of screens">
    <a
      id="og-screen-link--{{ moduleUrl }}--{{ screen.urlPath }}"
      class="item"
      [class.item--locked]="screen.isLocked"
      [routerLink]="getScreenRoute(screen.urlPath)"
      [routerLinkActive]="!screen.urlPath ? [] : ['item--active']"
    >
      {{ screen.screenName }}

      <og-icon
        class="item__lock"
        *ngIf="screen.isInternal"
        [matTooltip]="'This screen is internal and visible only to Opengamma users'"
        [matTooltipPosition]="'below'"
        name="eye-off"
        [size]="IconSize.XLarge"
      ></og-icon>
      <og-icon
        *ngIf="screen.isLocked"
        class="item__lock"
        name="lock"
        [size]="IconSize.XLarge"
      ></og-icon>
    </a>
  </ng-container>
  <ng-content></ng-content>
</div>
