/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { UserDetails, loader } from '@opengamma/ui';
import { Action, props, createAction } from '@ngrx/store';
import { PermissionsMetadata } from './user-auth.state';

export const updateMFA = loader<null>('User Auth', 'Update MFA');
export const login = loader<UserDetails>('User Auth', 'Login');
export const permissions = loader<PermissionsMetadata>('User Auth', 'Permissions');

export const getRequireJSCCTermsConsent = loader<any, { message: string }, any>(
  'T&C',
  'Require JSCC Terms consent'
);

export const consentToJSCCTerms = loader<boolean, { message: string }, boolean>(
  'T&C',
  'Consent to JSCC Terms'
);

export const getDemoTenantStatus = loader<boolean>('User Auth', 'Demo Tenant Status');

export const setMfa = createAction('Update MFA', props<{ isEnabled: boolean }>());

export const recordLastScreenVisitedInModule = createAction(
  'Record Screen in Module',
  props<{ url: string }>()
);

export const ROUTES_CREATED = '[Routing] Routes have been created';

/**
 * Dispatched when the routes have been created for the first time based on user permissions.
 * Used to trigger the app start in core.module. It's imperative that the app does not start
 * before routes have been dynamically created, as the user will inevitably try to access a route
 * that does not exist.
 *
 * Uses ngrx 7 syntax as newer actions cannot be listened to in the app initializer.
 */
export class RoutesCreated implements Action {
  readonly type = ROUTES_CREATED;
}
