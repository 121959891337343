/*
 * Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

// Spread doesn't work on generics
/* eslint-disable prefer-object-spread */

import { LoadableState } from '@opengamma/ui';
import { HttpErrorResponse } from '@angular/common/http';

/**
 * Sets the given loadable item of the given state to loading.
 *
 * @param state the state
 * @param loadableItemKey the key of the loadable item
 * @param maintainData whether to maintain the data, defaults to false
 *
 * @return the new state
 */
export function withLoading<T, S extends { [K1 in K]?: LoadableState<T> }, K extends keyof S>(
  state: S,
  loadableItemKey: K,
  maintainData = false
): S {
  // Any is safe here as it's guarded by the type parameters on the inputs
  const copy: S = Object.assign({}, state);
  copy[loadableItemKey] = {
    loading: true,
    data: maintainData ? copy[loadableItemKey]?.data : undefined
  } as S[K];
  return copy;
}

/**
 * Sets the given loadable item of the given state to the given value.
 *
 * @param state the state
 * @param loadableItemKey the key of the loadable item
 * @param data the data
 *
 * @return the new state
 */
export function withData<T, S extends { [K1 in K]?: LoadableState<T> }, K extends keyof S>(
  state: S,
  loadableItemKey: K,
  data: T
): S {
  // Any is safe here as it's guarded by the type parameters on the inputs
  const copy: S = Object.assign({}, state);
  copy[loadableItemKey] = { loading: false, error: undefined, data } as S[K];
  return copy;
}

/**
 * Sets the given loadable item of the given state to the given error.
 *
 * @param state the state
 * @param loadableItemKey the key of the loadable item
 * @param error the error
 * @param maintainData whether to maintain the data, defaults to false
 * @param errorResponse the error response
 *
 * @return the new state
 */
export function withError<T, S extends { [K1 in K]?: LoadableState<T> }, K extends keyof S>(
  state: S,
  loadableItemKey: K,
  error: string,
  maintainData = false,
  errorResponse?: HttpErrorResponse
): S {
  // Any is safe here as it's guarded by the type parameters on the inputs
  const copy: S = Object.assign({}, state);
  copy[loadableItemKey] = {
    loading: false,
    data: maintainData ? copy[loadableItemKey]?.data : undefined,
    error,
    errorResponse
  } as S[K];
  return copy;
}

/**
 * Sets the given loadable item of the given state to the given error response.
 * <p>
 * Delegates to withError.
 *
 * @param state the state
 * @param loadableItemKey the key of the loadable item
 * @param errorResponse the error response
 *
 * @return the new state
 */
export function withErrorResponse<T, S extends { [K1 in K]?: LoadableState<T> }, K extends keyof S>(
  state: S,
  loadableItemKey: K,
  errorResponse?: HttpErrorResponse
): S {
  return withError(state, loadableItemKey, errorResponse.message, false, errorResponse);
}
