/*
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { Component, Input } from '@angular/core';

@Component({
  selector: 'og-number-value-cell',
  templateUrl: './number-value-cell.component.html'
})
export class NumberValueCellComponent {
  @Input() value: number;

  _decimalPipes = '1.0-0';

  @Input() set decimalPlaces(decimalPlaces: number) {
    if (decimalPlaces) {
      this._decimalPipes = `1.${decimalPlaces}-${decimalPlaces}`;
    }
  }
}
