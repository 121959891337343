<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

@if (!areCardsLoading) {
<og-card>
  <div title-bar>{{ title }}</div>
  @if (cards) {
  <div class="cards__container" fxFlex="auto" fxLayout="column">
    <section>
      @for (card of cards; track card) {
      <og-summary-card
        [isSelected]="card.id === selectedCardId"
        [title]="card.name"
        (onRightArrowClick)="selectCardTrigger(card)"
      >
        <og-summary-card-metrics [value]="card.value"></og-summary-card-metrics>
      </og-summary-card>
      }
    </section>
  </div>
  }
</og-card>
} @else {
<og-spinner class="spinner" align="top"></og-spinner>
}
