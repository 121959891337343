/*
 * Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

// Default dev environment, using the dev auth service and dev API.
import { Environment } from 'environments/environment.model';

export const commonEnvironmentProperties: Partial<Environment> = {
  treasury: {
    viewMenuPollingIntervalInMillis: 1000,
    viewContentsPollingIntervalInMillis: 1000
  }
};
