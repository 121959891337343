/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from 'app/shared/store/reducers';
import { FromMarginScreensCommonActions } from '@opengamma/ui';

@Component({
  selector: 'og-table-export-button',
  templateUrl: './table-export-button.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableExportButtonComponent {
  @Input() featureName: string;
  @Input() endpointPath = 'table';
  @Input() selectedValuePath: string[] = ['selectedValues', 'groupings'];

  constructor(private store: Store<State>) {}

  onExportTableToCSVButtonClicked(): void {
    this.store.dispatch(
      FromMarginScreensCommonActions.exportTableDataToCsv({
        payload: {
          featureName: this.featureName,
          tablePath: this.endpointPath,
          groupingsPath: this.selectedValuePath
        }
      })
    );
  }
}
