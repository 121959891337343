/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnChanges,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';

/** Represents a range slider component. */
@Component({
  selector: 'og-range-slider',
  templateUrl: './range-slider.component.html',
  styleUrls: ['./range-slider.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class RangeSliderComponent implements OnInit, OnChanges {
  @ViewChild('bar', { static: true }) bar: ElementRef;

  /** The theme. */
  @Input() theme: 'green' | string = 'green';

  /** The minimum value on the slider. */
  @Input() min = 0;

  /** The maximum value on the slider. */
  @Input() max: number;

  /** True if the minimum and the maximum should be adjustable by the user. */
  @Input() isMinAdjustable = false;

  /** The minimum allowed difference between the min and max. */
  @Input() minDifference: number;

  // -------------------------------------------------------------------------
  /**
   * The selected minimum value.
   *
   * Ignored if {@link isMinAdjustable} is false.
   */
  @Input() selectedMin: number;
  /** Outputs when the user changes the minimum value. */
  @Output() selectedMinChanged = new EventEmitter<number>();

  /** The selected max value. */
  @Input() selectedMax: number;
  /** Outputs when the user changes the maximum value. */
  @Output() selectedMaxChanged = new EventEmitter<number>();

  // -------------------------------------------------------------------------
  // The positions of the teardrops and bars, used for styling
  minOffsetPx: string;
  maxOffsetPx: string;
  barWidthPx: string;

  // X position
  previousMousePosition: number;

  // True if the respective teardrops are being dragged
  draggingMin = false;
  draggingMax = false;

  // -------------------------------------------------------------------------
  constructor(private changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit() {
    if (!this.isMinAdjustable) {
      this.selectedMin = this.min;
    }
    if (!this.minDifference) {
      this.minDifference = this.isMinAdjustable ? 10 : 0;
    }
    this.updateComponent();
  }

  ngOnChanges() {
    this.updateComponent();
  }

  // -------------------------------------------------------------------------
  @HostListener('document:mousemove', ['$event'])
  mouseMove(event) {
    if (!this.draggingMin && !this.draggingMax) {
      return;
    }

    if (this.draggingMin) {
      const minValue = this.selectedMax - this.minDifference;
      this.selectedMin = Math.min(this.calculateValue(event.pageX, this.selectedMin), minValue);
    } /* if (this.draggingMax) */ else {
      const maxValue = this.selectedMin + this.minDifference;
      this.selectedMax = Math.max(this.calculateValue(event.pageX, this.selectedMax), maxValue);
    }

    this.previousMousePosition = event.pageX;
    this.updateComponent();
  }

  @HostListener('document:mouseup')
  mouseUp() {
    if (this.draggingMin) {
      this.selectedMinChanged.emit(this.selectedMin);
      this.draggingMin = false;
    }
    if (this.draggingMax) {
      this.selectedMaxChanged.emit(this.selectedMax);
      this.draggingMax = false;
    }
  }

  // -------------------------------------------------------------------------

  startDraggingMin(event) {
    this.previousMousePosition = event.pageX;
    this.draggingMin = true;
  }

  startDraggingMax(event) {
    this.previousMousePosition = event.pageX;
    this.draggingMax = true;
  }

  // -------------------------------------------------------------------------
  private get barOffsetWidth(): number {
    return this.bar.nativeElement.offsetWidth;
  }

  // returns the new value on the range slider, calculating it as a ratio of the mouse position
  // to the width and min/max values of the bar
  private calculateValue(mousePosition: number, previousValue: number) {
    const newValue =
      previousValue +
      ((mousePosition - this.previousMousePosition) / this.barOffsetWidth) * (this.max - this.min);
    return Math.max(Math.min(newValue, this.max), this.min);
  }

  // refreshes the component
  private updateComponent() {
    const min = this.barOffsetWidth * ((this.selectedMin - this.min) / (this.max - this.min));
    const max = this.barOffsetWidth * ((this.selectedMax - this.min) / (this.max - this.min));

    this.minOffsetPx = min + 'px';
    this.maxOffsetPx = max + 'px';
    this.barWidthPx = max - min + 'px';

    this.changeDetectorRef.detectChanges();
  }
}
