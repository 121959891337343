<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div class="top">
  <og-spinner class="spinner" size="small"></og-spinner>
  <span class="text text--{{ theme }}">{{ text }}</span>
</div>

<div #bar class="background-bar" [style.background-color]="backgroundColor"></div>
<div class="loaded-bar" [style.width]="barWidthPx" [style.background-color]="color"></div>
