/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { GAMMA, TUNDORA } from 'app/shared/utils/colors';
import * as Highcharts from 'highcharts/highstock';
import { Color } from 'highcharts';

export const navigator = (color = GAMMA): Highcharts.Options => ({
  scrollbar: {
    height: 0,
    enabled: true
  },
  navigator: {
    maskFill: new Color(color).setOpacity(0.15).get(),
    enabled: true,
    yAxis: {
      visible: false
    },
    xAxis: {
      visible: false
    },
    height: 40,
    handles: {
      borderColor: new Color(color).get(),
      backgroundColor: new Color(color).get(),
      width: 2,
      height: 40
    },
    series: {
      fillOpacity: 0.05,
      lineWidth: 1,
      color: new Color(color).setOpacity(0.6).get(),
      fillColor: new Color(color).setOpacity(0.05).get()
    },
    outlineColor: TUNDORA
  }
});
