<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div class="cell cell--{{ options.textAlign }}-align cell--{{ options.cellPadding }}">
  <og-arrow
    *ngIf="options.arrows && !!arrowValue"
    [value]="arrowValue"
    [invertColors]="options.arrows === 'up-red'"
  ></og-arrow>

  <div *ngIf="displayPlaceholder" class="cell__placeholder"></div>

  <span
    *ngIf="isExpandable && isExpanded"
    class="cell__expandable-icon"
    [style.margin-left]="4 + level * 14 + 'px'"
  >
    <og-icon name="minus" [stroke]="IconStroke.XThick"></og-icon>
  </span>
  <span
    *ngIf="isExpandable && !isExpanded"
    class="cell__expandable-icon"
    [style.margin-left]="4 + level * 14 + 'px'"
  >
    <og-icon name="plus" [stroke]="IconStroke.XThick"></og-icon>
  </span>

  <div
    class="cell__clear"
    *ngIf="isValueChanged(value, lastEditedValue)"
    (click)="onClearClick($event)"
  >
    <og-icon name="x"></og-icon>
  </div>
  <span
    class="cell__value"
    [class.editable]="options.editable"
    [class.cell__value--with-subtext]="subtext"
    [class.cell__value--with-subtext-right]="subtext && subtext.placement === 'right'"
    [ngStyle]="computeCustomCellStyles()"
    [ngSwitch]="type"
    *ngIf="!isEditing"
  >
    <span *ngIf="isValueUndefinedOrEmpty() && !(type === 'icon' || type === 'expandableIcon')">
      -
    </span>

    <ng-container *ngSwitchCase="'currency'">
      <ng-container *ngIf="lastEditedValue && lastEditedValue.price !== undefined">
        <div
          class="currency__value"
          [matTooltip]="
            getCurrencySymbol(lastEditedValue) + (lastEditedValue.price | number: '1.0-0')
          "
          [matTooltipPosition]="'right'"
        >
          {{ lastEditedValue | currencyValue: true:'1.2-2' }}
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'autoBoundCurrency'">
      <ng-container *ngIf="lastEditedValue && lastEditedValue.price !== undefined">
        <div
          class="currency__value"
          [matTooltip]="
            getCurrencySymbol(lastEditedValue) + (lastEditedValue.price | number: '1.0-0')
          "
          [matTooltipPosition]="'right'"
        >
          {{ lastEditedValue | boundedCurrencyValue: bound:'1.2-2' }}
        </div>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      {{ parseDate(lastEditedValue) | date: 'dd/MM/yyyy' }}
    </ng-container>

    <ng-container *ngSwitchCase="'number'">
      {{ lastEditedValue | number: '1.2-2' }}
    </ng-container>

    <ng-container *ngSwitchCase="'integer'">
      {{ lastEditedValue | number: '1.0-0' }}
    </ng-container>
    <ng-container *ngSwitchCase="'autoBoundNumber'">
      <ng-container *ngIf="isValueNumeric(); else plainValue">
        {{ lastEditedValue | boundedShortNumber: bound:'1.2-2' }}
      </ng-container>
      <ng-template #plainValue>
        {{ lastEditedValue }}
      </ng-template>
    </ng-container>
    <ng-container *ngSwitchCase="'fixedBoundNumber'">
      <ng-container *ngIf="!!lastEditedValue && lastEditedValue.value">
        <div
          class="currency__value"
          [matTooltip]="lastEditedValue.value | number: '1.0-0'"
          [matTooltipPosition]="'right'"
        >
          {{ lastEditedValue.value | boundedShortNumber: bound:'1.2-2' }}
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'fixedBoundCurrency'">
      <ng-container *ngIf="!!lastEditedValue && lastEditedValue.value">
        <div
          class="currency__value"
          [matTooltip]="lastEditedValue.value | currencyValue: false:'1.0-0'"
          [matTooltipPosition]="'right'"
        >
          {{ lastEditedValue.value | boundedCurrencyValue: bound:'1.2-2' }}
        </div>
      </ng-container>
    </ng-container>
    <ng-container *ngSwitchCase="'percent'">
      {{ lastEditedValue | percent: '1.2-2' }}
    </ng-container>

    <ng-container *ngSwitchCase="'text'">
      {{ lastEditedValue }}
    </ng-container>

    <ng-container *ngSwitchCase="'textList'">
      <div class="value__list">
        <span *ngFor="let value of lastEditedValue">{{ value }}</span>
      </div>
    </ng-container>

    <ng-container *ngSwitchCase="'icon'">
      <ng-container *ngIf="!!lastEditedValue && !!lastEditedValue.name">
        <og-icon
          *ngIf="lastEditedValue"
          [size]="lastEditedValue.size"
          [name]="lastEditedValue.name"
        ></og-icon>
      </ng-container>
    </ng-container>

    <ng-container *ngSwitchCase="'expandableIcon'">
      <og-icon *ngIf="lastEditedValue && !isExpanded" [name]="lastEditedValue.default"></og-icon>
      <og-icon *ngIf="lastEditedValue && isExpanded" [name]="lastEditedValue.expanded"></og-icon>
    </ng-container>

    <ng-container *ngSwitchCase="'barChart'">
      <div class="cell__bar-chart">
        <div class="bar-chart__legend">
          {{ lastEditedValue.barWidth + '%' }}
        </div>
        <div class="bar-chart__container">
          <div
            class="bar-chart__bar"
            [class.bar-chart__bar--expanded]="isExpanded"
            [style.width]="lastEditedValue.barWidth + '%'"
            [style.backgroundColor]="lastEditedValue.barColor"
          ></div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngSwitchCase="'directionalBarChart'">
      <div class="cell__bar-chart">
        <div fxFlex="70px" class="bar-chart__legend">
          <span class="bar-chart__sign--{{ lastEditedValue.direction }}"></span>
          {{ lastEditedValue.value + '%' }}
        </div>
        <div class="bar-chart__container bar-chart__container--left">
          <div
            class="bar-chart__bar bar-chart__bar--left"
            [class.bar-chart__bar--expanded]="isExpanded"
            [style.width]="getDirectionalBarWidth(lastEditedValue, 'left')"
          ></div>
        </div>
        <div fxFlex="1px" class="bar-chart__divider"></div>
        <div class="bar-chart__container bar-chart__container--right">
          <div
            *ngIf="lastEditedValue.direction === 'right'"
            class="bar-chart__bar bar-chart__bar--right"
            [class.bar-chart__bar--expanded]="isExpanded"
            [style.width]="getDirectionalBarWidth(lastEditedValue, 'right')"
          ></div>
        </div>
      </div>
    </ng-container>
    <span
      *ngIf="subtext"
      class="cell__subtext"
      [class.cell__subtext--right]="subtext.placement === 'right'"
    >
      <span [matTooltip]="subtext.tooltip" [matTooltipPosition]="'right'">
        {{ subtext.text }}
      </span>
    </span>
  </span>

  <span class="cell__value" [ngSwitch]="type" *ngIf="isEditing">
    <ng-container *ngSwitchCase="'currency'">
      {{ getCurrencySymbol(value) }}
      <input
        #input
        autofocus
        type="number"
        [(ngModel)]="editedValue.price"
        (blur)="onBlur()"
        (keyup)="onKeyUp($event)"
      />
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      <input
        #input
        autofocus
        type="text"
        [(ngModel)]="editedValue"
        (blur)="onBlur()"
        (keyup)="onKeyUp($event)"
      />
    </ng-container>

    <ng-container *ngSwitchCase="'number'">
      <input
        #input
        autofocus
        type="number"
        [(ngModel)]="editedValue"
        (blur)="onBlur()"
        (keyup)="onKeyUp($event)"
      />
    </ng-container>

    <ng-container *ngSwitchCase="'integer'">
      <input
        #input
        autofocus
        type="integer"
        [(ngModel)]="editedValue"
        (blur)="onBlur()"
        (keyup)="onKeyUp($event)"
      />
    </ng-container>

    <ng-container *ngSwitchCase="'percent'">
      <input
        #input
        autofocus
        type="number"
        [ngModel]="editedValue * 100"
        (change)="onEditPercentage($event)"
        (blur)="onBlur()"
        (keyup)="onKeyUp($event)"
      />
      %
    </ng-container>

    <ng-container *ngSwitchCase="'text'">
      <input
        #input
        type="text"
        [(ngModel)]="editedValue"
        (blur)="onBlur()"
        (keyup)="onKeyUp($event)"
      />
    </ng-container>

    <ng-container *ngSwitchCase="'icon'">
      <input
        #input
        type="text"
        [(ngModel)]="editedValue"
        (blur)="onBlur()"
        (keyup)="onKeyUp($event)"
      />
    </ng-container>
  </span>

  <div
    class="cell__original-value"
    [ngSwitch]="type"
    *ngIf="isEditing || isValueChanged(value, lastEditedValue)"
  >
    <ng-container *ngSwitchCase="'currency'">
      Original Value: {{ value | currencyValue: true:'1.0-2' }}
    </ng-container>

    <ng-container *ngSwitchCase="'date'">
      Original Value: {{ value | date: 'dd/MM/yyyy' }}
    </ng-container>

    <ng-container *ngSwitchCase="'number'">
      Original Value: {{ value | number: '1.0-2' }}
    </ng-container>

    <ng-container *ngSwitchCase="'integer'">
      Original Value: {{ value | number: '1.0-0' }}
    </ng-container>

    <ng-container *ngSwitchCase="'percent'">
      Original Value: {{ value | percent: '1.0-2' }}
    </ng-container>

    <ng-container *ngSwitchCase="'text'">Original Value: {{ value }}</ng-container>

    <ng-container *ngSwitchCase="'icon'">
      Original Value:
      <og-icon *ngIf="value" [name]="value"></og-icon>
    </ng-container>
  </div>
</div>
