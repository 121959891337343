/*
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { CurrencyValue } from '@opengamma/ui';
import { TooltipFormatterContextObject } from 'highcharts';

export interface FormatterMetaData {
  [metaData: string]: any;
}

/**
 * Acts as a shared tooltip formatter, for highcharts charts
 *
 * @param point The point on the chart that the user is currently moused over
 * @param isoCode The isoCode to format by
 */
export function currencyValueTooltipFormatter(
  point: TooltipFormatterContextObject,
  isoCode: string
): string {
  return `${point.series.name}: ${sharedGetCurrencyDescription({ price: point.y, isoCode })}`;
}

/**
 * Acts as a shared currency "pipe"
 *
 * @param currencyValue The currency value to process
 */
export function sharedGetCurrencyDescription({ price, isoCode }: CurrencyValue): string {
  return `${price.toLocaleString(
    'en',
    isoCode && {
      style: 'currency',
      currency: isoCode
    }
  )}`;
}
