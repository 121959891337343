/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { OGHighchartDirective } from 'app/shared/components/charts/modern/highchart/highchart.component';
import * as _ from 'lodash';
import { defaultPieOptions } from 'app/shared/components/charts/modern/donut-chart/options/pie.highchart-options';

/** An item for the donut chart. */
export interface DonutItem {
  /** The value of the item. */
  value: number;
  /** The color of the segment. */
  color: string;
  /** The name of the item, optional. */
  name?: string;
  /** The display value of the item, defaulted to the value. */
  displayValue?: string;
}

@Component({
  selector: 'og-donut',
  templateUrl: '../highchart/highchart.component.html',
  styleUrls: ['../highchart/highchart.component.scss']
})
export class DonutChartComponent extends OGHighchartDirective implements OnChanges {
  /** The items for the donut. */
  @Input() items: DonutItem[];
  /** The items for the donut. */
  @Input() hideLegend = false;

  ngOnChanges(changes: SimpleChanges): void {
    this.options = _.merge(
      this.options,
      defaultPieOptions,
      {
        legend: {
          enabled: !this.hideLegend
        }
      },
      this.createData()
    );
    super.ngOnChanges(changes);
  }

  private createData(): Highcharts.Options {
    return {
      series: [
        {
          type: 'pie',
          data: this.items.map(point => ({
            y: point.value,
            name: point.name,
            prettyValue: point.displayValue || point.value,
            color: point.color
          }))
        }
      ]
    };
  }
}
