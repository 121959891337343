<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div class="background" (click)="closeModal()"></div>

<div class="modal">
  @if (displayCloseIcon) {
  <og-icon
    class="cross-icon"
    name="x"
    [size]="IconSize.XLarge"
    [stroke]="IconStroke.XThick"
    (click)="closeModal()"
  ></og-icon>
  }
  <ng-content></ng-content>
</div>
