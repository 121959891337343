<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

@if (columnGroups) {
<og-select
  selectPlacement="bottom-start"
  [multiple]="true"
  [selectedOptions]="getSelectedColumns()"
  (selectionChange)="onColumnSelection($event)"
  class="select"
>
  <og-select-label>
    <og-icon name="columns-3"></og-icon>
  </og-select-label>
  <og-select-trigger class="select__trigger">
    {{ getSelectedColumnsText() }}
  </og-select-trigger>
  <og-select-panel class="select__panel">
    <og-select-panel-title>
      Show or hide table columns
    </og-select-panel-title>
    @if (enableSelectAllOption) {
    <og-select-option
      (onSelect)="onSelectAllColumns()"
      [selected]="areAllColumnsSelected()"
      [detached]="true"
    >
      Select all
    </og-select-option>
    } @if (enableSelectNoneOption) {
    <og-select-option
      (onSelect)="onDeselectAllColumns()"
      [selected]="areAllColumnsDeselected()"
      [detached]="true"
    >
      Select none
    </og-select-option>
    } @for (group of _toggleableColumnGroups; track trackColumnGroups($index, group)) { @if
    (!group.label) { @for (column of group.columns; track trackColumns($index, column)) {
    <og-select-option [value]="column" class="select__option">
      <og-checkbox-noop [checked]="isColumnSelected(column)">
        {{ column.label }}
      </og-checkbox-noop>
    </og-select-option>
    } } @else {
    <og-select-option
      (onSelect)="onGroupSelected(group)"
      [selected]="isGroupOptionSelected(group)"
      [detached]="true"
      class="select__option"
    >
      <og-checkbox-noop [checked]="isGroupOptionSelected(group)">
        {{ group.label }}
      </og-checkbox-noop>
    </og-select-option>
    @for (column of group.columns; track trackColumns($index, column)) {
    <og-select-option [value]="column" class="select__option select__option--indented">
      <og-checkbox-noop [checked]="isColumnSelected(column)">
        {{ column.label }}
      </og-checkbox-noop>
    </og-select-option>
    } } }
  </og-select-panel>
</og-select>
}
