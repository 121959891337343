/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

import { DonutPoint, getDonutOptions } from './simple-donut-util';

@Component({
  selector: 'og-simple-donut',
  templateUrl: './simple-donut.component.html',
  styleUrls: ['./simple-donut.component.scss']
})
export class SimpleDonutComponent implements OnChanges {
  @Input() color;
  @Input() points: DonutPoint[];
  @Input() mainTitle = '';
  @Input() secondaryTitle = '';

  /** The minimum size in px of the graph. */
  @Input() minSize?: number;

  options: any;

  ngOnChanges(changes: SimpleChanges) {
    if (!this.points) {
      return;
    }
    this.options = getDonutOptions({
      color: this.color,
      points: this.points,
      mainTitle: this.mainTitle,
      secondaryTitle: this.secondaryTitle,
      minSize: this.minSize
    });
  }
}
