<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

@if (userDetails) {
<div class="main">
  <img class="image" src="{{ imageUrl }}" />
</div>
}
