<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div
  class="main-container main-container--{{ theme$ | async }}"
  fxLayout="column"
  fxFlexAlign="start"
  fxFlex="grow"
>
  <ng-container *ngIf="areAllNeededApisCompleted$ | async; else pageSpinner">
    <router-outlet></router-outlet>
  </ng-container>
</div>

<ng-template #pageSpinner>
  <div class="spinner-container">
    <og-spinner></og-spinner>
  </div>
</ng-template>
