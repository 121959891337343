<og-header></og-header>
<og-screen-status-header></og-screen-status-header>
<og-layout-container>
  <og-spinner
    display="overlay"
    *ngIf="isScreenStatusLoading$ | async; else screenContent"
  ></og-spinner>
  <ng-template #screenContent>
    <div class="main__content">
      <router-outlet *ngIf="isDataAvailable$ | async; else noDataAvailable"></router-outlet>
    </div>
  </ng-template>
</og-layout-container>
<ng-template #noDataAvailable>
  <og-placeholder-icon
    label="No results available for the current selected date."
  ></og-placeholder-icon>
</ng-template>
