<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
@if (searchableColumnIndexes.length > 0) {
<div class="filter__container">
  <og-icon class="container__icon" name="search"></og-icon>
  <input
    type="text"
    class="container__filter"
    placeholder="Search"
    [(ngModel)]="filterTerm"
    (keyup)="updateFilter($event)"
  />
</div>
} @if (formattedColumnDescriptions.length > 0) {
<div class="descriptions">
  @for (description of formattedColumnDescriptions; track description) {
  <div
    class="descriptions__entry"
    [class.descriptions__entry--no-border]="!description.label"
    [ngStyle]="description.styles"
  >
    <span>{{ description.label }}</span>
  </div>
  }
</div>
}

<div #table class="table table--{{ theme }}">
  <!--Group of columns-->
  @for (group of formattedData; track group; let i = $index) {
  <div
    class="table__group table__group--{{ i }}"
    [ngStyle]="getColumnGroupStyles(group)"
    [class.table__group--expandable]="isExpandable()"
  >
    @if (group.label) {
    <div
      class="group__name group__name--{{ theme }}"
      [ngStyle]="getCustomColumnHeaderStyles(group.groupLabelOptions)"
      [class.group__name--sticky]="stickyHeader"
    >
      {{ group.label }}
    </div>
    }
    <div class="group__columns">
      <!--Column in group-->
      @for (column of group.columns; track column; let columnIndex = $index) {
      <div
        class="column column--{{ theme }}"
        [class.column--no-right-border]="
          columnIndex === group.columns.length - 1 && i === formattedData.length - 1
        "
        [ngStyle]="getColumnGroupWidth(column.headerOptions)"
      >
        <div
          class="column__header column__header--{{ theme }}"
          [class.column__header--more-padding]="areAllGroupsUnlabelled()"
          [class.column__header--sortable]="column.isSortable"
          [class.column__header--sticky]="stickyHeader"
          [class.column__header--sticky-grouped]="stickyHeader && group.label"
          [ngStyle]="getCustomColumnHeaderStyles(column.headerOptions)"
          (click)="column.isSortable && sortColumn(column)"
        >
          <div class="header__value header__value--{{ customHeaderHeight }}">
            <div class="value__contents">
              <span>{{ column.label }}</span>
              @if (column.isSortable) { @if (columnToSort?.index === column.index) {
              <og-icon
                class="header__icon header__icon--{{ columnSortingOrder }}"
                [name]="columnSortingOrder === 'asc' ? 'chevron-up' : 'chevron-down'"
              ></og-icon>
              } @if (columnToSort?.index !== column.index) {
              <og-icon class="header__icon" name="selector"></og-icon>
              } }
            </div>
            @if (column.icon) {
            <div class="header__icon header__icon--pull-right">
              @if (column.icon && column.icon.tooltip !== undefined) {
              <og-icon
                [name]="column.icon.name"
                [class.header__icon--clickable]="column.icon.onClick"
                [matTooltip]="column.icon.tooltip"
                (click)="column.icon.onClick ? column.icon.onClick(column.id) : undefined"
              ></og-icon>
              } @else {
              <og-icon
                [name]="column.icon.name"
                [class.header__icon--clickable]="column.icon.onClick"
                (click)="column.icon.onClick ? column.icon.onClick(column.id) : undefined"
              ></og-icon>
              }
            </div>
            }
          </div>
        </div>
        @for (row of filterRows(column); track row) {
        <og-magic-table-cell
          [column]="column"
          [editingColumn]="editingColumn"
          [row]="row"
          [editingRow]="editingRow"
          [expandedRowIndexes]="expandedIndexes"
          [displayedChildCount]="displayedChildCount"
          [theme]="theme"
          [class.cell--sticky]="stickyFirstRow && row.index === 1"
          (cellClicked)="onCellClick($event)"
          (valueEditedChange)="onValueEdited($event)"
          (displayMoreChildren)="onDisplayMoreChildren($event)"
        ></og-magic-table-cell>
        }
      </div>
      }
    </div>
  </div>
  }
</div>
