/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';
import { IconSize } from '@opengamma/ui';
import { CsvExporterService, CsvExportableData } from 'app/shared/services/csv-exporter.service';

@Component({
  selector: 'og-tree-csv-download-button',
  templateUrl: './tree-csv-download-button.component.html',
  styleUrls: ['./tree-csv-download-button.component.scss']
})
export class TreeCsvDownloadButtonComponent {
  /** The text on the button. */
  @Input() text = 'Export as CSV';
  /** The filename to be set to the exported csv */
  @Input() fileName: string;
  /** The custom theme to use */
  @Input() theme: 'dark' | 'light' = 'dark';
  /** The data to use for constructing the csv */
  @Input() data: CsvExportableData;
  /** Defines if the button should be disabled */
  @Input() disabled = false;

  IconSize = IconSize;

  constructor(private csvExporterService: CsvExporterService) {}

  onExportCsvClick() {
    this.csvExporterService.exportData(this.data, this.fileName);
  }
}
