<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div class="height--100" fxLayout="row" fxLayoutAlign="space-between center">
  <nav class="nav" fxLayoutAlign="left center">
    <div class="nav__start"></div>
    <ng-container *ngFor="let module of filterModulesByPosition('left', modules)">
      <ng-container *ngTemplateOutlet="moduleItem; context: { $implicit: module }"></ng-container>
    </ng-container>
    <div class="nav__end"></div>
  </nav>
  <nav class="nav" fxLayoutAlign="left center">
    <ng-container *ngFor="let module of filterModulesByPosition('right', modules)">
      <ng-container *ngTemplateOutlet="moduleItem; context: { $implicit: module }"></ng-container>
    </ng-container>
  </nav>
</div>

<ng-template #moduleItem let-module>
  <a
    id="og-module-link--{{ module.urlPath }}"
    class="nav__item nav__item--{{ theme }}"
    [routerLink]="getModuleRoute(module.urlPath)"
    [routerLinkActive]="'nav__item--active'"
    [class.nav__item__about]="module.moduleName === 'About'"
    [class.nav__item--locked]="module.isLocked"
    [class.nav__item--with-divider]="module.displayOptions?.hasDivider"
  >
    <og-icon
      *ngIf="module.displayOptions?.icon"
      [name]="module.displayOptions.icon"
      class="item__icon"
    ></og-icon>
    {{ module.moduleName }}
    <og-icon
      *ngIf="module.isLocked"
      class="item__lock"
      name="lock"
      [size]="IconSize.XLarge"
    ></og-icon>
  </a>
</ng-template>
