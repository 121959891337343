/*
 * Copyright (C) 2022 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { LOCAL_PRODUCT_DEFINITION } from '@opengamma/ui';
import { carmaApiPath } from 'environments/environment.utils';

// Returns the API paths of all screens which have multiple calculation runs enabled
export function getScreensWithSelectableRunsApiPaths(): string[] {
  return getScreensWithSelectableRuns().map(screen => screen.baseApiPath);
}

// Returns the URL paths of all screens which have multiple calculation runs enabled
export function getScreensWithSelectableRunsUrlPaths(): string[] {
  return getScreensWithSelectableRuns().map(screen => screen.urlPath);
}

function getScreensWithSelectableRuns() {
  const modules = LOCAL_PRODUCT_DEFINITION(carmaApiPath())['MARGIN'].modules;
  const screensWithSelectableRuns = [];

  Object.keys(modules).forEach(module => {
    const screens = modules[module].screens;
    Object.keys(screens).forEach(submodule => {
      const screen = screens[submodule];
      if (screen.hasMultipleRunsEnabled) {
        screensWithSelectableRuns.push(screen);
      }
    });
  });
  return screensWithSelectableRuns;
}

// Returns the `apiPath` property from screens that have it defined, so that the product definition
// is the single source of truth for these screens' API calls, instead of duplicating this
// information in each screen's data service
export function getScreenBaseApiPath(selectedModule: string, selectedScreen: string): string {
  const modules = LOCAL_PRODUCT_DEFINITION(carmaApiPath())['MARGIN'].modules;
  return modules[selectedModule]?.screens[selectedScreen]?.baseApiPath;
}
