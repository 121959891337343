/*
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { Component, Input } from '@angular/core';
import { Subtext } from '@opengamma/ui';

@Component({
  selector: 'og-percentage-value-cell',
  templateUrl: './percentage-value-cell.component.html'
})
export class PercentageValueCellComponent {
  /** The percentage value as a number, e.g., 0.67 === 67%. */
  @Input() value: number;

  /** The decimal format of the percentage value. */
  @Input() decimalPipeArgs = '1.1-1';

  @Input() subtext: Subtext;

  isValidPipeArgument(value: number): boolean {
    return isFinite(value);
  }
}
