/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { Component, Input, OnChanges } from '@angular/core';
import { IconSize } from '@opengamma/ui';

@Component({
  selector: 'og-placeholder-table',
  templateUrl: './placeholder-table.component.html',
  styleUrls: ['./placeholder-table.component.scss']
})
export class PlaceholderTableComponent implements OnChanges {
  /** The placeholder text shown under the placeholder icon. */
  @Input() placeholderText: string;
  /** The placeholder table row count. */
  @Input() rowCount: number;

  columns = new Array<number>(8);
  rows = new Array<number>(10);

  IconSize = IconSize;

  ngOnChanges() {
    if (!!this.rowCount) {
      this.rows = new Array<number>(this.rowCount);
    }
  }
}
