/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges } from '@angular/core';
import { UserDetails } from '@opengamma/ui';

@Component({
  selector: 'og-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnChanges {
  /** The user details to display. */
  @Input() userDetails: UserDetails;

  imageUrl: string;
  name: string;

  ngOnChanges() {
    if (!this.userDetails) {
      return;
    }
    this.imageUrl = this.userDetails.picture;
    this.name = this.userDetails.name;
  }
}
