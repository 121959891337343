<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div fxLayout="row">
  <span class="divider"></span>

  <span class="subtext" [matTooltip]="subtext?.tooltip" matTooltipPosition="right">
    {{ subtext?.text }}
    <ng-content></ng-content>
  </span>
</div>
