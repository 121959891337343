<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div class="items" fxLayout="row" fxLayoutGap="12px" fxLayoutAlign="left center">
  @for (screen of screens; track screen) {
  <a
    id="og-screen-link--{{ moduleUrl }}--{{ screen.urlPath }}"
    class="item"
    [class.item--locked]="screen.isLocked"
    [routerLink]="getScreenRoute(screen.urlPath)"
    [routerLinkActive]="!screen.urlPath ? [] : ['item--active']"
  >
    {{ screen.screenName }}
    @if (screen.isInternal) {
    <og-icon
      class="item__lock"
      [matTooltip]="'This screen is internal and visible only to Opengamma users'"
      [matTooltipPosition]="'below'"
      name="eye-off"
      [size]="IconSize.XLarge"
    ></og-icon>
    } @if (screen.isLocked) {
    <og-icon class="item__lock" name="lock" [size]="IconSize.XLarge"></og-icon>
    }
  </a>
  }
  <ng-content></ng-content>
</div>
