/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { NgxDatatableModule } from '@swimlane/ngx-datatable';
import { NoPermissionsComponent } from 'app/page-issues/no-permissions/no-permissions.component';

// Legacy
import { ArrowComponent } from 'app/shared/legacy-components/display-structures/arrow/arrow.component';
import { MagicTableValueComponent } from 'app/shared/legacy-components/display-structures/magic-table/value/magic-table-value.component';
import { MagicTableComponent } from 'app/shared/legacy-components/display-structures/magic-table/magic-table.component';
import { ModalComponent } from 'app/shared/legacy-components/layout/modal/modal.component';
import { OvalExpandButtonComponent } from 'app/shared/legacy-components/forms/oval-expand-button/oval-expand-button.component';
import { PlaceholderTableComponent } from 'app/shared/legacy-components/display-structures/placeholder-table/placeholder-table.component';
import { ProgressBarComponent } from 'app/shared/legacy-components/display-structures/progress-bar/progress-bar.component';
import { TradeTableComponent } from 'app/shared/legacy-components/display-structures/trade-table/trade-table.component';
import { RadioButtonGroupComponent } from 'app/shared/legacy-components/forms/radio-button-group/radio-button-group.component';
import { SimpleCsvDownloadButtonComponent } from 'app/shared/legacy-components/forms/simple-csv-download-button/simple-csv-download-button.component';
import { TradeErrorModalComponent } from 'app/shared/legacy-components/layout/trade-error-modal/trade-error-modal.component';
import { MagicTableCellComponent } from 'app/shared/legacy-components/display-structures/magic-table/cell/magic-table-cell.component';
import { TreeCsvDownloadButtonComponent } from 'app/shared/legacy-components/forms/tree-csv-download-button/tree-csv-download-button.component';
import { PortfolioSelectionTileComponent } from 'app/shared/legacy-components/forms/portfolio-selection-tile/portfolio-selection-tile.component';
import { ConfigTileComponent } from 'app/shared/legacy-components/forms/portfolio-selection-tile/config-tile/config-tile.component';
import { MinimisedConfigTileComponent } from 'app/shared/legacy-components/forms/portfolio-selection-tile/minimised-config-tile/minimised-config-tile.component';
import { ChangeImLabeledEntityComponent } from 'app/shared/legacy-components/metrics/change-im-labeled-entity/change-im-labeled-entity.component';
import { RangeSliderComponent } from 'app/shared/legacy-components/forms/range-slider/range-slider.component';
import { PanelComponent } from 'app/shared/legacy-components/layout/panel/panel.component';
import { DropdownLabeledEntityComponent } from 'app/shared/legacy-components/metrics/dropdown-labeled-entity/dropdown-labeled-entity.component';
import { InputLabeledEntityComponent } from 'app/shared/legacy-components/metrics/input-labeled-entity/input-labeled-entity.component';
import { DateSelectionLabeledEntityComponent } from 'app/shared/legacy-components/metrics/date-selection-labeled-entity/date-selection-labeled-entity.component';
import { DropdownItemChainComponent } from 'app/shared/legacy-components/forms/dropdown-item-chain/dropdown-item-chain.component';
import { LoadingBarComponent } from 'app/shared/legacy-components/display-structures/loading-bar/loading-bar.component';

// modern
import { PageNotFoundComponent } from 'app/page-issues/page-not-found/page-not-found.component';
import { NgxPopperjsModule } from 'ngx-popperjs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatRadioModule } from '@angular/material/radio';
import { MatButtonModule } from '@angular/material/button';
import { MatTabsModule } from '@angular/material/tabs';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { PortalModule } from '@angular/cdk/portal';
import { AlertComponent } from 'app/shared/components/alert/alert.component';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTreeModule } from '@angular/material/tree';

/** OG modules */
import { OGCardModule } from 'app/shared/components/card/card.module';
import { OGTableModule } from 'app/shared/components/table/table.module';
import { OGMetricsModule } from 'app/shared/components/metrics/metrics.module';
import { OGLayoutModule } from 'app/shared/components/layout/layout.module';
import { OGTableCellModule } from 'app/shared/components/table/table-cells/table-cell.module';
import { OGChartsModule } from 'app/shared/components/charts/charts.module';
import { OGTableWrapperModule } from 'app/shared/components/table-wrapper/table-wrapper.module';
import { TreeTableComponent } from 'app/shared/legacy-components/tree-table/tree-table.component';
import {
  LegacyMultiSelectModule,
  FiltersPanelModule,
  SnackBarService,
  IconModule,
  OGFormsModule,
  OGPipesModule,
  OGMiscModule,
  EntryFieldModule,
  FilePickerComponent,
  DropdownComponent,
  TabModule,
  LegacyToggleModule,
  ConfigBarComponent,
  TableBoundsDropdownComponent
} from '@opengamma/ui';

const standaloneComponents = [ConfigBarComponent, TableBoundsDropdownComponent];

@NgModule({
  imports: [
    /** Third party modules */
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    CommonModule,
    LegacyMultiSelectModule,
    FiltersPanelModule,
    RouterModule,
    MatTooltipModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    NgxPopperjsModule,
    MatCheckboxModule,
    MatRadioModule,
    MatDatepickerModule,
    MatButtonModule,
    PortalModule,
    MatSnackBarModule,
    MatTreeModule,
    /** OG modules */
    IconModule,
    OGCardModule,
    OGFormsModule,
    OGTableWrapperModule,
    OGTableModule,
    OGMetricsModule,
    OGLayoutModule,
    OGChartsModule,
    OGPipesModule,
    OGMiscModule,
    TabModule,
    LegacyToggleModule,
    TabModule,
    ...standaloneComponents
  ],
  declarations: [
    AlertComponent,
    ModalComponent,
    ProgressBarComponent,
    TradeTableComponent,
    PlaceholderTableComponent,
    OvalExpandButtonComponent,
    MagicTableComponent,
    MagicTableCellComponent,
    ArrowComponent,
    RadioButtonGroupComponent,
    SimpleCsvDownloadButtonComponent,
    TradeErrorModalComponent,
    MagicTableValueComponent,
    TreeCsvDownloadButtonComponent,
    RangeSliderComponent,
    RangeSliderComponent,
    PanelComponent,
    DropdownLabeledEntityComponent,
    InputLabeledEntityComponent,
    DateSelectionLabeledEntityComponent,
    LoadingBarComponent,
    DropdownItemChainComponent,
    ChangeImLabeledEntityComponent,
    ConfigTileComponent,
    PortfolioSelectionTileComponent,
    MinimisedConfigTileComponent,
    PageNotFoundComponent,
    NoPermissionsComponent,
    TreeTableComponent
  ],
  providers: [SnackBarService],
  exports: [
    AlertComponent,
    ModalComponent,
    ProgressBarComponent,
    TradeTableComponent,
    FilePickerComponent,
    PlaceholderTableComponent,
    OvalExpandButtonComponent,
    MagicTableComponent,
    ArrowComponent,
    RadioButtonGroupComponent,
    SimpleCsvDownloadButtonComponent,
    TradeErrorModalComponent,
    DropdownComponent,
    TreeCsvDownloadButtonComponent,
    RangeSliderComponent,
    PanelComponent,
    DropdownLabeledEntityComponent,
    InputLabeledEntityComponent,
    DateSelectionLabeledEntityComponent,
    LoadingBarComponent,
    DropdownItemChainComponent,
    ChangeImLabeledEntityComponent,
    ConfigTileComponent,
    PortfolioSelectionTileComponent,
    MinimisedConfigTileComponent,
    PageNotFoundComponent,
    NoPermissionsComponent,
    ConfigBarComponent,

    /** Third party modules */
    CommonModule,
    NgxPopperjsModule,
    FlexLayoutModule,
    NgxDatatableModule,
    FormsModule,
    ReactiveFormsModule,
    MatTabsModule,
    MatDatepickerModule,
    MatFormFieldModule,
    MatInputModule,
    MatDialogModule,
    MatAutocompleteModule,
    MatTooltipModule,
    MatCheckboxModule,
    MatRadioModule,
    NoPermissionsComponent,
    MatTabsModule,
    NgxPopperjsModule,
    ConfigBarComponent,
    TableBoundsDropdownComponent,
    MatButtonModule,
    TreeTableComponent,
    MatSnackBarModule,

    /** OG modules */
    LegacyMultiSelectModule,
    FiltersPanelModule,
    IconModule,
    OGCardModule,
    OGFormsModule,
    EntryFieldModule,
    OGMetricsModule,
    OGPipesModule,
    OGLayoutModule,
    OGTableCellModule,
    OGChartsModule,
    OGTableWrapperModule,
    OGTableModule,
    OGMiscModule,
    TabModule,
    LegacyToggleModule,
    TabModule
  ]
})
export class SharedModule {}
