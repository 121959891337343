/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Location } from '@angular/common';
import { MockAuthService } from './mock-auth.service';
import { carmaApiPath } from 'environments/environment.utils';
import { catchError } from 'rxjs/operators';
import { observableOf, UserAuthModels, UserDetails } from '@opengamma/ui';

/** Hits local test server for permissions, authentication service. */
@Injectable()
export class TestAuthService implements AuthService {
  idToken = 'idToken';

  getRequestHeaders(): HttpHeaders {
    return new HttpHeaders();
  }

  constructor(private client: HttpClient) {
    console.info(`
    --------------------------
    Using TestAuthService
    --------------------------
    `);
  }

  permissions(): Observable<UserAuthModels.Permissions> {
    return this.client.get<UserAuthModels.Permissions>(
      Location.joinWithSlash(carmaApiPath(), 'permissions')
    );
  }

  getDemoTenantStatus(tenant: string): Observable<boolean> {
    return this.client
      .get<boolean>(Location.joinWithSlash(carmaApiPath(), 'tenant-demo-status'))
      .pipe(
        catchError(error => {
          console.warn(
            'Could not determine if the current tenant is a demo environment. Assuming this is a production environment. Error: ',
            error
          );
          return observableOf(false);
        })
      );
  }

  authenticate(): Observable<UserDetails> {
    return new MockAuthService().authenticate();
  }

  unauthenticate(): void {
    console.warn('Unauthenticating');
  }

  warmUp(): Observable<void> {
    return of();
  }
}
