/*
 * Copyright (C) 2024 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UserService } from './user.service';
import { MfaSettings } from 'app/user-auth/models';
import { Location } from '@angular/common';
import { carmaApiPath } from 'environments/environment.utils';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ApiUserService extends UserService {
  constructor(private client: HttpClient) {
    super();
  }

  updateMfa(settings: MfaSettings): Observable<void> {
    const url = Location.joinWithSlash(carmaApiPath(), 'user/mfa');
    return this.client.put<void>(url, settings);
  }
}
