/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import {
  MagicTableColumnGroup,
  MagicTableRow
} from 'app/shared/legacy-components/display-structures/magic-table/models';
import { NamedValue } from '@opengamma/ui';

@Component({
  selector: 'og-trade-error-modal',
  templateUrl: './trade-error-modal.component.html',
  styleUrls: ['./trade-error-modal.component.scss']
})
export class TradeErrorModalComponent implements OnChanges {
  /** The trade file name */
  @Input() fileName: string;
  /** The trade error line number */
  @Input() lineNumber: number;
  /** The list of header name to field values */
  @Input() fieldData: NamedValue[];
  /** Emits modal close clicks */
  @Output() closeModalClick: EventEmitter<void> = new EventEmitter<void>();

  errorMetadataTableColumnGroups: MagicTableColumnGroup[] = [
    {
      flexBasis: '50%',
      columns: [
        {
          label: 'Field',
          type: 'text'
        }
      ]
    },
    {
      flexBasis: '50%',
      columns: [
        {
          label: 'Value',
          type: 'text'
        }
      ]
    }
  ];

  errorMetadataTableRows: MagicTableRow[] = [];

  ngOnChanges() {
    if (this.fieldData) {
      this.errorMetadataTableRows = this.fieldData.map(({ name, value }) => ({
        id: name,
        values: [name, value]
      }));
    }
  }

  onCloseModalClicked() {
    this.closeModalClick.next();
  }
}
