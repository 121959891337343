<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div class="card" [class.card--overflow-hidden]="!allowOverflow" fxFlexFill fxLayout="column">
  <div class="header" fxLayout="row" fxLayoutAlign="start center">
    @if (collapse) {
    <div>
      <og-icon
        class="card__collapse-icon"
        (click)="isCollapsed = !isCollapsed"
        [name]="getCollapseIcon()"
      ></og-icon>
    </div>
    }
    <div fxFlex="grow">
      <ng-content select="[title-bar]"></ng-content>
    </div>
  </div>

  @if (!isCollapsed) {
  <div class="content" fxFlex fxLayout="column">
    <ng-content></ng-content>
  </div>
  }
</div>
