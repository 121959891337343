/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Observable } from 'rxjs';
import { observableOf } from 'app/shared/rxjs/rxjs.utils';
import { MfaSettings } from 'app/user-auth/models';

/** Mock implementation of user service. */
@Injectable()
export class MockUserService extends UserService {
  constructor() {
    super();
  }

  updateMfa(mfaSettings: MfaSettings): Observable<void> {
    return observableOf();
  }
}
