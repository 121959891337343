/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, ChangeDetectionStrategy } from '@angular/core';

/**
 * @deprecated Use new component from OG/UI library instead
 */
@Component({
  selector: 'og-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class CardComponent {
  @Input() collapse: 'vertical' | 'horizontal';
  @Input() allowOverflow = false;
  isCollapsed = false;

  getCollapseIcon(): string {
    const baseIcon = 'chevron';
    const directionalMap = {
      horizontal: ['right', 'left'],
      vertical: ['up', 'down']
    };
    const directionIndex = this.isCollapsed ? 1 : 0;
    const direction = directionalMap[this.collapse][directionIndex];
    return `${baseIcon}-${direction}`;
  }
}
