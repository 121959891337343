<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div class="overflow-wrapper" [class.overflow-wrapper--fixed-overflow]="hasFixedOverflow">
  <table class="table" [class.table--fixed-overflow]="hasFixedOverflow">
    <thead class="table__head">
      <!-- Column groups-->
      <tr class="table__header-row" *ngIf="(groups$ | async).length > 1">
        <th
          *ngFor="let columnGroup of groups$ | async; let first = first; let last = last"
          class="table__header-cell"
          [class.first-column]="first && columnGroup.columns.length === 1"
          [class.table__header-cell--no-border]="last"
          [class.table__header-cell--no-padding]="!columnGroup.label"
          [attr.colspan]="columnGroup.columns.length"
        >
          <div fxLayout="row">
            <div class="cell__content" fxLayout="row" fxFlex="grow" fxLayoutAlign="center center">
              <ng-template
                [ngTemplateOutlet]="columnTitle"
                [ngTemplateOutletContext]="{
                  $implicit: { label: columnGroup.label, tooltip: columnGroup.subtext }
                }"
              ></ng-template>
            </div>
          </div>
        </th>
      </tr>
      <!-- Columns in column groups-->
      <tr>
        <th
          *ngFor="let column of columns$ | async; let first = first; let last = last; let i = index"
          class="table__header-cell table__header-cell--sortable"
          [class.first-column]="(columns$ | async).length > 1 && first"
          [class.table__header-cell--no-border]="last"
          (click)="!column.preventSorting && onRowSort(column)"
          test-ref="table-head-cell"
        >
          <div fxLayout="row">
            <div class="cell__content" fxLayout="row" fxFlex="grow" fxLayoutAlign="center center">
              <ng-container *ngIf="shouldDisplayCustomColumnTemplate(column.id)">
                <ng-container
                  *ngTemplateOutlet="
                    customHeaderCellTemplates[column.id].template;
                    context: { columnId: column.id, rows: rows$ | async }
                  "
                ></ng-container>
              </ng-container>
              <ng-template
                *ngIf="!shouldDisplayCustomColumnTemplate(column.id)"
                [ngTemplateOutlet]="columnTitle"
                [ngTemplateOutletContext]="{
                  $implicit: {
                    label: column.label,
                    tooltip: column.subtext,
                    icon: column.icon
                  }
                }"
              ></ng-template>
              <div *ngIf="!column.preventSorting" class="table__sort-icon" fxFlex="0 0 4px">
                <og-icon [name]="getSortIcon(column.id)"></og-icon>
              </div>
            </div>
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="table__body">
      <!-- Total row, if it exists-->
      <ng-container *ngIf="_totalRow">
        <ng-template
          [ngTemplateOutlet]="tableRows"
          [ngTemplateOutletContext]="{
            $implicit: { rows: _totalRow, level: 0, path: [''], isTotal: true }
          }"
        ></ng-template>
      </ng-container>
      <!-- Recursive table rows-->
      <ng-template
        [ngTemplateOutlet]="tableRows"
        [ngTemplateOutletContext]="{
          $implicit: { rows: rows$ | async, level: 0, path: [''], isTotal: false }
        }"
      ></ng-template>
    </tbody>
  </table>

  <!-- Column Title Template -->
  <ng-template #columnTitle let-title>
    <div *ngIf="title.tooltip" [matTooltip]="title.tooltip" class="table__header-cell-icon">
      <og-icon name="help-circle"></og-icon>
    </div>

    {{ title.label }}
    <ng-container *ngIf="title.icon as icon">
      <og-icon
        [name]="icon.name"
        class="color--{{ icon.color }}"
        [matTooltip]="icon.tooltip"
      ></og-icon>
    </ng-container>
  </ng-template>

  <!-- Row Iteration Template -->
  <ng-template #tableRows let-node>
    <!-- Iterate through the rows at the current level -->
    <ng-container
      *ngFor="
        let row of node.rows?.slice(0, getNumberOfRowsToDisplayForPath(node.path));
        let first = first;
        let last = last;
        trackBy: trackRows
      "
    >
      <tr
        class="table__row table__row--{{ node?.level }}"
        [class.table__row--expandable]="row?.isExpandable"
        [class.table__row--first]="node?.level > 0 && first"
        [class.table__row--last]="node?.level > 0 && last"
        [class.table__row--total]="node?.isTotal"
        (click)="!node.isTotal && row?.isExpandable && onRowExpand(node.path, row?.id, node.level)"
        test-ref="table-body-row"
      >
        <td
          *ngFor="
            let column of columns$ | async;
            let i = index;
            let first = first;
            let last = last;
            trackBy: trackColumns
          "
          class="table__cell table__cell--{{ node?.level }}"
          [class.first-column]="first"
          [class.table__cell--total]="node?.isTotal"
          [class.table__cell--no-border-right]="last"
        >
          <div
            class="table__cell-content"
            fxLayout="row"
            [fxLayoutAlign]="getCustomAlignment(first, column)"
          >
            <ng-container *ngIf="first">
              <div
                class="table__expansion-icon
                table__expansion-icon--{{ expansionIconColor }}
                table__expansion-icon--{{ node?.level }}"
              >
                <og-icon
                  *ngIf="row?.isExpandable"
                  [name]="row?.isExpanded ? 'minus' : 'plus'"
                  [stroke]="IconStroke.XThick"
                ></og-icon>
              </div>
            </ng-container>

            <ng-container
              *ngIf="shouldDisplayCustomCellTemplate(column.id, node.level); else standardTemplate"
            >
              <ng-container
                *ngTemplateOutlet="
                  customCellTemplates[column.id].template;
                  context: { node: node, row: row, column: column }
                "
              ></ng-container>
            </ng-container>

            <ng-template #standardTemplate>
              <og-table-cell
                [cell]="getCellForTemplate(row, column)"
                [bound]="bound"
              ></og-table-cell>
            </ng-template>
          </div>
        </td>
      </tr>

      <ng-container *ngIf="shouldDisplayCustomRowTemplate(row)">
        <td [attr.colspan]="(columns$ | async).length">
          <ng-container
            *ngTemplateOutlet="
              customRowTemplate;
              context: { $implicit: node, node: node, row: row }
            "
          ></ng-container>
        </td>
      </ng-container>

      <!-- Iterate through children if they exist -->
      <ng-container *ngIf="row?.isExpandable && row?.isExpanded">
        <ng-template
          [ngTemplateOutlet]="tableRows"
          [ngTemplateOutletContext]="{
            $implicit: {
              rows: row?.children,
              level: node.level + 1,
              path: node.path.concat(row.id),
              isTotal: false
            }
          }"
        ></ng-template>
      </ng-container>
    </ng-container>

    <!-- Vertical pagination -->
    <ng-container *ngIf="getNumberOfRowsToDisplayForPath(node.path) as numberOfRowsToDisplay">
      <tr
        class="table__row table__row--show-more table__row--{{ node.level }}"
        *ngIf="!node.isTotal && node.rows && node.rows.length > numberOfRowsToDisplay"
        (click)="onShowMoreRows(node.path)"
        test-ref="show-more-row"
      >
        <td
          class="table__cell table__cell--show-more table__cell--no-border-right"
          [attr.colspan]="(columns$ | async).length"
        >
          <div
            class="table__show-more table__show-more--{{ node.level }}"
            fxLayout="row"
            fxLayoutAlign="space-between center"
          >
            <div class="show-more__call-to-action" fxLayout="row">
              <div class="table__expansion-icon">
                <og-icon name="angle-down"></og-icon>
              </div>
              <div>Show {{ rowPaginationCount }} more</div>
            </div>
            <div class="show-more__info">
              Showing
              <span class="show-more__row-count">
                {{ numberOfRowsToDisplay }}
              </span>
              rows of
              <span class="show-more__row-count">{{ node.rows.length }}</span>
            </div>
          </div>
        </td>
      </tr>
    </ng-container>
  </ng-template>
</div>
