/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { Component, EventEmitter, Input, OnChanges, Output } from '@angular/core';
import { IconSize } from '@opengamma/ui';
import { FileUploadData } from 'app/shared/models/file-upload-data.model';

@Component({
  selector: 'og-portfolio-selection-tile',
  templateUrl: './portfolio-selection-tile.component.html',
  styleUrls: ['./portfolio-selection-tile.component.scss']
})
export class PortfolioSelectionTileComponent implements OnChanges {
  /** The status of the portfolio selection tile */
  @Input() status = 'active';
  /** Defines if the portfolio validation is in progress */
  @Input() portfolioValidationLoading: boolean;
  /** Defines if a cancel button should be displayed */
  @Input() displayCancelButton = false;
  /** Defines if the tile is minimized. */
  @Input() tileMinimized: boolean;
  /** Defines if the tile should be hidden. */
  @Input() tileHidden: boolean;
  /** The tile title to display */
  @Input() tileTitle: string;
  /** The minimized title to display */
  @Input() minimizedTitle: string;
  /** The custom theme to use */
  @Input() theme = 'config__tile--color--fun-blue';
  /** The custom theme to use */
  @Input() size: 'medium' | 'large' = 'medium';
  /** The selected portfolio name */
  @Input() selectedPortfolio: string;
  /** The error validating portfolio */
  @Input() error: string;

  /** Emits portfolio selection changes */
  @Output() portfolioSelectedChange: EventEmitter<FileUploadData> = new EventEmitter<
    FileUploadData
  >();
  /** Emits selected portfolio removal changes */
  @Output() portfolioRemovedChange: EventEmitter<void> = new EventEmitter<void>();
  /** Emits cancel button clicks */
  @Output() cancelButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  /** Emits error retry button clicks */
  @Output() retryPortfolioValidation: EventEmitter<void> = new EventEmitter<void>();
  /** Emits error change selection clicks */
  @Output() onErrorChangeSelectionClick: EventEmitter<void> = new EventEmitter<void>();

  portfolioUploadingName: string;
  IconSize = IconSize;

  portfolioUploaded(filesUploaded: FileUploadData[]) {
    this.portfolioSelectedChange.next(filesUploaded[0]);
  }

  removeUploadedPortfolio() {
    this.portfolioRemovedChange.next();
  }

  onCancelButtonClicked() {
    this.cancelButtonClicked.next();
  }

  ngOnChanges() {
    if (!!this.selectedPortfolio) {
      this.portfolioUploadingName = this.selectedPortfolio.substring(
        0,
        this.selectedPortfolio.lastIndexOf('.zip')
      );
    } else {
      this.portfolioUploadingName = undefined;
    }
  }

  onErrorRetryClicked() {
    this.retryPortfolioValidation.next();
  }

  onErrorChangeSelectionClicked() {
    this.onErrorChangeSelectionClick.next();
  }
}
