/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { InvertedMetricComponent } from 'app/shared/components/metrics/inverted-metric/inverted-metric.component';
import { LabeledEntityComponent } from 'app/shared/components/metrics/labeled-entity/labeled-entity.component';
import { CustomLabeledEntityComponent } from 'app/shared/components/metrics/custom-content-labeled-entity/custom-content-labeled-entity.component';
import { SelectableMetricsComponent } from 'app/shared/components/metrics/selectable-metrics/selectable-metrics.component';
import { SimpleLabeledEntityComponent } from 'app/shared/components/metrics/simple-labeled-entity/simple-labeled-entity.component';
import { HeadlinesComponent } from 'app/shared/components/metrics/headlines/headlines.component';
import { OGLayoutModule } from 'app/shared/components/layout/layout.module';
import {
  OGPipesModule,
  IconModule,
  OGHeadlinesScreenEngineModule,
  OGMiscModule
} from '@opengamma/ui';
import { TimelineComponent } from 'app/shared/components/metrics/timeline/timeline.component';
import { ChangeCurrencyValueComponent } from 'app/shared/components/metrics/signed-currency/change-currency-value.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    OGPipesModule,
    OGLayoutModule,
    OGMiscModule,
    IconModule,
    MatTooltipModule,
    OGHeadlinesScreenEngineModule
  ],
  declarations: [
    ChangeCurrencyValueComponent,
    CustomLabeledEntityComponent,
    InvertedMetricComponent,
    LabeledEntityComponent,
    SelectableMetricsComponent,
    SimpleLabeledEntityComponent,
    HeadlinesComponent,
    TimelineComponent
  ],
  exports: [
    ChangeCurrencyValueComponent,
    CustomLabeledEntityComponent,
    InvertedMetricComponent,
    LabeledEntityComponent,
    SelectableMetricsComponent,
    SimpleLabeledEntityComponent,
    HeadlinesComponent,
    OGHeadlinesScreenEngineModule,
    TimelineComponent
  ]
})
export class OGMetricsModule {}
