<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<section class="panel">
  <div
    *ngIf="title"
    class="panel__header"
    [class.panel__header--collapsible]="isUserCollapsible"
    (click)="onHeaderClick()"
  >
    <div class="panel__title">
      {{ title }}
    </div>

    <div class="panel__config">
      <div class="panel__inline">
        <ng-content select="[inline]"></ng-content>
      </div>
      <og-icon
        class="config__button"
        *ngIf="isUserCollapsible"
        name="chevron-{{ isExpanded ? 'up' : 'down' }}"
        [size]="IconSize.Large"
        [stroke]="IconStroke.XThick"
      ></og-icon>
    </div>
  </div>
  <div class="panel__content" [class.panel__content--collapsed]="!isExpanded">
    <ng-content></ng-content>
  </div>
</section>
