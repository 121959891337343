/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef
} from '@angular/core';

/**
 * If this component is to be used within TableWrapperComponent, but with its functionality handled outside of
 *     TableWrapperComponent, then you must set ** exportFunctionality = true **.
 */
@Component({
  selector: 'og-table-text-filter',
  templateUrl: './table-text-filter.component.html',
  styleUrls: ['./table-text-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableTextFilterComponent {
  _filter: string;

  @Input() hint = 'Filter table rows';

  /** Prevent TableWrapperComponent from handling this components logic. */
  @Input() exportFunctionality = false;

  @Output() onFilterChange = new EventEmitter<string>();

  @Input() set filter(filter: string) {
    this._filter = filter;
    this.cdRef.markForCheck();
  }
  get filter(): string {
    return this._filter;
  }

  constructor(private cdRef: ChangeDetectorRef) {}

  onFilterChangeEvent(filter: string): void {
    this.filter = filter;
    this.onFilterChange.emit(filter);
  }
}
