<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div *ngIf="displayLabels" class="text">
  <div class="text__cell text__cell--left">{{ title }}</div>
  <div class="text__cell text__cell--center">
    <og-arrow
      *ngIf="isNegativeAllowed"
      [invertColors]="shouldInvertArrowColors"
      [value]="value.price || value"
    ></og-arrow>
    {{ getDisplayValue() }}
  </div>
  <div class="text__cell text__cell--right">
    {{ getPercentage() | percent: '1.1-1' }}
  </div>
</div>
<og-highchart [options]="options"></og-highchart>
<div class="labels" *ngIf="isNegativeAllowed">
  <div>-{{ maxValue | percent: '1.1-1' }}</div>
  <div>0%</div>
  <div>{{ maxValue | percent: '1.1-1' }}</div>
</div>
