/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, EventEmitter, Output, SimpleChanges, OnChanges } from '@angular/core';
import { DateTime } from 'luxon';

@Component({
  selector: 'og-date-selection-labeled-entity',
  templateUrl: './date-selection-labeled-entity.component.html',
  styleUrls: ['./date-selection-labeled-entity.component.scss']
})
export class DateSelectionLabeledEntityComponent implements OnChanges {
  /** The label to display */
  @Input() label: string;
  /** The size of the input */
  @Input() size: 'small' | 'medium' | 'large' = 'large';
  /** The date to display in ISO_FORMAT */
  @Input() date: string;
  /** Specific date format to apply to the date for displaying purposes */
  @Input() dateFormat = 'yyyy-mm-dd';
  /** Emits dropdown selection changes */
  @Output() selectionChange = new EventEmitter<string>();

  showCalendar = false;
  displayDate: string;
  maxDate = DateTime.local();

  ngOnChanges(changes: SimpleChanges) {
    if (!!changes.date && !!changes.date.currentValue) {
      this.displayDate = DateTime.fromISO(this.date).toFormat(this.dateFormat);
    }
  }

  onDateChange(date: string) {
    this.showCalendar = false;
    this.displayDate = DateTime.fromISO(date).toFormat(this.dateFormat);
    this.selectionChange.next(date);
  }
}
