import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import {
  AbstractCommonScreensResolver,
  filterUndefined,
  LOCAL_PRODUCT_DEFINITION,
  ProductDateType
} from '@opengamma/ui';
import { State } from '../store/reducers';
import * as fromUser from '../../user-auth/store/user-auth.selectors';
import { filter, map, MonoTypeOperatorFunction, Observable, pipe, withLatestFrom } from 'rxjs';
import { selectUrl } from '../store/utils/router-state-utils';
import { getScreenUrlByTemplate } from '../../user-auth/store/user-auth.selectors';
import { findScreenByUrlPath } from '../utils/resolver.utils';
import { carmaApiPath } from 'environments/environment.utils';

@Injectable()
export class CarmaCommmonScreenResolver extends AbstractCommonScreensResolver {
  constructor(private store: Store<State>) {
    super();
  }

  getDateTypeForCurrentUrl(): Observable<ProductDateType> {
    return this.store.pipe(select(fromUser.getDateTypeForCurrentUrl));
  }

  getScreenUrl(): string {
    return location.pathname;
  }

  getScreenId(): string {
    return findScreenByUrlPath(LOCAL_PRODUCT_DEFINITION(carmaApiPath()), location.pathname);
  }

  filterByActiveRoute<String>(screen: string): MonoTypeOperatorFunction<String> {
    return pipe(
      withLatestFrom(
        this.store.pipe(select(selectUrl), filterUndefined()),
        this.store.pipe(select(getScreenUrlByTemplate))
      ),
      filter(([, currentUrl, screenUrls]) => currentUrl === screenUrls[screen]),
      map(_ => screen)
    ) as MonoTypeOperatorFunction<String>;
  }
}
