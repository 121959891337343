/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

/** Flattens the given reducers into a single reducer. */
export function flattenReducers(...reducers) {
  return (state, action) =>
    reducers.reduce((currentState, reducer) => reducer(currentState, action), state);
}
