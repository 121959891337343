<!--
~ Copyright (C) 2021 - present by OpenGamma Inc. and the OpenGamma group of companies
~
~ Please see distribution for license.
-->

<div fxLayout="column" fxLayoutGap="8px">
  <div class="timeline__title" (click)="isCompact = !isCompact">
    {{ isCompact ? 'Show' : 'Hide' }} {{ timeline.title }}
    <og-icon [name]="isCompact ? 'caret-down-filled' : 'caret-up-filled'"></og-icon>
  </div>
  @if (!isCompact) {
  <div class="timeline__content">
    @for (node of timeline.nodes; track node; let isLast = $last) {
    <div fxLayout="row" fxLayoutAlign="start start">
      <div fxLayout="row" fxLayoutAlign="start start">
        <div fxLayout="column" fxLayoutAlign="start center">
          <og-icon
            [name]="node.icon.name"
            class="color--{{ node.icon.color }} border--{{ node.icon.color }} timeline__icon"
            [size]="IconSize.XSmall"
          ></og-icon>
          @if (!isLast) {
          <div class="timeline__line"></div>
          }
        </div>
        <og-xofy [data]="node.description" class="timeline__x-dash-y"></og-xofy>
      </div>
    </div>
    }
  </div>
  }
</div>
