<og-select
  selectPlacement="bottom-start"
  [multiple]="true"
  [selectedOptions]="getSelectedOptions()"
  (selectionChange)="onChipSelectionChange($event)"
  class="select"
>
  <og-select-label>
    <og-icon name="menu-2"></og-icon>
  </og-select-label>

  <og-select-trigger class="select__trigger">
    <div fxLayout="row" fxLayoutGap="4px" class="select__trigger__chips">
      <ng-container *ngIf="selectedOptions?.length > 0; else noSelectedOptions">
        <og-chip
          [fieldId]="option"
          [removeIcon]="'times'"
          (remove)="onRemoveOption($event)"
          class="legend__item"
          fxLayout="row"
          fxLayoutAlign="center center"
          *ngFor="let option of selectedOptions; let i = index"
        >
          <div class="legend-item__bar" [style.background-color]="colors[i]"></div>
          <div class="legend-item__label">{{ option }}</div>
        </og-chip>
      </ng-container>
      <ng-template #noSelectedOptions>
        <div class="select__trigger__chips--empty">Select {{ pluralize(grouping) }}...</div>
      </ng-template>
    </div>
  </og-select-trigger>

  <og-select-panel class="select__panel">
    <og-select-panel-title>Show or hide {{ pluralize(grouping) }}</og-select-panel-title>

    <og-select-option
      *ngIf="enableSelectAllOption"
      (onSelect)="onSelectAllOptions()"
      [selected]="areAllOptionsSelected()"
      [detached]="true"
    >
      Select all
    </og-select-option>

    <og-select-option
      *ngIf="enableSelectNoneOption"
      (onSelect)="onDeselectAllOptions()"
      [selected]="areAllOptionsDeselected()"
      [detached]="true"
    >
      Select none
    </og-select-option>
    <og-select-option *ngFor="let option of options" [value]="option" class="select__option">
      <og-checkbox-noop [checked]="isOptionSelected(option)">
        {{ option }}
      </og-checkbox-noop>
    </og-select-option>
  </og-select-panel>
</og-select>
