<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div class="inverted-metric">
  <div class="inverted-metric__metric">
    <ng-content select="[metric]"></ng-content>
  </div>

  @if (showDivider) {
  <div class="inverted-metric__divider"></div>
  }

  <div class="inverted-metric__subtext">
    <ng-content select="[subtext]"></ng-content>
  </div>
</div>
