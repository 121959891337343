<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div
  class="export-btn export-btn--{{ theme }}"
  [class.export-btn--disabled]="disabled"
  (click)="onExportCsvClick()"
  right-navigation
>
  <span class="export-btn__label">
    {{ text }}
    <og-icon class="export-btn__icon" name="file-spreadsheet" [size]="IconSize.Large"></og-icon>
  </span>
</div>
