<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<span
  test-ref="boundedNumberValue"
  [matTooltip]="value | number: '1.2-2'"
  matTooltipPosition="right"
>
  {{ value ? (value | boundedShortNumber: bound:decimalPipeArgs) : undefinedValuePlaceholder }}

  <og-subtext *ngIf="subtext" [subtext]="subtext"></og-subtext>
</span>
