/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { OMEGA } from 'app/shared/utils/colors';

export function getOptions(
  percentage: number,
  color: string,
  negative: boolean,
  max: number,
  animationDuration?: number
) {
  return {
    chart: {
      type: 'bar',
      height: 7,
      plotBackgroundColor: OMEGA,
      renderTo: 'chart',
      spacing: [0, 0, 0, 0]
    },
    title: {
      text: undefined
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: false,
      min: negative ? -max : 0,
      max: max
    },
    tooltip: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    navigation: {
      buttonOptions: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: false
        }
      },
      column: {
        grouping: false,
        shadow: false
      },
      series: {
        animation: animationDuration ? { duration: animationDuration } : true,
        borderWidth: 0,
        enableMouseTracking: false,
        color: color,
        groupPadding: 0,
        pointPadding: 0,
        states: {
          hover: {
            enabled: false
          }
        }
      }
    },
    series: [
      {
        data: [percentage]
      }
    ]
  };
}
