<og-header></og-header>
<og-screen-status-header></og-screen-status-header>
<og-layout-container>
  @if (isScreenStatusLoading$ | async) {
  <og-spinner display="overlay"></og-spinner>
  } @else {
  <div class="main__content">
    @if (isDataAvailable$ | async) {
    <router-outlet></router-outlet>
    } @else {
    <og-placeholder-icon
      label="No results available for the current selected date."
    ></og-placeholder-icon>
    }
  </div>
  }
</og-layout-container>
