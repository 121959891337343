/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Environment } from './environment.model';
import { commonEnvironmentProperties } from 'environments/common/common.environment';

// Default dev environment, using the dev auth service and dev API.
export const environment: Environment = {
  ...commonEnvironmentProperties,
  production: true,
  preview: false,
  hostName: 'analytics.dev.opengamma.com',
  hmr: false,
  testRun: false,
  suppressErrors: false,

  disableAuth: false,
  authProvider: 'api',
  dataProvider: 'api',
  apiPath: '',

  loginUrl: 'https://login.dev.opengamma.com',
  auth0Url: 'auth.dev.opengamma.com',
  audience: 'https://api.dev.opengamma.com',

  analyticsUrl: 'https://analytics.dev.opengamma.com',

  datadogApplicationId: 'e6b038c2-a837-49b5-8232-f02a66d1f531',
  datadogClientToken: 'pub8382bd0abc7d1b5ca015d9371688ac0f',
  datadogServiceName: 'carma-dev',
  datadogEnvName: 'dev'
};
