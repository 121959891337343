<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div
  class="main-container main-container--{{ theme$ | async }}"
  fxLayout="column"
  fxFlexAlign="start"
  fxFlex="grow"
>
  @if (areAllNeededApisCompleted$ | async) {
  <router-outlet></router-outlet>
  } @else {
  <div class="spinner-container">
    <og-spinner></og-spinner>
  </div>
  }
</div>
