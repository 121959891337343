<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<ng-container *ngIf="!areCardsLoading; else cardsLoading">
  <og-card>
    <div title-bar>{{ title }}</div>
    <div class="cards__container" fxFlex="auto" fxLayout="column" *ngIf="cards">
      <section>
        <og-summary-card
          *ngFor="let card of cards"
          [isSelected]="card.id === selectedCardId"
          [title]="card.name"
          (onRightArrowClick)="selectCardTrigger(card)"
        >
          <og-summary-card-metrics [value]="card.value"></og-summary-card-metrics>
        </og-summary-card>
      </section>
    </div>
  </og-card>
</ng-container>
<ng-template #cardsLoading>
  <og-spinner class="spinner" align="top"></og-spinner>
</ng-template>
