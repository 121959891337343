/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import * as _ from 'lodash';
import { Component, OnChanges, SimpleChanges, ChangeDetectionStrategy } from '@angular/core';
import { defaultStackedColumnOptions } from './options/stacked-column-chart.highchart-options';
import { OGColumnChartComponent } from 'app/shared/components/charts/modern/column-chart/column-chart.component';
import { PageWidthLayoutService } from '@opengamma/ui';

@Component({
  selector: 'og-stacked-column',
  templateUrl: '../highchart/highchart.component.html',
  styleUrls: ['../highchart/highchart.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class OGStackedColumnChartComponent extends OGColumnChartComponent implements OnChanges {
  constructor(pageWidthLayoutService: PageWidthLayoutService) {
    super(pageWidthLayoutService);
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    this.options = _.merge(this.options, defaultStackedColumnOptions);
    super.ngOnChanges(simpleChanges);
  }
}
