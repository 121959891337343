<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

@if (products$ | async; as products) {
<div class="header__container header-height" fxLayout="column" fxLayoutAlign="center">
  @if (theme$ | async; as theme) { @let modules = modules$ | async; @let productUrl =
  selectedProduct$ | async;
  <div
    class="header header--{{ pageLayout$ | async }}"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div fxLayout="row" fxLayoutGap="32px" fxFlex="1 1 100%" fxLayoutAlign="left center">
      <div fxLayout="column" style="position: relative;">
        <a [routerLink]="['/']" class="header__logo">
          @switch (theme) { @case ('blue') {
          <img class="logo__img" src="assets/logos/solid-blue-open.png" />
          } @case ('red') {
          <img class="logo__img" src="assets/logos/pink-open.svg" />
          } @case ('green') {
          <img class="logo__img" src="assets/logos/green-open.svg" />
          } }
        </a>
        <og-uat-badge [userDetails]="userDetail$ | async"></og-uat-badge>
      </div>
      <div class="divider" fxFlex="0 0 1px"></div>

      <og-modules-menu
        [theme]="theme"
        [modules]="modules"
        [productUrl]="productUrl"
      ></og-modules-menu>
      <div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center">
        @if (treasuryProduct$ | async; as treasuryProduct) {
        <a
          [href]="treasuryProduct.urlPath"
          [attr.target]="treasuryProduct.isExternal && '_blank'"
          class="utility-button utility-button--new-experience"
        >
          <og-icon name="repeat2"></og-icon>
        </a>
        }
        <div class="utility-button" (click)="togglePageLayout()">
          @if (pageLayout$ | async; as layout) {
          <og-icon
            [name]="layout === 'fixed' ? 'arrows-diagonal' : 'arrows-diagonal-minimize-2'"
            [matTooltip]="(layout === 'fixed' ? 'Expand' : 'Compress') + ' page elements'"
          ></og-icon>
          }
        </div>
        <og-help-menu class="utility-button help-button">
          <og-help-menu-item>
            <a [routerLink]="userGuideRef$ | async" target="_blank" (click)="onUserDownloadClick()">
              Download user guide
            </a>
          </og-help-menu-item>
          @for (module of (modules | filterModulesByPosition: 'right-dropdown') ; track $index) {
          <og-help-menu-item>
            <a [routerLink]="[productUrl, module.urlPath]">{{ module.moduleName }}</a>
          </og-help-menu-item>

          }
        </og-help-menu>
      </div>
    </div>
  </div>
  }
</div>
} @if (screens$ | async; as screens) {
<og-screens-menu
  [theme]="theme$ | async"
  [displayDatepicker]="true"
  [displayFileViewerButton]="displayFileViewerButton$ | async"
  [displayScreenMenu]="displayScreenMenu$ | async"
>
  <og-screens-menu-items
    [screens]="screens"
    [productUrl]="selectedProduct$ | async"
    [moduleUrl]="selectedModule$ | async"
  ></og-screens-menu-items>
</og-screens-menu>
}
