import { Component, OnInit } from '@angular/core';
import { Observable, delay, map } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { FromScreenStatusSelectors } from '@opengamma/ui';
import { State } from 'app/shared/store/reducers';

@Component({
  selector: 'og-app-main',
  templateUrl: './app-main.component.html',
  styleUrls: ['./app-main.component.scss']
})
export class AppMainComponent implements OnInit {
  isScreenStatusLoading$: Observable<boolean>;
  isDataAvailable$: Observable<boolean>;

  /** Since migrated screens display "no results available" per screen
   * The logic is duplicated as there are some non migrated carma screens that still
   * rely on the legacy flow of dislaying "no results available".
   * This property ensurse only a single "no results available" component is displayed.
   */
  noResultsPlaceHolderAlreadyDefined$: Observable<boolean>;

  ngOnInit() {
    this.isScreenStatusLoading$ = this.store.pipe(
      select(FromScreenStatusSelectors.isScreenStatusLoading)
    );
    this.isDataAvailable$ = this.store.pipe(
      select(FromScreenStatusSelectors.isDataAvailableForCurrentScreenLegacy)
    );

    this.noResultsPlaceHolderAlreadyDefined$ = this.isDataAvailable$.pipe(
      delay(0),
      map(() => {
        const noResultsPlaceholderEls = document.querySelectorAll(
          'og-placeholder-icon[label="No results available for the current selected date."]'
        );
        return noResultsPlaceholderEls.length > 1;
      })
    );
  }

  constructor(private store: Store<State>) {}
}
