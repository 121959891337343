/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Color } from 'highcharts';
import { WHITE } from 'app/shared/utils/colors';

export interface DonutParams {
  color: string;
  points: any;
  mainTitle: string;
  secondaryTitle: string;
  minSize?: number;
}

export interface DonutPoint {
  displayName: string;
  id?: string;
  value: number;
  prettyValue: string;
}

const labelWidth = 135;
const labelHeight = 40;
const labelOffset = 3;

function addTitles(chart, mainTitle, secondaryTitle) {
  const pie = chart.series[0];
  const left = pie.center[0] + chart.plotLeft - labelWidth / 2 - labelOffset;
  const top = pie.center[1] + chart.plotTop - labelHeight / 2 - labelOffset;

  const title = `
    <div class="donut-title" style="width: ${labelWidth}px; height: ${labelHeight}px;">
      <div class="main-title">${mainTitle}</div>
      <div class="secondary-title">${secondaryTitle}</div>
    </div>
  `;

  chart.donutLabel = chart.renderer
    .label(title, left, top, 'rect', 0, 0, true, false, 'donut-title')
    .add();
}

function removeTitles(chart) {
  if (chart.donutLabel) {
    chart.donutLabel.destroy();
  }
}

export function getDonutOptions(params: DonutParams): any {
  return {
    chart: {
      type: 'pie',
      events: {
        render: function() {
          removeTitles(this);
          addTitles(this, params.mainTitle, params.secondaryTitle);
        }
      }
    },
    plotOptions: {
      pie: {
        innerSize: '75%',
        borderWidth: 1,
        borderColor: 'rgba(0, 0, 0, 0.2)',
        cursor: 'default',
        dataLabels: {
          distance: 15,
          connectorColor: WHITE,
          softConnector: false,
          padding: 5,
          format: `{point.prettyValue} {point.name}`,
          x: 0,
          y: 0,
          style: {
            color: WHITE,
            fontSize: '11px',
            fontWeight: 'normal',
            textOutline: '0'
          }
        },
        states: {
          hover: {
            enabled: false
          }
        }
      },
      series: {
        states: {
          hover: {
            enabled: false
          }
        }
      }
    },
    tooltip: {
      enabled: false
    },
    series: [
      {
        data: params.points.map((p, i) => ({
          y: p.value,
          name: p.prettyValue,
          prettyValue: p.displayName,
          color: {
            linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
            stops: [
              [0, new Color(params.color).setOpacity(1 - i * 0.1).get('rgba')],
              [1, new Color(params.color).setOpacity(1 - (i + 1) * 0.2).get('rgba')]
            ]
          }
        })),
        minSize: params.minSize
      }
    ]
  };
}
