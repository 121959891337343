<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
-->
<div class="group__container {{ theme }}">
  <div
    *ngFor="let button of buttons; let i = index"
    class="radio-button-group__wrapper"
    [ngClass]="buttonSizeClass"
  >
    <div class="rb-wrapper" *ngIf="i < 8 || showMoreSelected">
      <div
        class="radio-button-group__button {{ buttonTheme }}"
        id="button-{{ i }}"
        (click)="onButtonSelected(button.id)"
        [class.radio-button-group__button--selected]="button.id === selectedButton"
      >
        <span>{{ button.name | uppercase }}</span>
      </div>
    </div>
  </div>
  <div
    *ngIf="buttons && buttons.length > 8"
    class="radio-button-group__button --show-more"
    (click)="onShowMoreClicked()"
  >
    <span>{{ showMoreButtonText | uppercase }}</span>
    <og-icon *ngIf="!showMoreSelected" name="chevron-down"></og-icon>
    <og-icon *ngIf="showMoreSelected" name="chevron-up"></og-icon>
  </div>
</div>
