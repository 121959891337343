<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<span fxLayout="row" fxLayoutAlign="{{ alignment }} center">
  <span
    test-ref="currencyValue"
    [class.currency-value]="subtext"
    [matTooltip]="value | currencyValue"
    matTooltipPosition="right"
  >
    {{ value ? (value | boundedCurrencyValue: bound:decimalPipeArgs) : undefinedValuePlaceholder }}
  </span>

  <og-subtext test-ref="subtext" *ngIf="subtext" [subtext]="subtext">
    <ng-content></ng-content>
  </og-subtext>
</span>
