/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { AuthService } from './auth.service';
import { HttpHeaders } from '@angular/common/http';
import { observableOf } from 'app/shared/rxjs/rxjs.utils';
import { delay } from 'rxjs/operators';
import { LOCAL_PRODUCT_DEFINITION, UserAuthModels, UserDetails, ProductType } from '@opengamma/ui';
import { carmaApiPath } from 'environments/environment.utils';

/** Mock/disabled authentication service. */
@Injectable()
export class MockAuthService implements AuthService {
  idToken = 'idToken';

  getRequestHeaders(): HttpHeaders {
    return new HttpHeaders();
  }

  constructor() {
    console.info(`
    --------------------------
    Authentication is disabled
    --------------------------
    `);
  }

  permissions(): Observable<UserAuthModels.Permissions> {
    const permissions: UserAuthModels.Permissions = {
      products: {},
      modules: {},
      screens: {},
      defaultProduct: ProductType.Carma
    };
    Object.keys(LOCAL_PRODUCT_DEFINITION(carmaApiPath())).forEach(product => {
      permissions.products[product] = 'ENABLED';
      Object.keys(LOCAL_PRODUCT_DEFINITION(carmaApiPath())[product].modules || {}).forEach(
        module => {
          permissions.modules[module] = 'ENABLED';
          Object.keys(
            LOCAL_PRODUCT_DEFINITION(carmaApiPath())[product].modules[module].screens
          ).forEach(screen => {
            permissions.screens[screen] = 'ENABLED';
          });
        }
      );
    });
    return observableOf(permissions).pipe(delay(500));
  }

  authenticate(): Observable<UserDetails> {
    return observableOf({
      name: 'John Smith',
      email: 'ui-test@opengamma.com',
      companyName: 'OpenGamma',
      organizationId: 'OPENGAMMA',
      companyTags: ['onboarding', 'prod'],
      isMfaEnabled: false,
      picture: 'assets/cool-pic.jpeg',
      roles: ['view:hidden'],
      jsccTermsConsentRequired: true
    });
  }

  unauthenticate(): void {
    console.warn('Unauthenticating');
  }

  getDemoTenantStatus(tenant: string): Observable<boolean> {
    return observableOf(false);
  }

  warmUp(): Observable<void> {
    return of();
  }
}
