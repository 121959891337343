/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconSize, IconStroke } from '@opengamma/ui';

@Component({
  selector: 'og-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss']
})
export class ModalComponent {
  /** Defines if a close icon should be displayed in the top right corner */
  @Input() displayCloseIcon: boolean;
  /** Emits modal close clicks */
  @Output() closeModalClick: EventEmitter<void> = new EventEmitter<void>();

  IconSize = IconSize;
  IconStroke = IconStroke;

  closeModal() {
    this.closeModalClick.next();
  }
}
