<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

@if (formattedRange) {
<div fxLayout="row" fxLayoutGap="8px" fxLayoutAlign="center center" class="range__dates">
  {{ formattedRange }}
</div>
}
