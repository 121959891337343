/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, EventEmitter, Output } from '@angular/core';
import { NamedValue } from '@opengamma/ui';

@Component({
  selector: 'og-dropdown-labeled-entity',
  templateUrl: './dropdown-labeled-entity.component.html'
})
export class DropdownLabeledEntityComponent {
  /** The label to display */
  @Input() label: string;
  /** The dropdown options to display */
  @Input() dropdownOptions: NamedValue<string>[];
  /** The selected option */
  @Input() selectedOption: NamedValue<string>;
  /** Defines if the dropdown contents are being loaded */
  @Input() loading: boolean;
  /** Defines if the dropdown should be disabled */
  @Input() disabled: boolean;
  /** The size of the dropdown - different settings have different width/height */
  @Input() size: 'small' | 'medium' | 'small-wide' | 'flex' = 'small';

  /** Emits dropdown selection changes */
  @Output() selectionChange = new EventEmitter<NamedValue<string>>();

  onSelectionChange(selection: NamedValue<string>) {
    this.selectionChange.next(selection);
  }
}
