/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, OnChanges } from '@angular/core';
import { DifferencePairChartParams } from './difference-pair-chart.model';
import { differencePairChartOptions } from './difference-pair-chart-util';
import { NamedValue } from '@opengamma/ui';

@Component({
  selector: 'og-difference-pair-chart',
  templateUrl: 'difference-pair-chart.component.html',
  styleUrls: ['difference-pair-chart.component.scss']
})
export class DifferencePairChartComponent implements OnChanges {
  /** Wraps the name and value of the left positioned entry above the chart. */
  @Input() leftPositionedEntry: NamedValue;
  /** Wraps the name and value of the right positioned entry above the chart */
  @Input() rightPositionedEntry: NamedValue;
  /** The difference pair chart params to use in the chart options */
  @Input() differencePairChartParams: DifferencePairChartParams;

  options: object;

  maxBoundary = 0.05;

  ngOnChanges() {
    if (!!this.differencePairChartParams && this.differencePairChartParams.percentage) {
      this.maxBoundary =
        Math.ceil((Math.abs(this.differencePairChartParams.percentage) * 100) / 5) / 20;
      this.options = differencePairChartOptions(this.differencePairChartParams, this.maxBoundary);
    }
  }
}
