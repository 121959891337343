<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

@if (chartModes) {
<div class="toggle" fxLayout="row" fxLayoutAlign="center center">
  @for (mode of chartModes; track mode; let last = $last) {
  <div
    class="toggle__item"
    [class.toggle__item--selected]="mode.value === selectedChartMode"
    (click)="onToggleChange.emit(mode.value)"
  >
    {{ mode.name }}
  </div>
  @if (!last) {
  <div class="toggle__divider"></div>
  } }
</div>
}
