<div class="modal" fxLayout="column" fxLayoutGap="16px">
  <div fxLayout="column" fxLayoutGap="16px">
    <h2 class="title">Terms and Conditions</h2>
    <div class="divider divider--accent"></div>
  </div>
  <div class="description">
    We have updated the OpenGamma
    <a class="text__link" target="_blank" [href]="jsccTermsConditionsLink">Terms and Conditions</a>
    for JSCC ETD VaR Margin Simulation Tool together with the
    <a
      class="text__link"
      target="_blank"
      href="https://opengamma.com/terms-conditions/#Privacy_Policy"
    >
      Privacy Policy
    </a>
    . By checking the box you indicate that you have read and agreed to the Terms and the Privacy
    Policy.
  </div>
  <og-checkbox-noop
    class="cell__checkbox"
    [checked]="isAccepted"
    (click)="onToggleConsent()"
    test-ref-component-id="queryfield-checkbox-noop"
  >
    I have read and agreed to the Terms and the Privacy Policy.
  </og-checkbox-noop>
  <div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="8px">
    <og-button
      (buttonClick)="onAccept()"
      [theme]="'accent'"
      [disabled]="!isAccepted || (isJSCCTCsAcceptedLoadable$ | async).loading"
      [loading]="(isJSCCTCsAcceptedLoadable$ | async).loading"
    >
      Accept
    </og-button>
  </div>
</div>
