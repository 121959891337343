/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { IconSize, SLIDE_IN } from '@opengamma/ui';

@Component({
  selector: 'og-config-tile',
  templateUrl: './config-tile.component.html',
  styleUrls: ['./config-tile.component.scss'],
  animations: [SLIDE_IN]
})
export class ConfigTileComponent {
  /** The title of the tile. */
  @Input() title: string;
  /** The status of the tile. */
  @Input() status = 'active';
  /** Defines if the cancel button should be displayed */
  @Input() showCancelButton: boolean;
  /** Defines if the loading spinner should be displayed */
  @Input() loading: boolean;
  /** The theme to use */
  @Input() theme = 'config__tile--color--fun-blue';
  /** The size to use */
  @Input() size: 'medium' | 'large' = 'medium';
  /** Defines if the tile should be hidden */
  @Input() hidden: boolean;
  /** The error to display  */
  @Input() error: string;

  /** Emits cancel button clicks */
  @Output() cancelButtonClicked: EventEmitter<void> = new EventEmitter<void>();
  /** Emits error refresh button clicks */
  @Output() errorRetryClick: EventEmitter<void> = new EventEmitter<void>();
  /** Emits error change selection clicks */
  @Output() errorChangeSelectionClick: EventEmitter<void> = new EventEmitter<void>();

  IconSize = IconSize;

  onCancelButtonClicked() {
    this.cancelButtonClicked.next();
  }

  onErrorRetryClicked() {
    this.errorRetryClick.next();
  }

  onErrorChangeSelectionClicked() {
    this.errorChangeSelectionClick.next();
  }
}
