<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<og-custom-content-labeled-entity class="details__entry" [label]="label" [size]="size">
  <div class="im-values" content>
    <span>{{ currentIm | boundedCurrencyValue: bound:'1.2-2' }}</span>
    @if (!!imChange) {
    <span
      class="im-values__left im-values__left--{{ size }}"
      [class.im-values__left--negative]="imChange.price < 0"
      [class.im-values__left--positive]="imChange.price > 0"
    >
      {{ imChange | boundedCurrencyValue: bound:'1.2-2' }}
    </span>
    }
  </div>
</og-custom-content-labeled-entity>
