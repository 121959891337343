/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'og-labeled-entity',
  templateUrl: './labeled-entity.component.html',
  styleUrls: ['./labeled-entity.component.scss']
})
export class LabeledEntityComponent {
  /** The label to display above the metric */
  @Input() label: string;
  /** The subtext to display below the metric */
  @Input() subtext: string;
  /** The size of the metrics */
  @Input() size: 'xs' | 'small' | 'large' = 'large';
  /** Style for the entity */
  @Input() theme: 'light' | 'dark' | 'accent' = 'light';
}
