<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div>
  <div [hidden]="tileHidden">
    <og-config-tile
      [hidden]="tileMinimized"
      [title]="tileTitle"
      [status]="status"
      [showCancelButton]="displayCancelButton"
      [theme]="theme"
      [error]="error"
      [size]="size"
      (cancelButtonClicked)="onCancelButtonClicked()"
      (errorRetryClick)="onErrorRetryClicked()"
      (errorChangeSelectionClick)="onErrorChangeSelectionClicked()"
    >
      @if (!portfolioValidationLoading && status !== 'COMPLETE' && !portfolioUploadingName) {
      <div class="uploader__wrapper uploader__wrapper--{{ size }}">
        <og-file-picker
          [disabled]="status === 'DISABLED'"
          [size]="size"
          (dataChange)="portfolioUploaded($event)"
        ></og-file-picker>
      </div>
      } @if ((status === 'ACTIVE' && portfolioValidationLoading) || status === 'COMPLETE') {
      <div class="uploader__wrapper" class="uploader__wrapper uploader__wrapper--{{ size }}">
        <div class="uploader-proggress__wrapper">
          @if (portfolioValidationLoading && status !== 'COMPLETE') {
          <div>
            <div class="uploading__placeholder">
              <og-icon class="upload__icon" name="upload" [size]="IconSize.Large"></og-icon>
              <p class="portfolio__uploading">UPLOADING</p>
              <p class="portfolio__filename">{{ portfolioUploadingName }}</p>
            </div>
            <og-progress-bar
              [value]="100"
              [percentage]="10"
              [displayLabels]="false"
              [animationDuration]="3000"
            ></og-progress-bar>
          </div>
          } @if ( status === 'COMPLETE' || (!!portfolioUploadingName && !portfolioValidationLoading)
          ) {
          <div>
            <div class="uploading__placeholder">
              <span class="portfolio-remove__wrapper" (click)="removeUploadedPortfolio()">
                X
              </span>
              <og-icon
                class="upload__icon"
                name="circle-check-filled"
                [size]="IconSize.XLarge"
              ></og-icon>
              <p class="portfolio__filename portfolio__filename--complete">
                {{ portfolioUploadingName }}
              </p>
            </div>
          </div>
          }
        </div>
      </div>
      }
    </og-config-tile>
  </div>
  @if (tileMinimized) {
  <og-minimised-config-tile
    [title]="minimizedTitle"
    [subject]="portfolioUploadingName"
    [theme]="theme"
  ></og-minimised-config-tile>
  }
</div>
