/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
  ViewChild
} from '@angular/core';
import {
  CellSelectionEvent,
  ColumnDescription,
  ColumnSorting,
  FormattedColumn,
  FormattedColumnDescription,
  FormattedColumnGroup,
  FormattedRow,
  MagicTableautoBoundCurrencyValue,
  MagicTableautoBoundNumber,
  MagicTableCell,
  MagicTableCellValue,
  MagicTableColumn,
  MagicTableColumnGroup,
  MagicTableColumnType,
  MagicTableDirectionalBarChart,
  MagicTableOptions,
  MagicTableRow,
  MagicTableValueSubtext,
  SortingOrder,
  ValueChangeEvent
} from './models';
import { getCellOptions } from './magic-table.utils';
import * as _ from 'lodash';
import { CurrencyValue, suffixToNumber } from '@opengamma/ui';

/** The number of additional children to show when the respective button is clicked. */
const CHILD_LOAD_INCREMENT = 20;

// -------------------------------------------------------------------------
/** Represents a table which contains hierarchical data. */
@Component({
  selector: 'og-magic-table',
  templateUrl: './magic-table.component.html',
  styleUrls: ['./magic-table.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class MagicTableComponent implements OnChanges, AfterViewInit {
  /** The table column config. */
  @Input() columnGroups: MagicTableColumnGroup[];
  /** The table rows. */
  @Input() rows: MagicTableRow[];
  /** The expanded row ID. */
  @Input() expandedRowIds?: string[] = undefined;
  /** True if multiple rows can be expanded at the same time. */
  @Input() allowMultipleExpandingRows = true;
  /** The stylistic theme of the magic table. */
  @Input() theme: 'light' | 'dark' = 'light';
  /** Makes the header stick to top when scrolling the table */
  @Input() stickyHeader = false;
  /** Makes the header stick to top when scrolling the table */
  @Input() stickyFirstRow = false;
  /** The custom height for the table headers. */
  @Input() customHeaderHeight: 'default' | 'tall' = 'default';
  /** The column descriptions to display above the headers */
  @Input() columnDescriptions?: ColumnDescription[];
  /** If true, the first row will be ignored when sorting the table  */
  @Input() ignoreFirstRowOnSorting = false;
  /** The column ID to sort by. */
  @Input() sortBy?: ColumnSorting;
  /** The maximum amount of rows to show - if not present shows all. */
  @Input() maxRows?: number;
  /** Magic table bound for numbers/currencies */
  @Input() bound?: number;

  /** Emits when a row is expanded. */
  @Output() expandedRowIdsChange = new EventEmitter<string[]>();
  /** Emits when a cell is clicked. */
  @Output() cellClicked = new EventEmitter<MagicTableCell>();
  /** Emits when an editable cell is changed. */
  @Output() cellEdited = new EventEmitter<MagicTableCellValue>();
  /** Emits when the user sorts by a different column. */
  @Output() sortByChange = new EventEmitter<ColumnSorting>();

  @ViewChild('table') table: ElementRef;

  // -------------------------------------------------------------------------
  expandedIndexes: number[] = [];
  formattedData: FormattedColumnGroup[];

  // id -> index
  idIndices = {};
  // index -> id
  indexIds = {};
  // columnId -> formattedColumn
  idColumns: { [id: string]: FormattedColumn } = {};

  // The number of displayed children for a given row index.
  displayedChildCount = [];

  editingRow: FormattedRow;
  editingColumn: MagicTableColumn;
  searchableColumnIndexes: number[] = [];

  filteredRows: MagicTableRow[];
  filterTerm = '';

  expandable: boolean;
  rowCounter = 0;

  columnSortingOrder: SortingOrder;
  columnToSort: FormattedColumn;

  formattedColumnDescriptions: FormattedColumnDescription[] = [];

  constructor(private changeDetection: ChangeDetectorRef) {}

  /** Returns true if any rows have children. */
  isExpandable(): boolean {
    return this.rows.some(row => !_.isEmpty(row.children));
  }

  getColumnGroupStyles({ flexBasis, flexGrow }: FormattedColumnGroup): object {
    const columnGroupStyles = {
      'flex-basis': flexBasis,
      'flex-grow': flexGrow
    };

    if (flexGrow === 0) {
      columnGroupStyles['min-width'] = '0px';
    }

    return columnGroupStyles;
  }

  getColumnGroupWidth(customOptions: MagicTableOptions): object {
    if (!!customOptions && customOptions.width) {
      return {
        flex: `0 0 ${customOptions.width}px`
      };
    }
    return undefined;
  }

  getCustomColumnHeaderStyles(customOptions: MagicTableOptions): object {
    const getBorderStyles = () =>
      !!customOptions.border
        ? {
            'border-left': customOptions.border.borderLeft,
            'border-right': customOptions.border.borderRight,
            'border-top': customOptions.border.borderTop,
            'border-bottom': customOptions.border.borderBottom
          }
        : {};

    return !!customOptions
      ? {
          'white-space': customOptions.whiteSpace,
          'background-color': customOptions.backgroundColor,
          color: customOptions.color,
          'font-size': customOptions.fontSize,
          height: customOptions.height + 'px',
          ...getBorderStyles()
        }
      : { 'white-space': 'nowrap' };
  }

  /** Returns true if all column groups have no label. */
  areAllGroupsUnlabelled(): boolean {
    return this.columnGroups.every(group => !group.label);
  }

  /** Called when a given row needs to display more children. */
  onDisplayMoreChildren(index: number) {
    const copy = [...this.displayedChildCount];
    copy[index] += CHILD_LOAD_INCREMENT;
    this.displayedChildCount = copy;
  }

  ngOnChanges(changes: SimpleChanges) {
    // TODO: find a less hacky solution to this
    // Prevent table rerendering when only expanded rows are changed
    if (!(_.size(changes) === 1 && changes.expandedRowIds) && this.columnGroups && this.rows) {
      let colIndex = -1;
      this.searchableColumnIndexes = _.flatMap(this.columnGroups, ({ columns }) =>
        columns
          .map(column => ({ ...column, index: ++colIndex }))
          .filter(({ isSearchable }) => isSearchable)
          .map(({ index }) => index)
      );

      this.expandable = this.rows.some(row => !!row.children);

      this.updateFilter();

      // if columns haven't changed, maintain sorting
      if (!this.sortBy) {
        this.formattedData.forEach(group =>
          group.columns.forEach(column => {
            if (column.defaultSorting) {
              this.sortColumn(column, column.defaultSorting);
            }
          })
        );
      } else {
        this.sortColumn(this.idColumns[this.sortBy.id], this.sortBy.order);
      }
    }

    // if only the rows change and there is sorting set, resort them
    if (
      _.size(changes) === 1 &&
      changes.rows &&
      this.columnGroups &&
      this.rows &&
      this.columnToSort &&
      this.columnSortingOrder
    ) {
      this.sortColumn(this.columnToSort, this.columnSortingOrder);
    }

    // Would expand the table if an expandedRowId is pushed from the client
    // i.e. navigation to /capital/detail/:id
    if (this.expandedRowIds && this.formattedData) {
      this.expandedIndexes = (<(ids: number[][]) => number[]>_.spread(_.union))(
        this.expandedRowIds
          .map(id => this.findPathToIndex(this.idIndices[id]))
          .filter(value => value !== undefined)
      );
    }
  }

  /**
   * Loops through the formatted column groupings constructing column descriptions.
   * In order to set the proper width of each column description the width of each column grouping is used.
   * If a column grouping has no description a placeholder description is created
   * with the width of the column grouping.
   * Otherwise, the width of the column grouping is added to the sum of all the other column groupings which
   * are part of the same column description.
   *
   * @returns the column descriptions constructed
   */
  private constructColumnDescriptions(): FormattedColumnDescription[] {
    const columnDescriptions: FormattedColumnDescription[] = [];

    const constructEmptyColumnDescription = () => ({
      backgroundColor: undefined,
      width: 0,
      label: undefined
    });

    const formatStyles = (
      columnDescription: FormattedColumnDescription
    ): FormattedColumnDescription => ({
      ...columnDescription,
      styles: {
        'background-color': columnDescription.backgroundColor,
        width: columnDescription.width + 'px'
      }
    });

    const getWidthOfColumnGroup = (columnGroupIndex): number =>
      this.table.nativeElement.querySelector(`.table__group--${columnGroupIndex}`).clientWidth;

    const groupIndexToColumnDescription = [];
    this.columnDescriptions.forEach(
      columnDescription =>
        (groupIndexToColumnDescription[columnDescription.startColumn] = columnDescription)
    );

    let placeholderDescription: FormattedColumnDescription = constructEmptyColumnDescription();

    for (let i = 0; i < this.columnGroups.length; i++) {
      if (!!groupIndexToColumnDescription[i]) {
        if (placeholderDescription.width > 0) {
          columnDescriptions.push(formatStyles(placeholderDescription));
          placeholderDescription = constructEmptyColumnDescription();
        }

        const columnDescription = groupIndexToColumnDescription[i];
        columnDescriptions.push(
          formatStyles({
            ...columnDescription,
            width: _.range(columnDescription.startColumn, columnDescription.endColumn + 1).reduce(
              (acc, curr) => acc + getWidthOfColumnGroup(curr),
              0
            )
          })
        );

        i = columnDescription.endColumn;
        continue;
      }

      placeholderDescription.width += getWidthOfColumnGroup(i);
    }

    return columnDescriptions;
  }

  ngAfterViewInit() {
    if (!!this.columnDescriptions) {
      this.formattedColumnDescriptions = this.constructColumnDescriptions();

      // This is required since the digest loop does not run after state changes in ngAfterViewInit
      this.changeDetection.detectChanges();
    }
  }

  /**
   * Sorts all the rows(and their children) in a column based on the current sortingOrder
   *
   * First all the rows on each level are sorted and then a map is used to keep their pre and post sort indexes
   * Then the new row indexes are computed, the rows are reordered based on the new ordering
   *
   * @param columnToSort the column to sort
   * @param sortOrderOverride the sort order to force; otherwise default to toggle
   */
  sortColumn(columnToSort: FormattedColumn, sortOrderOverride?: SortingOrder) {
    const rowIndexToSortedIndex = [];

    const previousSortingOrder = this.columnSortingOrder;
    if (sortOrderOverride) {
      this.columnSortingOrder = sortOrderOverride;
    } else if (this.columnToSort && columnToSort.index !== this.columnToSort.index) {
      this.columnSortingOrder = 'asc';
    } else {
      this.columnSortingOrder = this.columnSortingOrder === 'asc' ? 'desc' : 'asc';
    }

    this.computeSortedRowIndexes(
      columnToSort.rows,
      rowIndexToSortedIndex,
      columnToSort.type,
      this.columnSortingOrder
    );

    this.formattedData = this.formattedData.map(formattedDataEntry => ({
      ...formattedDataEntry,
      columns: formattedDataEntry.columns.map(column => ({
        ...column,
        rows: this.sortRows(column.rows, rowIndexToSortedIndex)
      }))
    }));

    if (
      _.isEqual(this.columnToSort, columnToSort) &&
      _.isEqual(this.columnSortingOrder, previousSortingOrder)
    ) {
      return;
    }

    // don't emit undefined IDs
    if (columnToSort.id) {
      this.sortByChange.next({
        id: columnToSort.id,
        order: this.columnSortingOrder
      });
    }

    this.columnToSort = columnToSort;
  }

  private computeSortedRowIndexes(
    rows: FormattedRow[],
    rowIndexToSortedIndex: number[],
    columnType: MagicTableColumnType,
    columnSortingOrder: SortingOrder
  ) {
    let sortedRows;

    const ignoreFirstRow = !_.isEmpty(rows) && rows[0].index === 1 && this.ignoreFirstRowOnSorting;

    const rowValue = row => (row.value && row.value.subtext ? row.value.value : row.value);

    const rowValueGetterMap = {
      currency: row => {
        const value = <CurrencyValue>rowValue(row);
        return value !== undefined ? value.price : Number.NEGATIVE_INFINITY;
      },
      autoBoundCurrency: row => {
        const value = <CurrencyValue>rowValue(row);
        return value !== undefined ? value.price : Number.NEGATIVE_INFINITY;
      },
      fixedBoundCurrency: row => {
        const value = <MagicTableautoBoundCurrencyValue>rowValue(row);
        return value !== undefined ? value.value.price : Number.NEGATIVE_INFINITY;
      },
      directionalBarChart: row => {
        const value = <MagicTableDirectionalBarChart>rowValue(row);
        return value !== undefined
          ? value.direction === 'left'
            ? -value.value
            : value.value
          : Number.NEGATIVE_INFINITY;
      }
    };

    const sortingOrder = columnSortingOrder === 'none' ? 'asc' : columnSortingOrder;
    sortedRows = _.orderBy(
      ignoreFirstRow ? _.tail(rows) : rows,
      row => (rowValueGetterMap[columnType] ? rowValueGetterMap[columnType](row) : row.value),
      [sortingOrder]
    );

    if (ignoreFirstRow) {
      sortedRows.unshift(rows[0]);
    }

    sortedRows.forEach((row, index) => (rowIndexToSortedIndex[row.index] = index));

    sortedRows
      .filter(sortedRow => !!sortedRow.children)
      .forEach(({ children }) =>
        this.computeSortedRowIndexes(
          children,
          rowIndexToSortedIndex,
          columnType,
          columnSortingOrder
        )
      );
  }

  private sortRows(rows: FormattedRow[], rowIndexToSortedIndex: number[]): FormattedRow[] {
    const sortedRows = [];

    rows.forEach(row => (sortedRows[rowIndexToSortedIndex[row.index]] = row));

    return sortedRows.map(sortedRow =>
      !!sortedRow.children
        ? {
            ...sortedRow,
            children: this.sortRows(sortedRow.children, rowIndexToSortedIndex)
          }
        : sortedRow
    );
  }

  onCellClick({ row, column }: CellSelectionEvent) {
    if (
      (!column.options || !column.options.disableExpansionOnClick) &&
      row.children &&
      row.children.length
    ) {
      if (this.expandedIndexes.includes(row.index)) {
        this.removeExpandedRowWithChildren(row);
      } else {
        if (this.allowMultipleExpandingRows) {
          this.expandedIndexes = [...this.expandedIndexes, row.index];
        } else {
          this.expandedIndexes = this.findPathToIndex(row.index);
        }
      }

      this.expandedRowIdsChange.emit(this.expandedIndexes.map(index => this.indexIds[index]));
    }

    this.cellClicked.emit({
      rowId: row.id,
      columnId: column.id
    });

    // Enable editing if the cell is editable
    if (this.editingColumn !== column || this.editingRow !== row) {
      const options = getCellOptions(column, row);
      if (options.editable) {
        this.editingRow = row;
        this.editingColumn = column;
      } else {
        this.editingRow = undefined;
        this.editingColumn = undefined;
      }
    }
  }

  /**
   * Updates this.expandedIndexes, removing a row and all of its children from it
   *
   * @param row - the row to remove
   */
  private removeExpandedRowWithChildren(row: FormattedRow) {
    const expandedSubRowIndexes = this.findExpandedSubRowIndexes(row);

    this.expandedIndexes = this.expandedIndexes.filter(
      expandedIndex => !expandedSubRowIndexes.includes(expandedIndex)
    );
  }

  /**
   * Returns the array of all children of the input row who are expanded
   *
   * @param row - the row to find expanded children of
   */
  private findExpandedSubRowIndexes(row: FormattedRow): number[] {
    if (!row.children && this.expandedIndexes.indexOf(row.index) === -1) {
      return [];
    }

    const expandedIndexes = _.flatMap(row.children, childRow =>
      this.findExpandedSubRowIndexes(childRow)
    );

    return [row.index, ...expandedIndexes];
  }

  onValueEdited({ row, column, value }: ValueChangeEvent) {
    this.cellEdited.emit({
      cell: {
        rowId: row.id,
        columnId: column.id
      },
      value: value === row.value ? undefined : value
    });
    this.editingRow = undefined;
    this.editingColumn = undefined;
  }

  updateFilter() {
    if (this.filterTerm.length === 0) {
      this.filteredRows = this.rows;
    } else {
      this.filteredRows = this.rows.filter(
        ({ values }) =>
          -1 !==
          this.searchableColumnIndexes
            .map(index => values[index])
            .map(value => value.toString().toLowerCase())
            .findIndex(searchableValue => searchableValue.startsWith(this.filterTerm.toLowerCase()))
      );
    }

    this.formatData();
  }

  /**
   * Formats rows, indexing the rows in depth-first order, e.g. :
   * 1
   *  2
   *   3
   *   4
   *  5
   * 6
   *  7
   *    8
   */
  private formatData() {
    let colIndex = -1;
    this.formattedData = this.columnGroups.map(group => ({
      ...group,
      columns: group.columns.map(column => {
        colIndex++;
        this.rowCounter = 0;
        const formattedColumn = {
          ...column,
          isExpandable: colIndex === 0 && this.expandable,
          isSortable: column.isSortable,
          index: colIndex,
          rows: this.filteredRows.map(row => {
            return this.formatRow(colIndex, -1, row, 0, row.id);
          }),
          bound: this.bound || this.calculateBound(this.rows, colIndex, column.type),
          maxBarValue:
            column.type === 'directionalBarChart'
              ? this.calculateMaxBarValue(this.rows, colIndex)
              : undefined
        };
        if (column.id) {
          this.idColumns[column.id] = formattedColumn;
        }
        return formattedColumn;
      })
    }));
  }

  // Returns the bound to use given a set of row and the index of the bounded currency values
  private calculateBound(
    rows: MagicTableRow[],
    index: number,
    columnType: MagicTableColumnType
  ): number {
    const getValue = (row, i) =>
      (row.values[i] as MagicTableValueSubtext) && (row.values[i] as MagicTableValueSubtext).subtext
        ? row.values[i].value
        : row.values[i];

    switch (columnType) {
      case 'autoBoundNumber':
        return this.maxInColumn(rows, row => Math.abs(+getValue(row, index)) || 0);
      case 'fixedBoundNumber':
        return this.maxInColumn(rows, row =>
          getValue(row, index)
            ? suffixToNumber((<MagicTableautoBoundNumber>getValue(row, index)).bound)
            : 0
        );
      case 'fixedBoundCurrency':
        return this.maxInColumn(rows, row =>
          getValue(row, index)
            ? suffixToNumber((<MagicTableautoBoundCurrencyValue>getValue(row, index)).bound)
            : 0
        );
      case 'autoBoundCurrency':
        return this.maxInColumn(rows, row =>
          getValue(row, index) ? (<CurrencyValue>getValue(row, index)).price : 0
        );
    }

    return 0;
  }

  // Returns the maximum bar value in a column, used to scale the bars
  private calculateMaxBarValue(rows: MagicTableRow[], index: number): number {
    return this.maxInColumn(rows, row => (<MagicTableDirectionalBarChart>row.values[index]).value);
  }

  // returns the maximum across a given column, searched recursively through all the children of that column.
  // the maximum is found by applying a transformer that maps the row to the value in the row that should be compared
  private maxInColumn(rows: MagicTableRow[], transformer: (row: MagicTableRow) => number): number {
    if (rows && rows.length > 0) {
      return Math.max(
        ...rows.map(row =>
          Math.max(Math.abs(transformer(row)), this.maxInColumn(row.children, transformer))
        )
      );
    }
    return 0;
  }

  private formatRow(
    colIndex: number,
    level: number,
    row: MagicTableRow,
    parentIndex: number,
    parentId: string
  ): FormattedRow {
    const currentRowIndex = ++this.rowCounter;
    this.displayedChildCount[currentRowIndex] = CHILD_LOAD_INCREMENT;
    this.idIndices[row.id] = currentRowIndex;
    this.indexIds[currentRowIndex] = row.id;
    level++;
    return {
      index: currentRowIndex,
      value: row.values[colIndex],
      level,
      parentIndex,
      parentId: level === 0 ? undefined : parentId,
      temporaryValue: !row.temporaryValues ? undefined : row.temporaryValues[colIndex],
      options: row.options,
      id: row.id,
      children: !row.children
        ? []
        : row.children.map(childRow =>
            this.formatRow(colIndex, level, childRow, currentRowIndex, row.id)
          ),
      message: row.message,
      cellStyles: row.cellStyles
    };
  }

  // -------------------------------------------------------------------------
  // returns the path to an index from the given row, or undefined if it doesn't exist.
  private findPathToIndexInRow(row: FormattedRow, target: number): number[] | undefined {
    if (row.index === target) {
      return [target];
    }
    if (_.isEmpty(row.children)) {
      return undefined;
    }

    const path = row.children
      .map(child => this.findPathToIndexInRow(child, target))
      .find(value => value !== undefined);

    if (path !== undefined) {
      return [row.index, ...path];
    }

    return undefined;
  }

  // returns the path to a row with the given index, including the row id.
  private findPathToIndex(index: number): number[] {
    return (
      this.formattedData[0].columns[0].rows
        .map(row => this.findPathToIndexInRow(row, index))
        .find(value => value !== undefined) || []
    );
  }

  filterRows(column: FormattedColumn): FormattedRow[] {
    return this.maxRows !== undefined
      ? column.rows.slice(0, Math.min(column.rows.length, this.maxRows))
      : column.rows;
  }
}
