/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  Component,
  ChangeDetectionStrategy,
  Input,
  EventEmitter,
  Output,
  OnInit,
  ChangeDetectorRef
} from '@angular/core';
import { BOUNDS, SelectOption, NumericBound } from '@opengamma/ui';

/**
 * If this component is to be used within TableWrapperComponent, but with its functionality handled outside of
 *     TableWrapperComponent, then you must set ** exportFunctionality = true **.
 */
@Component({
  selector: 'og-table-bounds-select',
  templateUrl: './table-bounds-select.component.html',
  styleUrls: ['./table-bounds-select.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableBoundsSelectComponent implements OnInit {
  bounds: SelectOption<NumericBound>[];
  shortBound: string;

  @Output() selectedBoundChange = new EventEmitter<NumericBound>();

  /** Prevent TableWrapperComponent from handling this components logic. */
  @Input() exportFunctionality = false;

  _selectedBound: NumericBound;
  @Input()
  set selectedBound(selectedBound: NumericBound) {
    if (selectedBound) {
      this._selectedBound = selectedBound;
      this.setShortBound();
      this.cdRef.markForCheck();
    }
  }
  get selectedBound(): NumericBound {
    return this._selectedBound;
  }

  constructor(private cdRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this.setBounds();
    if (!this.selectedBound) {
      this.selectedBound = 1e6;
    }
    this.setShortBound();
  }

  onBoundSelected(bound: NumericBound): void {
    this.selectedBound = bound;
    this.selectedBoundChange.emit(bound);
  }

  private setBounds(): void {
    this.bounds = BOUNDS.map(divider => ({
      value: divider.value,
      text: divider.description
    }));
  }

  private setShortBound(): void {
    this.shortBound = BOUNDS.find(divider => divider.value === this.selectedBound).suffix;
  }
}
