<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

@if (isValidPipeArgument(value)) {
<span [matTooltip]="value | percent: decimalPipeArgs" matTooltipPosition="right">
  {{ value | percent: decimalPipeArgs }}
  @if (subtext) {
  <og-subtext [subtext]="subtext"></og-subtext>
  }
</span>
}
