/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Input, Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'og-input-labeled-entity',
  templateUrl: './input-labeled-entity.component.html',
  styleUrls: ['./input-labeled-entity.component.scss']
})
export class InputLabeledEntityComponent {
  /** The label to display */
  @Input() label: string;
  /** The value to display */
  @Input() value: any;
  /** The placeholder to display */
  @Input() placeholder: string;
  /** The size of the input */
  @Input() size: 'small' | 'medium' | 'large' = 'large';
  /** Defines the type of input to use */
  @Input() inputType: 'text' | 'number' = 'text';
  /** Defines the increase/decrease step amount, valid only for inputType 'number' */
  @Input() step: number;

  /** Emits on value changes */
  @Output() valueChange = new EventEmitter<any>();

  focused = false;

  onInputChange(value: string) {
    this.valueChange.next(value);
  }
}
