/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, ChangeDetectionStrategy, Output, EventEmitter } from '@angular/core';
import { IconSize } from '@opengamma/ui';

@Component({
  selector: 'og-summary-card',
  templateUrl: './summary-card.component.html',
  styleUrls: ['./summary-card.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryCardComponent {
  isCollapsed = true;

  @Input() title: string;
  @Input() isSelected: boolean;

  @Output() onRightArrowClick = new EventEmitter();

  IconSize = IconSize;

  getCollapseIcon(): string {
    return `chevron-${this.isCollapsed ? 'down' : 'up'}`;
  }
}
