/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { Component, Input } from '@angular/core';
import { SLIDE_IN } from '@opengamma/ui';

@Component({
  selector: 'og-minimised-config-tile',
  templateUrl: './minimised-config-tile.component.html',
  styleUrls: ['./minimised-config-tile.component.scss'],
  animations: [SLIDE_IN]
})
export class MinimisedConfigTileComponent {
  /** The title to display */
  @Input() title: string;
  /** The subject to display */
  @Input() subject: string;
  /** The theme to use */
  @Input() theme;
}
