<!--
  ~ Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div
  class="container container--{{ theme }}"
  [ngClass]="displayScreenMenu ? 'sub-header-height' : 'sub-header-height--sm'"
>
  <div
    *ngIf="displayScreenMenu"
    class="container__content container__content--{{ pageLayout$ | async }}"
    fxLayout="row"
    fxLayoutAlign="space-between center"
  >
    <div>
      <ng-content></ng-content>
    </div>
    <div fxLayout="row" fxLayoutGap="12px">
      <ng-container *ngIf="displayDatepicker">
        <ng-template [cdkPortalOutlet]="datepickerPortal$ | async"></ng-template>
      </ng-container>
      <og-file-viewer-button
        *ngIf="displayFileViewerButton && !(areFilesLoading$ | async)"
        (click)="onFileViewerSidePanelToggle()"
        type="menu"
      >
        <og-icon name="folder-open" class="file-viewer__toggle-button-icon"></og-icon>
      </og-file-viewer-button>
    </div>
  </div>
</div>
<og-file-side-panel></og-file-side-panel>
