<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div fxLayout="row" class="title-bar__metadata" fxLayoutAlign="center space-between">
  <ng-content select="og-timeseries-legend"></ng-content>

  <ng-content select="og-timeseries-multi-select"></ng-content>
  <ng-content select="og-timeseries-pills"></ng-content>

  <ng-content select="og-timeseries-range"></ng-content>

  <ng-content select="og-timeseries-toggle"></ng-content>

  <ng-content select="og-timeseries-export-button"></ng-content>
</div>
