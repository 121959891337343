/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IconSize, IconStroke } from '@opengamma/ui';

/**
 * A panel intended to provide consistent formatting for distinct sections of content
 * along pages.
 *
 * Can be optionally collapsible.
 *
 * An `inline` content selector can be used to provide configuration on the title
 * line of the panel.
 */
@Component({
  selector: 'og-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent {
  /** The title of the panel. */
  @Input() title?: string;

  /** True if the user can collapse the panel. */
  @Input() isUserCollapsible = false;

  /** True if the panel is expanded. */
  @Input() isExpanded = true;

  @Output() isExpandedChange = new EventEmitter<boolean>();

  IconSize = IconSize;
  IconStroke = IconStroke;

  onHeaderClick() {
    if (this.isUserCollapsible) {
      this.isExpanded = !this.isExpanded;
      this.isExpandedChange.emit(this.isExpanded);
    }
  }
}
