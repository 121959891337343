/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'og-custom-content-labeled-entity',
  templateUrl: './custom-content-labeled-entity.component.html'
})
export class CustomLabeledEntityComponent {
  /** The label to display */
  @Input() label: string;
  /** The size of the metrics */
  @Input() size: 'small' | 'large' = 'large';
}
