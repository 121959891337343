<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<ng-template
  [ngTemplateOutlet]="table"
  [ngTemplateOutletContext]="{
    $implicit: {
      columnGroups: columnGroups,
      columns: getColumnsFromColumnGroups(columnGroups),
      rows: rows$ | async,
      level: 0,
      path: ['']
    }
  }"
></ng-template>

<!-- Table Template -->
<ng-template #table let-table>
  <table class="table">
    <thead class="table__head">
      @if (table.columnGroups.length > 1) {
      <tr class="table__header-row">
        @for (columnGroup of table.columnGroups; track columnGroup; let last = $last) {
        <th
          class="table__header-cell"
          [class.table__header-cell--no-border]="last"
          [class.table__header-cell--no-padding]="!columnGroup.label"
          [attr.colspan]="columnGroup.columns.length"
        >
          <div fxLayout="row">
            <div class="cell__content" fxLayout="row" fxFlex="grow" fxLayoutAlign="center center">
              <ng-template
                [ngTemplateOutlet]="columnTitle"
                [ngTemplateOutletContext]="{
                  $implicit: { label: columnGroup.label }
                }"
              ></ng-template>
            </div>
          </div>
        </th>
        }
      </tr>
      }

      <tr class="table__header-row">
        @for (column of table.columns; track column; let last = $last) {
        <th
          class="table__header-cell"
          [class.table__header-cell--no-border]="last"
          [class.checkbox-column]="last && column.id === 'export'"
          [class.table__header-cell--inner]="table.level !== 0"
        >
          <ng-template
            [ngTemplateOutlet]="columnTitle"
            [ngTemplateOutletContext]="{
              $implicit: { label: column.label }
            }"
          ></ng-template>
        </th>
        }
      </tr>
    </thead>

    <tbody class="table__body">
      <ng-template
        [ngTemplateOutlet]="tableRows"
        [ngTemplateOutletContext]="{
          $implicit: {
            rows: table.rows,
            columns: table.columns,
            level: table.level,
            path: table.path
          }
        }"
      ></ng-template>
    </tbody>
  </table>
</ng-template>

<!-- Column Title Template -->
<ng-template #columnTitle let-title>
  @if (title.tooltip) {
  <div [matTooltip]="title.tooltip" class="table__header-cell-icon">
    <og-icon name="question-circle"></og-icon>
  </div>
  }
  {{ title.label }}
</ng-template>

<!-- Rows Template -->
<ng-template #tableRows let-node>
  @for ( row of node.rows.slice(0, getNumberOfRowsToDisplayForPath(node.path)); track
  trackRows($index, row)) {
  <tr
    class="table__row"
    [class.table__row--expandable]="row?.isExpandable"
    [class.table__row--border-bottom]="node.level == 0"
  >
    @for (column of node.columns; track column; let first = $first; let last = $last) {
    <td
      class="table__cell"
      (click)="column.type !== 'export' && row?.isExpandable && onRowExpand(row.id)"
      [class.checkbox-column]="last && column.id === 'export'"
      [class.table__cell--no-border-right]="last"
    >
      <div
        class="table__cell-content"
        fxLayout="row"
        [fxLayoutAlign]="first ? 'start center' : 'center center'"
      >
        @if (first) {
        <div class="table__expansion-icon">
          @if (row?.isExpandable) {
          <og-icon
            [name]="row?.isExpanded ? 'minus' : 'plus'"
            [stroke]="IconStroke.XThick"
          ></og-icon>
          }
        </div>
        } @if (getCellForTemplate(row, column).type !== 'export') {
        <og-table-cell
          [cell]="getCellForTemplate(row, column)"
          [bound]="column?.options?.columnBound || bound"
          [options]="column.options"
        ></og-table-cell>
        } @else {
        <og-checkbox-noop
          class="cell__checkbox"
          [checked]="isChecked((row?.values)[column.id], row)"
          [indeterminate]="isIndeterminate(row)"
          (click)="onCheck((row?.values)[column.id], row)"
        ></og-checkbox-noop>
        }
      </div>
    </td>
    }
  </tr>
  <!-- Inner table -->
  @if (row.children) {
  <tr class="table__row">
    <td [attr.colspan]="getColumnsFromColumnGroups(columnGroups).length">
      <ng-template
        [ngTemplateOutlet]="table"
        [ngTemplateOutletContext]="{
          $implicit: {
            columnGroups: row.children.columnGroups,
            columns: getColumnsFromColumnGroups(row.children.columnGroups),
            rows: row.children.rows,
            level: node.level + 1,
            path: node.path.concat(row.id)
          }
        }"
      ></ng-template>
    </td>
  </tr>
  }
  <!-- Vertical pagination -->
  } @if (getNumberOfRowsToDisplayForPath(node.path); as numberOfRowsToDisplay) { @if (!node.isTotal
  && node.rows && node.rows.length > numberOfRowsToDisplay) {
  <tr
    class="table__row table__row--show-more table__row--{{ node.level }}"
    (click)="onShowMoreRows(node.path)"
    test-ref="show-more-row"
  >
    <td
      class="table__cell table__cell--show-more table__cell--no-border-right"
      [attr.colspan]="node.columns.length"
    >
      <div
        class="table__show-more table__show-more--{{ node.level }}"
        fxLayout="row"
        fxLayoutAlign="space-between center"
      >
        <div class="show-more__call-to-action" fxLayout="row">
          <div class="table__expansion-icon">
            <og-icon name="chevron-down"></og-icon>
          </div>
          <div>Show {{ rowPaginationCount }} more</div>
        </div>
        <div class="show-more__info">
          Showing
          <span class="show-more__row-count">
            {{ numberOfRowsToDisplay }}
          </span>
          rows of
          <span class="show-more__row-count">{{ node.rows.length }}</span>
        </div>
      </div>
    </td>
  </tr>
  } }
</ng-template>
