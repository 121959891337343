/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, EventEmitter, Output, ChangeDetectionStrategy } from '@angular/core';
import { SummaryCard } from 'app/shared/components/card/summary-card/summary-card.model';

@Component({
  selector: 'og-summary-cards-screen-engine',
  templateUrl: './summary-cards-screen-engine.component.html',
  styleUrls: ['./summary-cards-screen-engine.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SummaryCardsScreenEngineComponent {
  @Input() title: string;
  @Input() cards: SummaryCard[];
  @Input() selectedCardId: string;
  @Input() areCardsLoading: boolean;

  @Output() onSelectCard = new EventEmitter();

  selectCardTrigger(card: SummaryCard): void {
    this.onSelectCard.emit(card);
  }
}
