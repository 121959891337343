/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { WHITE, SILVER, BOULDER } from 'app/shared/utils/colors';
import * as Highcharts from 'highcharts/highstock';

export const defaultOptions: Highcharts.Options = {
  lang: {
    numericSymbols: ['k', 'm', 'b', 't']
  },
  // -------------------------------------------------------------------------
  tooltip: {
    shared: true,
    split: true,
    hideDelay: 100,
    xDateFormat: '%e %b, %Y'
  },
  // -------------------------------------------------------------------------
  chart: {
    backgroundColor: 'transparent',
    style: {
      fontFamily: 'Roboto, ArialMT, Arial',
      fontSize: '12px',
      fontWeight: '500'
    },
    reflow: true
  },
  // -------------------------------------------------------------------------
  credits: {
    enabled: false
  },
  exporting: {
    enabled: false
  },
  title: {
    text: undefined
  },
  // -------------------------------------------------------------------------
  legend: {
    enabled: false,
    itemStyle: {
      color: SILVER,
      fontWeight: 'normal'
    },
    itemHoverStyle: {
      color: WHITE,
      fontWeight: 'normal'
    },
    itemHiddenStyle: {
      color: BOULDER,
      fontWeight: 'normal'
    },
    itemMarginBottom: 8,
    align: 'center',
    layout: 'horizontal',
    verticalAlign: 'bottom',
    padding: 0,
    margin: 8,
    symbolRadius: 0
  },
  // -------------------------------------------------------------------------
  xAxis: {
    title: {
      text: undefined
    },
    gridZIndex: 0,
    gridLineWidth: 1,
    gridLineDashStyle: 'Dash',
    gridLineColor: 'rgba(255, 255, 255, 0.05)',
    minorGridLineWidth: 1,
    minorGridLineDashStyle: 'Dash',
    minorGridLineColor: 'rgba(255, 255, 255, 0.02)',
    tickWidth: 0,
    lineWidth: 0,
    lineColor: 'rgb(93, 101, 126)',
    labels: {
      style: {
        fontSize: '12px',
        color: 'rgba(255, 255, 255, 0.5)'
      },
      padding: 15
    },
    crosshair: true,
    tickPixelInterval: 150
  },
  // -------------------------------------------------------------------------
  yAxis: {
    title: {
      text: undefined
    },
    gridZIndex: 0,
    gridLineWidth: 1,
    gridLineDashStyle: 'Solid',
    gridLineColor: 'rgba(255, 255, 255, 0.05)',
    minorGridLineWidth: 1,
    minorGridLineDashStyle: 'Solid',
    minorGridLineColor: 'rgba(255, 255, 255, 0.02)',
    lineWidth: 0,
    lineColor: 'rgb(93, 101, 126)',
    labels: {
      style: {
        fontSize: '12px',
        color: 'rgba(255, 255, 255, 0.5)'
      },
      padding: 15
    }
  }
};
