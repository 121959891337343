/*
 * Copyright (C) 2019 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { AuthService } from 'app/user-auth/services/auth/auth.service';
import { isAnalyticsRequest } from 'app/core/http.utils';

/** Interceptor for caching data. */
@Injectable()
export class AuthenticateInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    if (isAnalyticsRequest(req.url)) {
      return next.handle(req);
    }
    if (req.headers.get('og-skip-authorization') === 'true') {
      req.headers.delete('og-skip-authorization');
      return next.handle(req);
    }

    const authHeaders = this.authService.getRequestHeaders();

    let headers = req.headers;
    if (authHeaders) {
      authHeaders.keys().forEach(key => {
        headers = headers.set(key, authHeaders.get(key));
      });
    }

    return next.handle(req.clone({ headers: headers })).pipe(
      catchError(error => {
        if (error.status === 401) {
          return this.authService.authenticate().pipe(
            switchMap(() => {
              return next.handle(req).pipe(
                catchError(e => {
                  this.authService.unauthenticate();
                  return throwError(e);
                })
              );
            })
          );
        }
        return throwError(error);
      })
    );
  }
}
