<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<og-autocomplete
  (filterChange)="onFilterChangeEvent($event)"
  [value]="filter"
  [hint]="hint"
  theme="config-bar"
  fxFlex
></og-autocomplete>
