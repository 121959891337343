/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, ChangeDetectionStrategy } from '@angular/core';
import { IconSize, UserAuthModels } from '@opengamma/ui';

@Component({
  selector: 'og-screens-menu-items',
  templateUrl: './screens-menu-items.component.html',
  styleUrls: ['./screens-menu-items.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ScreensMenuItemsComponent {
  @Input() screens: UserAuthModels.AvailableScreen[];
  @Input() productUrl: string;
  @Input() moduleUrl: string;

  IconSize = IconSize;

  getScreenRoute(screenUrl: string): string[] {
    return [this.productUrl, this.moduleUrl, screenUrl].filter(
      urlSegment => urlSegment !== undefined
    );
  }
}
