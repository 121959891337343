/*
 * Copyright (C) 2022 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { getScreensWithSelectableRunsApiPaths } from 'app/user-auth/services/auth/screen.path.utils';
import { FromScreenStatusActions } from '@opengamma/ui';

/**
 * Interceptor for adding the `calcRunId` query parameter to Carma screens' API calls.
 *
 * This interceptor was added as an alternative to adding the calcRunId to every single screen's
 * API call in the screen's API effects. The complexity lies in the Screen Engine screens such as
 * Track > Change, Monitor > Simm and Forecast > Scenarios (amongst others). Here, we check if the
 * screen is eligible for appending the URL parameter. Screens which have the parameter appended
 * will be requesting data for a specific calc run's output, instead of always requesting the
 * latest data for a given screen.
 *
 * This parameter only needs to be appended to API calls for screens which have the feature enabled,
 * so we compare the request URL to the global set of base API paths we want to select runs for
 * (which can be found in {@link LOCAL_PRODUCT_DEFINITION}).
 */
@Injectable()
export class SelectedRunQueryParamInterceptor implements HttpInterceptor {
  selectedCalcRunId: string;
  screensWithSelectableRuns: string[];

  constructor(actions: Actions) {
    this.screensWithSelectableRuns = getScreensWithSelectableRunsApiPaths();

    actions
      .pipe(ofType(FromScreenStatusActions.selectCalcRunId))
      .subscribe(({ selectedCalcRunId }) => {
        this.selectedCalcRunId = selectedCalcRunId;
      });
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const calcRunIdSelectable = this.screensWithSelectableRuns.some(url => req.url.startsWith(url));
    if (calcRunIdSelectable && this.selectedCalcRunId !== undefined) {
      return next.handle(
        req.clone({ params: req.params.set('calcRunId', this.selectedCalcRunId) })
      );
    } else {
      return next.handle(req);
    }
  }
}
