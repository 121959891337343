/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';
import { ProductTheme } from 'app/shared/models';
import { IconSize, UserAuthModels } from '@opengamma/ui';

@Component({
  selector: 'og-modules-menu',
  templateUrl: './modules-menu.component.html',
  styleUrls: ['./modules-menu.component.scss']
})
export class ModulesMenuComponent {
  /** The subheader items. */
  @Input() modules: UserAuthModels.AvailableModule[];

  /** The currently selected product. */
  @Input() productUrl: string;

  /** The theme of the subheader. */
  @Input() theme: ProductTheme;

  IconSize = IconSize;

  getModuleRoute(moduleUrl: string): string[] {
    return [this.productUrl, moduleUrl];
  }

  filterModulesByPosition(
    position: 'left' | 'right' | 'right-dropdown',
    modules: UserAuthModels.AvailableModule[]
  ): UserAuthModels.AvailableModule[] {
    return modules?.filter(module => module.displayOptions.positionInMenu === position);
  }
}
