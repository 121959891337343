/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';

export interface LegendItem {
  name: string;
  color: string;
}

@Component({
  selector: 'og-timeseries-legend',
  templateUrl: './timeseries-legend.component.html',
  styleUrls: ['./timeseries-legend.component.scss']
})
export class TimeseriesLegendComponent {
  @Input() legendItems: string[];
  @Input() colors: string[];
  @Input() legendItemsWithColors: LegendItem[];
}
