/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import { IconModule, OGFormsModule, OGMiscModule } from '@opengamma/ui';

import { TimeseriesCardHeaderComponent } from 'app/shared/components/card/timeseries-card-header/timeseries-card-header.component';
import { TimeseriesLegendComponent } from 'app/shared/components/card/timeseries-card-header/timeseries-legend/timeseries-legend.component';
import { TimeseriesPillsComponent } from 'app/shared/components/card/timeseries-card-header/timeseries-pills/timeseries-pills.component';
import { TimeseriesRangeComponent } from 'app/shared/components/card/timeseries-card-header/timeseries-range/timeseries-range.component';
import { TimeseriesToggleComponent } from 'app/shared/components/card/timeseries-card-header/timeseries-toggle/timeseries-toggle.component';
import { TimeseriesExportButtonComponent } from 'app/shared/components/card/timeseries-card-header/timeseries-export-button/timeseries-export-button.component';
import { TimeseriesMultiSelectComponent } from 'app/shared/components/card/timeseries-card-header/timeseries-multi-select/timeseries-multi-select.component';
import { MatTooltipModule } from '@angular/material/tooltip';

@NgModule({
  imports: [
    CommonModule,
    OGFormsModule,
    IconModule,
    FlexLayoutModule,
    MatTooltipModule,
    OGMiscModule
  ],
  declarations: [
    TimeseriesCardHeaderComponent,
    TimeseriesLegendComponent,
    TimeseriesPillsComponent,
    TimeseriesRangeComponent,
    TimeseriesToggleComponent,
    TimeseriesMultiSelectComponent,
    TimeseriesExportButtonComponent
  ],
  exports: [
    TimeseriesCardHeaderComponent,
    TimeseriesLegendComponent,
    TimeseriesPillsComponent,
    TimeseriesRangeComponent,
    TimeseriesToggleComponent,
    TimeseriesMultiSelectComponent,
    TimeseriesExportButtonComponent
  ]
})
export class OGTimeseriesCardHeaderModule {}
