/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { INITIAL_STATE, UserAuthState, initializer } from 'app/user-auth/store/user-auth.state';
import { withData, withError, withLoading } from 'app/shared/store/utils/loadable-state';
import { createReducer, on, Action } from '@ngrx/store';
import * as userActions from './user-auth.actions';
import { getUrlPathSegment } from 'app/user-auth/services/auth/permission.utils';

const userApiReducer = createReducer(
  initializer(),

  // Login
  on(userActions.login.load, state => withLoading(state, 'user')),
  on(userActions.login.success, (state, { data }) => withData(state, 'user', data)),
  on(userActions.login.error, (state, { error }) => withError(state, 'user', error)),

  // Tenant Demo Status
  on(userActions.getDemoTenantStatus.load, state => withLoading(state, 'isDemoTenant')),
  on(userActions.getDemoTenantStatus.success, (state, { data }) =>
    withData(state, 'isDemoTenant', data)
  ),
  on(userActions.getDemoTenantStatus.error, (state, { error }) =>
    withError(state, 'isDemoTenant', error)
  ),

  // Permissions
  on(userActions.permissions.load, state => withLoading(state, 'permissions')),
  on(userActions.permissions.success, (state, { data }) => withData(state, 'permissions', data)),
  on(userActions.permissions.error, (state, { error }) => withError(state, 'permissions', error)),
  // JSCC Terms
  // fetching / updating
  on(userActions.getRequireJSCCTermsConsent.load, userActions.consentToJSCCTerms.load, state =>
    withLoading(state, 'isJSCCTCsAccepted')
  ),
  on(
    userActions.getRequireJSCCTermsConsent.success,
    userActions.consentToJSCCTerms.success,
    (state, { data }) => withData(state, 'isJSCCTCsAccepted', data)
  ),
  on(
    userActions.consentToJSCCTerms.error,
    userActions.consentToJSCCTerms.error,
    (state, { error }) => withError(state, 'isJSCCTCsAccepted', error.message)
  ),

  // MFA
  on(userActions.setMfa, (state, { isEnabled }) => ({
    ...state,
    user: {
      ...state.user,
      data: {
        ...state.user.data,
        isMfaEnabled: isEnabled
      }
    }
  })),

  // Router
  on(userActions.recordLastScreenVisitedInModule, (state, { url }) => {
    const urlFragments = url.split('/').filter(item => item !== '');
    if (urlFragments.length === 3) {
      const moduleUrl = getUrlPathSegment(url, 'module');
      const screenUrl = getUrlPathSegment(url, 'screen');
      return {
        ...state,
        moduleToScreenMap: {
          ...state.moduleToScreenMap,
          [moduleUrl]: screenUrl
        }
      };
    }
    return state;
  })
);

export function reducer(state = INITIAL_STATE, action: Action): UserAuthState {
  return userApiReducer(state, action);
}
