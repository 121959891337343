/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { NamedValue } from '@opengamma/ui';
import {
  HeadlineValue,
  HeadlinesEndpoint
} from 'app/shared/components/metrics/headlines/headlines.model';

@Component({
  selector: 'og-headlines',
  templateUrl: './headlines.component.html',
  styleUrls: ['./headlines.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeadlinesComponent {
  headlineValues: NamedValue<HeadlineValue>[];

  @Input() areHeadlinesLoading: boolean;

  @Input() invertSignColor: boolean;

  @Input() set headlines(headlinesEndpoint: HeadlinesEndpoint) {
    this.headlineValues = headlinesEndpoint?.headlines;
  }
}
