/*
 * Copyright (C) 2023 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { Pipe, PipeTransform } from '@angular/core';
import { UserAuthModels } from '@opengamma/ui';

@Pipe({
  name: 'filterModulesByPosition',
  standalone: true
})
export class FilterModulesByPositionPipe implements PipeTransform {
  transform(
    modules: UserAuthModels.AvailableModule[],
    position: 'left' | 'right' | 'right-dropdown'
  ): UserAuthModels.AvailableModule[] {
    return modules?.filter(module => module.displayOptions.positionInMenu === position);
  }
}
