<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

@if (cell.value !== undefined) { @switch (cell.type) { @case ('text') {
<og-truncated-text class="table-cell--align-left" [text]="cell.value"></og-truncated-text>
} @case ('joined_values') {
<div fxLayout="row" fxLayoutAlign="center center">
  @for (cell of cell.value; track cell; let first = $first) { @if (!first) {
  <hr class="table-cell__divider" />
  }
  <og-table-cell [cell]="cell" class="table-cell__details"></og-table-cell>
  }
</div>
} @case ('number') {
<og-number-value-cell
  [value]="cell.value"
  [decimalPlaces]="options?.decimalPlaces"
></og-number-value-cell>
} @case ('bounded_number') {
<og-bounded-number-value-cell
  [value]="cell.value"
  [bound]="bound"
  [decimalPipeArgs]="getDecimalPipeArgs(options?.decimalPlaces)"
></og-bounded-number-value-cell>
} @case ('short_number') {
<og-short-number-value-cell [value]="cell.value"></og-short-number-value-cell>
} @case ('currency_value') {
<og-currency-value-cell
  [value]="cell.value"
  [alignment]="options?.alignment"
  [bound]="options?.columnBound || bound"
  [decimalPipeArgs]="getDecimalPipeArgs(options?.decimalPlaces)"
></og-currency-value-cell>
} @case ('currency_value_change') {
<og-change-currency-value
  [currency]="cell.value"
  [bound]="options?.columnBound || bound"
  [decimalPipeArgs]="getDecimalPipeArgs(options?.decimalPlaces)"
></og-change-currency-value>
} @case ('percentage') {
<og-percentage-value-cell [value]="cell.value"></og-percentage-value-cell>
} @case ('status') {
<og-entry-line-feedback-field [feedbackItem]="cell.value"></og-entry-line-feedback-field>
} @case ('icon') {
<og-icon-cell [icon]="cell.value"></og-icon-cell>
}
<!-- If cell type could not be matched, display the undefined value placeholder. -->
@default {
<ng-container *ngTemplateOutlet="undefinedValue"></ng-container>
} } } @else {
<div>-</div>
}

<ng-template #undefinedValue>
  <div>-</div>
</ng-template>
