/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'og-oval-expand-button',
  templateUrl: './oval-expand-button.component.html',
  styleUrls: ['./oval-expand-button.component.scss']
})
export class OvalExpandButtonComponent {
  @Input() buttonText: string;
  @Input() isStateExpanded: boolean;
  @Output() buttonClicked: EventEmitter<void> = new EventEmitter<void>();

  onButtonClick() {
    this.buttonClicked.next();
  }
}
