/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { DateTime } from 'luxon';
import { NamedTimeSeries } from 'app/shared/models';

export function convertToHighchartsTimeseries(namedSeries: NamedTimeSeries): number[][] {
  return namedSeries.timeSeries.map(({ date, value }) => [
    DateTime.fromISO(date).toMillis(),
    value
  ]);
}
