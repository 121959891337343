/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { Component, Input } from '@angular/core';
import { Subtext } from '@opengamma/ui';

@Component({
  selector: 'og-subtext',
  templateUrl: './subtext.component.html',
  styleUrls: ['./subtext.component.scss']
})
export class SubtextComponent {
  @Input() subtext: Subtext;
}
