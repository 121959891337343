/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { StoreDevtoolsModule } from '@ngrx/store-devtools';

export const storeDevTools = [
  StoreDevtoolsModule.instrument({
    maxAge: 25
  })
];
