/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';
import { Subtext } from '@opengamma/ui';

@Component({
  selector: 'og-bounded-number-value-cell',
  templateUrl: './bounded-number-value-cell.component.html',
  styleUrls: ['./bounded-number-value-cell.component.scss']
})
export class BoundedNumberValueCellComponent {
  /** The bound for the number */
  private _bound: number;

  /** The placeholder to display if value is undefined */
  undefinedValuePlaceholder = '-';

  /** The decimal format to use for the bounded number value. */
  @Input() decimalPipeArgs = '1.0-2';

  @Input() value: number;

  @Input() subtext: Subtext;

  @Input()
  set bound(bound: number) {
    if (!bound || bound < 1) {
      this._bound = 1;
    } else {
      this._bound = bound;
    }
  }
  get bound(): number {
    return this._bound;
  }
}
