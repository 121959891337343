/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input, EventEmitter, Output } from '@angular/core';
import { DateRangePill } from 'app/shared/components/card/timeseries-card-header/timeseries-card-header.model';

@Component({
  selector: 'og-timeseries-pills',
  templateUrl: './timeseries-pills.component.html',
  styleUrls: ['./timeseries-pills.component.scss']
})
export class TimeseriesPillsComponent {
  @Input() pills: DateRangePill[];
  @Input() selectedPill: DateRangePill;

  @Output() onPillSelect = new EventEmitter<DateRangePill>();
}
