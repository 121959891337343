/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';

// -------------------------------------------------------------------------
/** Icon for the up arrow. */
const UP_ARROW_ICON = 'chevrons-up';
/** Icon for the down arrow. */
const DOWN_ARROW_ICON = 'chevrons-down';
/** Icon for no-change. */
const NO_CHANGE_ICON = 'minus';

// -------------------------------------------------------------------------
/** Represents an arrow. */
@Component({
  selector: 'og-arrow',
  styleUrls: ['./arrow.component.scss'],
  templateUrl: './arrow.component.html'
})
export class ArrowComponent {
  /** True if the up arrow should be red rather than green. */
  @Input() invertColors = false;

  /** The value the arrow represents. */
  @Input() value: number;

  // -------------------------------------------------------------------------
  getIconName(): string {
    switch (Math.sign(this.value)) {
      case 1:
        return UP_ARROW_ICON;
      case -1:
        return DOWN_ARROW_ICON;
      case 0:
        return NO_CHANGE_ICON;
    }
  }

  getIconClass(): string {
    switch (Math.sign(this.value)) {
      case 1:
        return this.invertColors ? 'red' : 'green';
      case -1:
        return this.invertColors ? 'green' : 'red';
      case 0:
        return 'gray';
    }
  }
}
