import { ChangeDetectionStrategy, Component, EventEmitter, Output } from '@angular/core';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';
import { Store } from '@ngrx/store';
import { OGFormsModule, OGMiscModule } from '@opengamma/ui';
import { environment } from 'environments/default.environment';
import * as fromUser from 'app/user-auth/store/user-auth.selectors';

import { State } from 'app/shared/store/reducers';
import { AsyncPipe } from '@angular/common';

@Component({
  selector: 'og-terms-consent',
  templateUrl: './terms-consent.component.html',
  styleUrls: ['./terms-consent.component.scss'],
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [OGFormsModule, FlexLayoutModule, OGMiscModule, AsyncPipe]
})
export class TermsConsentComponent {
  @Output() acceptClicked = new EventEmitter<boolean>();

  isAccepted = false;
  jsccTermsConditionsLink = `${environment.analyticsUrl}/assets/jscc-terms.pdf`;
  isJSCCTCsAcceptedLoadable$ = this.store.select(fromUser.getIsJSCCTCsAcceptedLoadable);

  constructor(private store: Store<State>) {}

  onAccept() {
    this.acceptClicked.emit(this.isAccepted);
  }

  onToggleConsent() {
    this.isAccepted = !this.isAccepted;
  }
}
