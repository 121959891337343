/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

// -------------------------------------------------------------------------
import { Observable } from 'rxjs';
import { filter, tap } from 'rxjs/operators';

export { of as observableOf } from 'rxjs';
export { from as observableFrom } from 'rxjs';
export { interval as observableInterval } from 'rxjs';
export { defer as observableDefer } from 'rxjs';
export { throwError as observableThrow } from 'rxjs';
export { combineLatest as observableCombineLatest } from 'rxjs';
export { forkJoin as observableForkJoin } from 'rxjs';
export { zip as observableZip } from 'rxjs';
export { merge as observableMerge } from 'rxjs';
export { pipe as observablePipe } from 'rxjs';
export { race as observableRace } from 'rxjs';

// -------------------------------------------------------------------------
// filterUndefined
type Defined<T> = T extends undefined | null ? never : T;

export const filterUndefined = () => <T>(source: Observable<T>) =>
  source.pipe(filter((value: T): value is Defined<T> => value !== undefined && value !== null));

// -------------------------------------------------------------------------
/**
 * Logs values, errors and completions from the observable.
 *
 * @param title the optional text to put with the log.
 */
export const log = (title: string = '') => <T>(source: Observable<T>) => {
  return source.pipe(
    tap(
      // eslint-disable-next-line no-console
      value => console.log(title, 'Observable value: ', value),
      // eslint-disable-next-line no-console
      error => console.log(title, 'Observable error: ', error),
      // eslint-disable-next-line no-console
      () => console.log(title, 'Observable complete: ')
    )
  );
};
