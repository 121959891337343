/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import * as Highcharts from 'highcharts/highstock';

export const defaultStackedColumnOptions: Highcharts.Options = {
  plotOptions: {
    column: {
      pointPadding: 0.2,
      borderWidth: 0,
      shadow: false,
      stacking: 'normal'
    }
  },
  tooltip: {
    split: false,
    shared: false
  }
};
