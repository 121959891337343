/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { NgModule, inject } from '@angular/core';
import { RouterModule, Routes, Router, Route } from '@angular/router';
import { NoPermissionsComponent } from 'app/page-issues/no-permissions/no-permissions.component';
import { PageNotFoundComponent } from 'app/page-issues/page-not-found/page-not-found.component';
import { SharedModule } from 'app/shared/shared.module';
import { select, Store } from '@ngrx/store';
import * as fromUser from 'app/user-auth/store/user-auth.selectors';
import { State } from 'app/shared/store/reducers';
import { ProductDefinition } from 'app/user-auth/models';
import { filterUndefined, observableCombineLatest } from 'app/shared/rxjs/rxjs.utils';
import { MODULE_MAP } from 'app/user-auth/services/auth/local-product.definition';
import { RoutesCreated } from 'app/user-auth/store/user-auth.actions';
import { ScreenStatusModule, UserDetailsService } from '@opengamma/ui';
import { Observable, filter, of } from 'rxjs';
import { AppMainComponent } from './app-main/app-main.component';

export const extraRoutes: Routes = [
  {
    path: '404',
    component: PageNotFoundComponent
  },
  {
    path: '**',
    redirectTo: '404'
  }
];

/**
 * Hack to enable navigation using both the history API and URL fragments.
 *
 * This is needed so we don't get rate-limited by third parties during
 * automated UI testing. Currently navigation between pages is done by
 * navigating to different URLs as opposed to clicking around the UI,
 * which means all resources are reloaded on each step.
 *
 * Using URL fragments allows us to keep navigating using URLs (simpler to
 * maintain than element selectors for elements we want to click!), but
 * doesn't force the browser to re-load everything all the time.
 */
function shouldUseHash(): boolean {
  // We get back data such as the access token via URL fragment. However,
  // the name comes right after the hash in these cases ("#access_token=foo"),
  // whereas with 'useHash' set on RouterModule the hash is surrounded by
  // slashes on both sides.
  return window.location.href.includes('/#/');
}
function isJSCCTCsAcceptedGuard(): Observable<boolean> {
  const user = inject(UserDetailsService).getUser();
  const store = inject(Store);

  if (!user) {
    console.warn('User is not set');
    return of(false);
  }
  if (!user.jsccTermsConsentRequired) {
    return of(true);
  } else {
    return store.pipe(
      select(fromUser.getIsJSCCTCsAccepted),
      filter(isTermsAccepted => isTermsAccepted)
    );
  }
}

@NgModule({
  imports: [
    RouterModule.forRoot([], { useHash: shouldUseHash() }),
    SharedModule,
    ScreenStatusModule
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {
  haveRoutesBeenBuiltOnce: boolean;
  constructor(private router: Router, private store: Store<State>) {
    observableCombineLatest(
      this.store.pipe(select(fromUser.getPermissionsData), filterUndefined()),
      this.store.pipe(select(fromUser.getModuleHistoryMap), filterUndefined())
    ).subscribe(([permissions, moduleHistoryMap]) => {
      const routes = this.buildRoutes(permissions.productDefinition, moduleHistoryMap);
      this.router.config = [...routes, ...extraRoutes];
      if (!this.haveRoutesBeenBuiltOnce) {
        this.store.dispatch(new RoutesCreated());
        if (!shouldUseHash()) {
          // Strip any hashes or request parameters - maintain a clean-looking URL.
          // The Auth0 callback should redirect clients to URLs that do not use the
          // URL fragment for location (meaning the URL should not have "/#/"). If
          // we do come into the site immediately with "/#/margin/foo/bar", the below
          // would strip away everything before the hash and redirect us to "/".
          this.router.navigateByUrl(window.location.pathname);
        }
      }
      this.haveRoutesBeenBuiltOnce = true;
    });
  }

  /**
   * Build the cascading angular routes based on the provided permissions.
   */
  private buildRoutes(
    permissions: ProductDefinition,
    moduleHistoryMap: { [moduleUrl: string]: string }
  ): Routes {
    /**
     * When arriving at the base path (no route), default to the first available product/module/screen.
     * For example, when arriving at x.com, redirect to x.com/margin, then to x.com/margin/explain,
     * then to x.com/margin/explain/accounts
     */
    const redirectRoute = (redirectTo): Route => ({
      path: '',
      pathMatch: 'full',
      redirectTo
    });

    if (
      permissions.products.length === 0 ||
      permissions.products.every(product => product.isLocked)
    ) {
      return [
        {
          path: 'not-configured',
          component: NoPermissionsComponent
        }
      ];
    }

    return [
      redirectRoute(permissions.products[0].urlPath),
      {
        path: '',
        component: AppMainComponent,
        canActivate: [isJSCCTCsAcceptedGuard],
        children: permissions.products.map(product => ({
          path: product.urlPath,
          children: [
            redirectRoute(product.modules[0].urlPath),
            ...product.modules.map(module => ({
              path: module.urlPath,
              children: [
                redirectRoute(moduleHistoryMap[module.urlPath] || module.screens[0].urlPath),
                ...module.screens.map(screen => ({
                  path: screen.urlPath,
                  loadChildren: MODULE_MAP[screen.screenTemplate].loadChildren,
                  providers: MODULE_MAP[screen.screenTemplate].providers ?? [],
                  data: { url: [product.urlPath, module.urlPath, screen.urlPath].join('/') }
                }))
              ]
            }))
          ]
        }))
      }
    ];
  }
}
