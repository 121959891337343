<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div class="group__container {{ theme }}">
  @for (button of buttons; track button; let i = $index) {
  <div class="radio-button-group__wrapper" [ngClass]="buttonSizeClass">
    @if (i < 8 || showMoreSelected) {
    <div class="rb-wrapper">
      <div
        class="radio-button-group__button {{ buttonTheme }}"
        id="button-{{ i }}"
        (click)="onButtonSelected(button.id)"
        [class.radio-button-group__button--selected]="button.id === selectedButton"
      >
        <span>{{ button.name | uppercase }}</span>
      </div>
    </div>
    }
  </div>
  } @if (buttons && buttons.length > 8) {
  <div class="radio-button-group__button --show-more" (click)="onShowMoreClicked()">
    <span>{{ showMoreButtonText | uppercase }}</span>
    @if (!showMoreSelected) {
    <og-icon name="chevron-down"></og-icon>
    } @if (showMoreSelected) {
    <og-icon name="chevron-up"></og-icon>
    }
  </div>
  }
</div>
