/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import {
  Input,
  AfterViewInit,
  OnChanges,
  ViewChild,
  ElementRef,
  SimpleChanges,
  Directive,
  OnDestroy
} from '@angular/core';
import { defaultOptions } from 'app/shared/components/charts/modern/highcharts-options/defaults.highchart-options';
import * as Highcharts from 'highcharts/highstock';
import * as HC_exportData from 'highcharts/modules/exporting';
import * as HC_exportDataToCSV from 'highcharts/modules/export-data';
import * as _ from 'lodash';
import { Observable, Subscription } from 'rxjs';
import { PageLayout, PageWidthLayoutService } from '@opengamma/ui';

// enabling exporting module for exporting chart data
HC_exportData.default(Highcharts);
HC_exportDataToCSV.default(Highcharts);
Highcharts.setOptions(defaultOptions);

@Directive()
export class OGHighchartDirective implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('chart', { static: true }) element: ElementRef;

  // Expose the Highcharts library to the angular-highcharts component.
  // This is needed as there are multiple Highcharts constructors, like Highstock or Highmap.
  options: Highcharts.Options = {};

  // Extract and expose the highcharts API object through this reference to the base component
  chart: Highcharts.Chart;

  pageLayout$: Observable<PageLayout>;

  @Input() extraOptions: Highcharts.Options;
  private reflowSubscription: Subscription;

  constructor(pageWidthLayoutService: PageWidthLayoutService) {
    this.pageLayout$ = pageWidthLayoutService.getPageLayout();
  }

  ngAfterViewInit() {
    this.reflowChart();
    this.reflowSubscription = this.pageLayout$.subscribe(() => {
      this.reflowChart(450);
    });
  }

  ngOnDestroy(): void {
    this.reflowSubscription.unsubscribe();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.extraOptions) {
      this.options = _.merge(this.options, this.extraOptions);
    }
    if (this.chart) {
      this.chart.update(this.options);
      this.reflowChart();
    } else {
      this.createChart();
    }
  }

  private reflowChart(delay = 10): void {
    setTimeout(() => {
      this.chart?.reflow();
    }, delay);
  }

  private createChart(): void {
    if (this.element && this.element.nativeElement && this.options) {
      this.chart = new Highcharts.Chart(this.element.nativeElement, this.options);
    }
  }
}
