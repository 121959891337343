/*
 * Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { NgModule, inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FlexLayoutModule } from '@ngbracket/ngx-layout';

import {
  AbstractFileViewerResolver,
  IconModule,
  OGFormsModule,
  OGMiscModule,
  OGUIFileViewerModule,
  UatBadgeComponent,
  UserDetailsService,
  serviceDefinitionLink,
  HelpMenuModule,
  HelpMenuItemComponent
} from '@opengamma/ui';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { PortalModule } from '@angular/cdk/portal';

import { HeaderComponent } from 'app/header/header.component';
import { AccountComponent } from 'app/header/account/account.component';
import { ModulesMenuComponent } from 'app/header/modules-menu/modules-menu.component';
import { ScreensMenuComponent } from 'app/header/screens-menu/screens-menu.component';
import { ScreensMenuItemsComponent } from 'app/header/screens-menu/screens-menu-items/screens-menu-items.component';
import { MatTooltipModule } from '@angular/material/tooltip';
import { OGLayoutModule } from 'app/shared/components/layout/layout.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { carmaApiPath } from 'environments/environment.utils';
import { environment } from 'environments/default.environment';

import { CarmaFileViewerResolver } from 'app/shared/services/file-viewer-resolver.service';
import { FilterModulesByPositionPipe } from './pipes/filter-by-position.pipe';

const standaloneComponents = [UatBadgeComponent];

const standalonePipes = [FilterModulesByPositionPipe];

/*
  Factory that returns document list to be displayed in Service Desk Modal
  Terms and Privacy Policy Documents should only be displayed
  when jsccTermsConsent is required for the tenant
*/
const termsDocumentFactory = () => {
  const user = inject(UserDetailsService)?.getUser();
  if (!user) {
    console.warn('User is not defined');
  }
  return [
    serviceDefinitionLink,
    ...(user?.jsccTermsConsentRequired
      ? [
          {
            link: `${environment.analyticsUrl}/assets/jscc-terms.pdf`,
            label: 'Terms and Conditions'
          },
          {
            link: 'https://opengamma.com/terms-conditions/#Privacy_Policy',
            label: 'Privacy Policy'
          }
        ]
      : [])
  ];
};

@NgModule({
  imports: [
    CommonModule,
    PortalModule,
    MatTooltipModule,
    FlexLayoutModule,
    IconModule,
    RouterModule,
    OGUIFileViewerModule.forRoot({
      apiPath: carmaApiPath(),
      resolverProvider: {
        provide: AbstractFileViewerResolver,
        useClass: CarmaFileViewerResolver
      }
    }),
    MatDatepickerModule,
    OGFormsModule,
    OGLayoutModule,
    OGMiscModule,
    MatFormFieldModule,
    HelpMenuItemComponent,
    ...standaloneComponents,
    ...standalonePipes,
    HelpMenuModule.forRoot({
      urls: {
        basePath: carmaApiPath(),
        loadData: 'service-desk/get-data',
        submitData: 'service-desk/submit-ticket'
      },
      documentLinksFactory: termsDocumentFactory
    })
  ],
  declarations: [
    ScreensMenuComponent,
    ScreensMenuItemsComponent,
    AccountComponent,
    ModulesMenuComponent,
    HeaderComponent
  ],
  exports: [
    ScreensMenuComponent,
    ScreensMenuItemsComponent,
    AccountComponent,
    ModulesMenuComponent,
    HeaderComponent
  ]
})
export class HeaderModule {}
