<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

@if (pills) {
<div class="range-selector" fxLayout="row" fxLayoutAlign="center center" fxLayoutGap="8px">
  @for (pill of pills; track pill) {
  <div
    class="range-selector__pill"
    [class.range-selector__pill--selected]="selectedPill?.label === pill.label"
    (click)="onPillSelect.emit(pill)"
  >
    {{ pill.label }}
  </div>
  }
</div>
}
