<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<div class="table-wrapper">
  <div class="table-wrapper__bar" fxLayout="row" fxLayoutGap="8px">
    <div fxFlex>
      <ng-content select="og-table-text-filter"></ng-content>
    </div>
    <ng-content select="og-table-bounds-select"></ng-content>
    <ng-content select="og-table-columns-select"></ng-content>
    <ng-content select="og-table-export-button"></ng-content>
    <ng-content></ng-content>
  </div>

  <ng-content select="og-table"></ng-content>
</div>
