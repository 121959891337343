/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */
import { Color } from 'highcharts';
import { OMEGA } from 'app/shared/utils/colors';
import { DifferencePairChartParams } from './difference-pair-chart.model';

export function differencePairChartOptions(
  { color, percentage }: DifferencePairChartParams,
  maxBoundary: number
) {
  return {
    chart: {
      type: 'bar',
      height: 7,
      plotBackgroundColor: OMEGA,
      renderTo: 'chart',
      spacing: [0, 0, 0, 0]
    },
    title: {
      text: undefined
    },
    xAxis: {
      visible: false
    },
    yAxis: {
      visible: false,
      min: -maxBoundary * 100,
      max: maxBoundary * 100
    },
    tooltip: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    credits: {
      enabled: false
    },
    navigation: {
      buttonOptions: {
        enabled: false
      }
    },
    plotOptions: {
      bar: {
        pointWidth: 40,
        dataLabels: {
          enabled: false
        }
      },
      column: {
        grouping: false,
        shadow: false
      },
      series: {
        animation: true,
        borderWidth: 0,
        enableMouseTracking: false,
        color: new Color(color),
        pointWidth: 40,
        states: {
          hover: {
            enabled: false
          }
        }
      }
    },
    series: [
      {
        pointWidth: 40,
        data: [
          {
            color,
            y: percentage * 100
          }
        ]
      }
    ]
  };
}
