/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { ChangeDetectionStrategy, Component, Input, OnChanges } from '@angular/core';
import * as _ from 'lodash';

// -------------------------------------------------------------------------
/** A numerical time series with an optional name. */
export interface NamedNumericalSeries {
  /** The name of the time series, optional. */
  name?: string;
  /** The numerical data series. */
  dataSeries: DataSeriesEntry[];
}

export interface DataSeriesEntry {
  /** The x axis value */
  x: number;
  /** The y axis value */
  y: number;
  /** Contains metadata for the entry, can be used to add extra details to each highchart point. */
  metadata: object;
}

/** A line on the time series graph. */
export interface NumericalSeriesPlotLine {
  /** The color of the line. */
  color: string;
  /** The value at which the line should appear. */
  value: number;
  /** The text on the line. */
  text?: string;
}

/** Defines a point on the chart. */
export interface NumericalDataPoint {
  /** The x axis value. */
  x: number;
  /** The y axis value. */
  y: number;
  /** The name of the series that the point is part of. */
  seriesLabel: string;
  /** Contains metadata for the data point. */
  metadata?: object;
}

// -------------------------------------------------------------------------
/**
 * Represents a line chart where the x and y axises are number.
 *
 * Can be used to display a single line as well as multiple
 * value lines'. Both the primary inputs will accept arrays as well
 * as single values.
 */ @Component({
  selector: 'og-numerical-line-chart',
  templateUrl: './numerical-line-chart.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NumericalLineChartComponent implements OnChanges {
  /** The time series data. */
  @Input() data: NamedNumericalSeries | NamedNumericalSeries[];
  // -------------------------------------------------------------------------
  /** The title of the chart, optional. */
  @Input() chartTitle?: string;
  /** The colours to be used for each respective series. */
  @Input() colors: string[] = [];
  /** A list of lines to display on the time series, optional. */
  @Input() plotLines?: NumericalSeriesPlotLine[];
  /** The positions of the ticks to display on the x axis. */
  @Input() xAxisTickPositions?: number[];
  /** The formatter to use for formatting the tooltip text */
  @Input() tooltipFormatter: (point: NumericalDataPoint) => string;
  /** The formatter to use for formatting the y axis label value */
  @Input() yAxisLabelFormatter: (value: number) => string;
  /** Defines if the tooltip should be enabled */
  @Input() enableTooltip = false;
  /** Defines the X axis title to use */
  @Input() xAxisTitle: string;
  /** Defines the Y axis title to use */
  @Input() yAxisTitle: string;
  /** Defines additional chart options. */
  @Input() extraOptions: object = {};

  // -------------------------------------------------------------------------
  options?: object; // highcharts object

  ngOnChanges() {
    if (!this.data) {
      this.options = undefined;
      return;
    }

    const normalizedData = ((this.data as NamedNumericalSeries[]).length !== undefined
      ? this.data
      : [this.data]) as NamedNumericalSeries[];

    const seriesData = normalizedData.map((datum, index) => ({
      name: datum.name,
      color: this.colors[index % this.colors.length],
      data: datum.dataSeries.map(({ x, y, metadata }) => ({ x, y, metadata }))
    }));

    const showLegend = seriesData.some(data => !!data.name);

    const tooltipFormatter = this.tooltipFormatter;

    this.options = _.merge(
      {
        title: {
          text: this.chartTitle,
          style: {
            color: 'rgba(216, 216, 216, .7)',
            fontSize: '12px'
          }
        },
        tooltip: {
          formatter: this.tooltipFormatter
            ? function() {
                return tooltipFormatter({
                  x: this.x,
                  y: this.y,
                  seriesLabel: this.points && this.points[0].series.name,
                  metadata: this.points && this.points[0].point.metadata
                });
              }
            : undefined,
          borderRadius: 0,
          borderWidth: 0,
          crosshairs: false,
          enabled: this.enableTooltip
        },
        legend: {
          enabled: showLegend,
          navigation: {
            enabled: false
          },
          lineHeight: 12,
          margin: 12,
          padding: 8,
          symbolRadius: 0
        },
        xAxis: {
          gridLineWidth: 1,
          gridLineDashStyle: 'ShortDash',
          gridLineColor: 'rgba(216, 216, 216, .2)',
          gridZIndex: 5,
          labels: {
            step: 2,
            style: {
              color: 'rgba(216, 216, 216, .6)'
            }
          },
          title: {
            text: this.xAxisTitle
          },
          lineWidth: 0,
          tickColor: 'rgba(216, 216, 216, .2)',
          tickPositions: this.xAxisTickPositions,
          plotLines: this.plotLines
            ? this.plotLines.map(line => ({
                color: line.color,
                value: line.value,
                width: 1,
                label: line.text
                  ? {
                      text: line.text,
                      style: {
                        color: line.color,
                        fontWeight: 'bold'
                      }
                    }
                  : undefined
              }))
            : undefined
        },
        yAxis: {
          title: {
            text: this.yAxisTitle,
            style: {
              fontSize: '8px'
            }
          },
          labels: {
            style: {
              color: 'rgba(216, 216, 216, .6)'
            },
            formatter: _.partial(function(formatter) {
              return formatter ? formatter(this.value) : this.axis.defaultLabelFormatter.call(this);
            }, this.yAxisLabelFormatter)
          },
          plotLines: [
            {
              color: 'rgba(216, 216, 216, .2)',
              width: 1,
              value: 0
            }
          ]
        },
        series: seriesData
      },
      this.extraOptions
    );
  }
}
