<!--
  ~ Copyright (C) 2020 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->

<og-button
  class="export-to-csv-button"
  matTooltip="Export table data"
  icon="cloud-download"
  theme="secondary"
  [iconOnly]="true"
  (buttonClick)="onExportTableToCSVButtonClicked()"
></og-button>
