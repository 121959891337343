/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, ElementRef, Input, OnChanges, ViewChild } from '@angular/core';
import { NumberUtils, CurrencyValuePipe } from '@opengamma/ui';
import { getOptions } from './progress-bar-util';
import { Currency } from 'app/shared/models';

@Component({
  selector: 'og-progress-bar',
  templateUrl: 'progress-bar.component.html',
  styleUrls: ['progress-bar.component.scss']
})
export class ProgressBarComponent implements OnChanges {
  @ViewChild('chart', { static: true }) chartEl: ElementRef;

  /** The value of the progress bar. */
  @Input() value: number;

  /** The currency to use for formatting the value. */
  @Input() currency: Currency;
  /**
   * The percentage of the progress bar, between 0 and 1.
   *
   * If absent, will be calculated from the {@link maxValue} property.
   */
  @Input() percentage?: number;

  /**
   * The max value of the progress bar, used to calculate the percentage.
   *
   * Required if {@link percentage} isn't provided, ignored otherwise.
   */
  @Input() maxValue?: number;

  /** The color of the progress bar. */
  @Input() color;

  /**
   * True if the progress bar should allow negative values.
   *
   * This will put 0% in the middle of the progress bar, regardless of whether
   * the value is positive or negative. Defaults to false.
   */
  @Input() isNegativeAllowed = false;

  /**
   * True if arrow colours should be inverted.
   * Ignored if {@link isNegativeAllowed} is not true.
   */
  @Input() shouldInvertArrowColors = false;

  /** The string to be displayed before the value, defaulted to blank. */
  @Input() prefixLabel = '';

  /** The string to be displayed after the value, defaulted to blank. */
  @Input() suffixLabel = '';

  /** The title to display above the progress bar, defaulted to blank. */
  @Input() title = '';

  /** This flag is used to decide whether the display the text above the progress bar */
  @Input() displayLabels = true;

  /** The duration of the animation in milliseconds */
  @Input() animationDuration: number;

  options: any;

  constructor(private currencyValuePipe: CurrencyValuePipe) {}

  /** Returns the progress bar value, formatted for display purposes. */
  getDisplayValue(): string {
    if (this.currency) {
      return this.currencyValuePipe.transform({
        price: this.value,
        ...this.currency
      });
    }

    return `${this.prefixLabel}${NumberUtils.roundToDecimalPlaces(this.value, 0)}${
      this.suffixLabel
    }`;
  }

  /**
   * Returns the percentage represented by the progress bar, between 0 and 1.
   *
   * @return the percentage
   */
  getPercentage(): number {
    return this.percentage || this.value / this.maxValue;
  }

  // ------------------------------------------------------------------------
  // Default chart event listeners.
  ngOnChanges() {
    this.options = getOptions(
      this.getPercentage(),
      this.color,
      this.isNegativeAllowed,
      this.maxValue,
      this.animationDuration
    );
  }
}
