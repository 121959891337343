/*
 * Copyright (C) 2018 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Component, Input } from '@angular/core';

@Component({
  selector: 'og-inverted-metric',
  templateUrl: './inverted-metric.component.html',
  styleUrls: ['./inverted-metric.component.scss']
})
export class InvertedMetricComponent {
  /**
   * Whether or not to show the divider that vertically separates the metric
   *    from the subtext.
   */
  @Input() showDivider = true;
}
