/*
 * Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
 *
 * Please see distribution for license.
 */

import { Observable } from 'rxjs';
import { MfaSettings } from 'app/user-auth/models';

/** Service for getting user info. */
export abstract class UserService {
  /** Updates the MFA of the user. */
  abstract updateMfa(mfaSettings: MfaSettings): Observable<void>;
}
