<!--
  ~ Copyright (C) 2017 - present by OpenGamma Inc. and the OpenGamma group of companies
  ~
  ~ Please see distribution for license.
  -->
<div class="overflow-wrapper">
  <table class="table">
    <thead class="table__head">
      <!-- Column groups-->
      @if (groups?.length > 1) {
      <tr class="table__header-row">
        @for (columnGroup of groups; track columnGroup; let first = $first) {
        <th
          class="table__header-cell"
          [attr.colspan]="columnGroup.columns.length"
          [class.first-column]="first && columnGroup.columns.length === 1"
        >
          {{ columnGroup.label }}
        </th>
        }
      </tr>
      }
      <!-- Columns in column groups-->
      <tr>
        @for (column of columns; track column; let first = $first; let last = $last; let i = $index)
        {
        <th
          class="table__header-cell"
          [class.table__header-cell--no-border]="last"
          [class.table__header-cell--sortable]="column.isSortable"
          [class.first-column]="columns.length > 1 && first"
          (click)="onSortChange(column, i)"
        >
          <div fxLayout="row">
            <div class="cell__content" fxLayout="row" fxFlex="grow" fxLayoutAlign="center center">
              {{ column.label }}
              @if (column.isSortable) {
              <div class="table__sort-icon" fxFlex="0 0 15px">
                <og-icon [name]="getSortIcon(i)"></og-icon>
              </div>
              }
            </div>
            @if (column.icon) {
            <div
              class="table__header-cell-icon"
              [class.table__header-cell-icon--clickable]="column.icon.onClick"
            >
              <og-icon
                [name]="column.icon.name"
                (click)="column.icon.onClick && column.icon.onClick()"
                [matTooltip]="column.icon.tooltip"
              ></og-icon>
            </div>
            }
          </div>
        </th>
        }
      </tr>
    </thead>
    <tbody class="table__body">
      <!-- Total row, if it exists-->
      @if (totalRow) {
      <ng-template
        [ngTemplateOutlet]="treeTableRows"
        [ngTemplateOutletContext]="{
          $implicit: { rows: [totalRow], level: 0, path: [], isTotal: true }
        }"
      ></ng-template>
      }
      <!-- Recursive table rows-->
      <ng-template
        [ngTemplateOutlet]="treeTableRows"
        [ngTemplateOutletContext]="{
          $implicit: { rows: rows, level: 0, path: [], isTotal: false }
        }"
      ></ng-template>
      <!-- Row Iteration Template -->
      <ng-template #treeTableRows let-node>
        <!-- Iterate through the rows at the current level -->
        @for ( row of getRowsToDisplay(node); track trackRows($index, row); let first = $first; let
        last = $last) { @if (getNodePath(node.path, row.id); as nodePath) {
        <tr
          class="table__row table__row--{{ node?.level }}"
          [class.table__row--expandable]="isRowExpandable(row)"
          [class.table__row--first]="node.level > 0 && first"
          [class.table__row--last]="node.level > 0 && last"
          [class.table__row--total]="node.isTotal"
          (click)="!node.isTotal && isRowExpandable(row) && onRowToggle(nodePath)"
        >
          @for ( cellValue of row.values; track cellValue; let i = $index; let first = $first; let
          last = $last) {
          <td
            class="table__cell table__cell--{{ node?.level }}"
            [class.table__cell--active]="isColumnActive(i)"
            (click)="onCellClick(i, $event)"
            [class.first-column]="columns.length > 1 && first"
            [class.table__cell--no-border-right]="last"
          >
            <div
              class="table__cell-content"
              fxLayout="row"
              [fxLayoutAlign]="first ? 'start center' : 'center center'"
            >
              @if (first) {
              <div class="table__expansion-icon table__expansion-icon--{{ node.level }}">
                @if (row.children && row.children.length !== 0) {
                <og-icon
                  [name]="isRowExpanded(nodePath) ? 'minus' : 'plus'"
                  [stroke]="IconStroke.XThick"
                ></og-icon>
                }
              </div>
              } @if (cellValue !== undefined) { @if (getCellTemplate(i); as cellTemplate) {
              <ng-container
                [ngTemplateOutlet]="cellTemplate"
                [ngTemplateOutletContext]="{ $implicit: cellValue, row: row }"
              ></ng-container>
              } @else {
              <div class="table-cell--empty">
                {{ cellValue }}
              </div>
              } } @else { - }
            </div>
          </td>
          }
        </tr>
        <!-- Iterate through children if they exist -->
        @if (isRowExpandable(row) && isRowExpanded(nodePath)) {
        <ng-template
          [ngTemplateOutlet]="treeTableRows"
          [ngTemplateOutletContext]="{
            $implicit: {
              rows: row.children,
              level: node.level + 1,
              path: nodePath,
              isTotal: false
            }
          }"
        ></ng-template>
        } } } @if (node.rows && node.rows.length > getRowsToDisplay(node).length) {
        <tr
          class="table__row table__row--show-more table__row--{{ node.level }}"
          (click)="onShowMoreRows(node.path)"
        >
          <td
            class="table__cell table__cell--show-more table__cell--no-border-right"
            [attr.colspan]="columns.length"
          >
            <div
              class="table__show-more table__show-more--{{ node.level }}"
              fxLayout="row"
              fxLayoutAlign="space-between center"
            >
              <div class="show-more__call-to-action" fxLayout="row">
                <div class="table__expansion-icon">
                  <og-icon name="angle-down"></og-icon>
                </div>
                <div>Show {{ rowPaginationCount }} more</div>
              </div>
              <div class="show-more__info">
                Showing
                <span class="show-more__row-count">{{ getRowsToDisplay(node).length }}</span>
                rows of
                <span class="show-more__row-count">{{ node.rows.length }}</span>
              </div>
            </div>
          </td>
        </tr>
        }
      </ng-template>
    </tbody>
  </table>
</div>
